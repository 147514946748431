import * as React from 'react';
import { connect } from 'react-redux';
import TestHistory from '../../landing/testhistory';
import Landing from "../../landing/landing";
import GlobalReport from "../../landing/globalreport";
import "./landing.css";

class LandingComponent extends React.Component {
	state = { currentPage: "Report" }
  constructor(props: any) {
    super(props);
  }

	componentDidMount() {
		//this.setState({ currentPage: "Report" });
		window.location.href = "/landing/#";
  }

	changePage(event, value) {
		this.setState({ currentPage: value });
	}

  callback(value) {
  }

  render() {
	  const { currentPage } = this.state;
	  const authData = JSON.parse(localStorage.getItem("AUTHDATA"));

    return (
      <div className="py-4 px-10">
				<div className="w-3/5 px-4 mx-auto sm:px-6 md:px-8 flex">
					<span className="text-1xl w-1/3 text-right">
						<a href="./landing/#"
							className={(currentPage === "Report" ? "underline text-primary " : " text-gray-500")}
							onClick={(event) => this.changePage(event, "Report")}>Reporting</a>
					</span>
					<span className="text-1xl w-1/3 text-center">
						<a href="./landing/#"
							className={(currentPage === "TestHistory" ? "underline text-primary " : " text-gray-500 ")}
							onClick={(event) => this.changePage(event, "TestHistory")}>Test History</a>
				</span>
				{authData?.clientId === 1 &&
					<span className="text-1xl w-1/3 text-left">
						<a href="./landing/#"
							className={(currentPage === "GlobalReporting" ? "underline text-primary " : " text-gray-500 ")}
							onClick={(event) => this.changePage(event, "GlobalReporting")}>Global Reporting</a>
					</span>
				}
				</div>
				{
					currentPage === "Report" ? <Landing /> : ""
				}
				{
					currentPage === "TestHistory" ? <TestHistory /> : ""
				}
				{
					currentPage === "GlobalReporting" ? <GlobalReport /> : ""
				}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { login } = state;
  return {
    login
  };
}

const LandingPage = connect(mapStateToProps)(LandingComponent);
export default LandingPage;