import {

	GET_USERS_STARTED,
	GET_USERS_COMPLETED,
	GET_USERS_FAILED,

	POST_SINGLE_USER_DELETE_STARTED,
	POST_SINGLE_USER_DELETE_COMPLETED,
	POST_SINGLE_USER_DELETE_FAILED,

	GET_SINGLE_USER_STARTED,
	GET_SINGLE_USER_COMPLETED,
	GET_SINGLE_USER_FAILED,

	POST_ADD_USER_STARTED,
	POST_ADD_USER_COMPLETED,
	POST_ADD_USER_FAILED,

	POST_UPDATE_USER_AVATAR_STARTED,
	POST_UPDATE_USER_AVATAR_COMPLETED,
	POST_UPDATE_USER_AVATAR_FAILED,

	GET_USERS_ROLES_STARTED,
	GET_USERS_ROLES_COMPLETED,
	GET_USERS_ROLES_FAILED

} from '../actions/user';

import { user } from '../../utils/models/model';

const initialState = {
	isLoading: false,
	isError: false,
	isUsersLoaded: false,
	isUserDeleted: false,
	deleteResult: false,
	userInput: {
		userId: -1,
		userToken: "",
		clientId: -1
	},
	usersResult: [{
		id: -1,
		name: ""
	}],
	userRolesResult: [{
		id: -1,
		name: ""
	}],
	user: user,
	isUserLoaded: false,
	isUserAdded: false,
	userAddResult: false,
	isAvatarUpdated: false,
	isUserRolesLoaded: false,
}

const usersData = (state = initialState, action: any) => {
	switch (action.type) {
		case GET_USERS_STARTED:
			return {
				...state,
				isLoading: true,
				userInput: action.input,
				isUsersLoaded: false
			};
		case GET_USERS_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				usersResult: action.payload.result,
				isUserAdded: false,
				isUserDeleted: false,
				isSuccess: true,
				isUsersLoaded: true
			};
		case GET_USERS_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true
			};

		case POST_SINGLE_USER_DELETE_STARTED:
			return {
				...state,
				isLoading: true,
				userInput: action.input,
				isUsersLoaded: false,
				isUserDeleted: false
			};
		case POST_SINGLE_USER_DELETE_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				deleteResult: action.payload.result,
				isUserDeleted: true
			};
		case POST_SINGLE_USER_DELETE_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true
			};

		case GET_SINGLE_USER_STARTED:
			return {
				...state,
				isLoading: true,
				userInput: action.input,
				isUserLoaded: false
			};
		case GET_SINGLE_USER_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				user: action.payload.result,
				isUserLoaded: true
			};
		case GET_SINGLE_USER_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true,
				isUserLoaded: false
			};

		case POST_ADD_USER_STARTED:
			return {
				...state,
				isLoading: true,
				user: action.input,
				isUserAdded: false
			};
		case POST_ADD_USER_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				userAddResult: action.payload.result,
				isUserAdded: true
			};
		case POST_ADD_USER_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true,
				isUserAdded: false
			};

		case POST_UPDATE_USER_AVATAR_STARTED:
			return {
				...state,
				userInput: action.input,
				isAvatarUpdated: false
			};
		case POST_UPDATE_USER_AVATAR_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				isAvatarUpdated: true
			};
		case POST_UPDATE_USER_AVATAR_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true,
				isAvatarUpdated: false
			};

		case GET_USERS_ROLES_STARTED:
			return {
				...state,
				isLoading: true,
				userInput: action.input,
				isUserRolesLoaded: false
			};
		case GET_USERS_ROLES_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				userRolesResult: action.payload.result,
				isSuccess: true,
				isUserRolesLoaded: true
			};
		case GET_USERS_ROLES_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true,
				isUserRolesLoaded: false
			};

		default:
			return state;
	}
};

export default usersData;
