import { Http } from "../Http";
import { AxiosPromise } from "axios";
export class SettingsApi {

	public static getSettings(input: any) {
		return Http.axios().get('/Settings/GetSettings?userId=' + input.userId + "&userToken=" + input.userToken)
			.catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

	public static saveSettings(input: any) {
		var obj = JSON.stringify(input);
		return Http.axios().post('/Settings/UpdateSettings', obj, { headers: { 'Content-Type': 'application/json' } })
			.then(response => {
				return response;
			}).catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}
}