import {
  GETCORRECTIVEACTION_COMPLETED, GETCORRECTIVEACTION_FAILED, GETCORRECTIVEACTION_STARTED,
  ADDCORRECTIVEACTION_COMPLETED, ADDCORRECTIVEACTION_FAILED, ADDCORRECTIVEACTION_STARTED,
  DELETECORRECTIVEACTION_COMPLETED, DELETECORRECTIVEACTION_FAILED, DELETECORRECTIVEACTION_STARTED
} from "../actions/correctiveaction";

const initialState = {
  isError: false,
  isCALoaded: false,
  isCASaved: false,
  isCADeleted: false,
  correctiveActionInput: {
    userId: 0,
    usertoken: "",
  },
  correctiveActions: []
};

const correctiveActionData = (state = initialState, action: any) => {

  switch (action.type) {
    case GETCORRECTIVEACTION_STARTED:
      return {
        ...state,
        correctiveInput: action.input,
        isCALoaded: false
      };
    case GETCORRECTIVEACTION_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        correctiveActions: action.payload.result,
        isCALoaded: true
      };
    case GETCORRECTIVEACTION_FAILED:
      return {
        ...state,
        error: action.payload,
        isError: true,
        isCALoaded: false
      };

    case ADDCORRECTIVEACTION_STARTED:
      return {
        ...state,
        correctiveInput: action.input,
				isCASaved: false,
				isCALoaded: false
      };
    case ADDCORRECTIVEACTION_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        isCASaved: true
      };
    case ADDCORRECTIVEACTION_FAILED:
      return {
        ...state,
        error: action.payload,
        isError: true,
        isCASaved: false
      };

    case DELETECORRECTIVEACTION_STARTED:
      return {
        ...state,
        correctiveInput: action.input,
				isCADeleted: false,
				isCALoaded: false
      };
    case DELETECORRECTIVEACTION_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        isCADeleted: true
      };
    case DELETECORRECTIVEACTION_FAILED:
      return {
        ...state,
        error: action.payload,
        isError: true,
        isCADeleted: false
      };
    default:
      return state;
  }
};

export default correctiveActionData;
