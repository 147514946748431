export const site = {
  deleted: false,
  id: 0,
  clientId: 0,
  userId: 0,
  userToken: "",
  name: "",
  addressLine1: "",
  addressLine2: "",
  town: "",
  city: "",
  county: "",
  postcode: "",
	country: "GB",
	selected: false
}

export const client = {
  deleted: false,
  id: 0,
  userId: 0,
  userToken: "",
  name: "",
  addressLine1: "",
  addressLine2: "",
  town: "",
  city: "",
  county: "",
  postcode: "",
  country: "GB",
  profileImage: "",
  imageUrl: ""
}

export const user = {
  deleted: false,
  id: 0,
  userId: 0,
  userToken: "",
  firstName: "",
  lastName: "",
  userName: "",
  email: "",
  roleId: 0,
	password: "",
	updatedPassword: "",
  passwordResetToken: "",
  imageUrl: "",
  clientId: 0,
  siteId: 0
}

export const userProfile = {
  deleted: false,
  clientId: 0,
  userId: 0,
  userToken: "",
  userName: "",
  firstName: "",
  lastName: "",
  email: "",
  currentPassword: "",
  confirmPassword: "",
  newPassword: "",
  imageUrl: ""
}

export const cAction = {
  userId: 0,
  userToken: "",
  id: 0,
  name: ""
}

export const samplePlanModel =
{
	id: 0,
	name: "",
	frequency: "",
	dayOfWeek: "",
	dayOfMonth: 0,
	isLive: false,
	testPoints: [
		{
			id: 0,
			name: "",
			location: "",
			where: ""
		}
	],
	sites: [
		{
			id: 0,
			name: ""
		}
	]
}

export const testpoint = {
	id: 0,
	name: "",
	location: "",
	where: "",
	userId: 0,
	userToken: "",
	deleted: false,
	selected: false
}