import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  CheckIcon, CloseIcon
} from '../../utils/common/icon-hooks.js';

import * as React from 'react';
import FCButtonWithClick from '../../utils/library/buttonwithclick';

interface IProps {
  message: string;
  onOkClick?: any;
  onCancelClick?: any;
  className?: string;
  isOpen: boolean,
  title: string
}

interface IState {
  open: boolean
}

class FCConfirmAlert extends React.Component<IProps, IState>  {
  state = { open: false };

  constructor(props: any) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleOk(event) {
    if (this.props.onOkClick) {
      this.props.onOkClick(event);
    }
  }

  componentDidMount() {
    this.setState({ open: this.props.isOpen });
  }

  handleCancel(event) {
    this.props.onCancelClick(event);
  }

  handleClose(event) {
    this.props.onCancelClick(event);
  }

  render() {
    return (
      <Transition.Root show={this.state.open || this.props.isOpen} as={Fragment}>
        <Dialog static open={this.state.open} as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(event) => this.handleClose(event)}>
					<div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4
                                text-left overflow-hidden
                                transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 focus:outline-none"
                    onClick={(event) => { this.handleCancel(event) }}
                  >
                    <span className="sr-only">Close</span>
                    <CloseIcon />
                  </button>
                </div>
                <div className="sm:flex sm:items-start items-center">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center w-full mb-5">
                    <Dialog.Title as="h3" className="leading-6  text-gray-500">
                      {this.props.title}
                    </Dialog.Title>
					<div className="mt-5 w-full text-center">
                      <p className="text-gray-500">
												{this.props.message}
                      </p>
                    </div>
                  </div>
                </div>
				<div className="flex w-full">
					<div className="mt-5 mr-10 sm:mt-4 sm:flex w-1/2">&nbsp;</div>
					<div className="mt-5 mr-10 sm:mt-4 sm:flex w-1/2 float-right">
						<FCButtonWithClick onHandleClick={(event) => { this.handleCancel(event) }} bgColor="bg-fcbutton mr-3" type="button" icon="" value="No" />
									<FCButtonWithClick onHandleClick={(event) => { this.handleOk(event) }} bgColor="bg-primary" type="button" icon="" value="Yes" />
					</div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }
}

export default FCConfirmAlert;