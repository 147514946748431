import { put, call } from "redux-saga/effects";
import {
	GETALLSAMPLEPLANS_COMPLETED,
	GETALLSAMPLEPLANS_FAILED,
	GETALLTESTPOINTS_COMPLETED,
	GETALLTESTPOINTS_FAILED,
	SAVESAMPLEPLAN_COMPLETED,
	SAVESAMPLEPLAN_FAILED,
	DELETESAMPLEPLAN_COMPLETED,
	DELETESAMPLEPLAN_FAILED,
	IMPORT_SAMPLEPLAN_COMPLETED,
	IMPORT_SAMPLEPLAN_FAILED
} from "../actions/samplePlan";
import { SampleplanApi } from "../../utils/api/sampleplanApi";

export function* getSamplePlans(request: any) {
	try {
		const result = yield call(SampleplanApi.getSamplePlans, request.input);
		yield put({
			type: GETALLSAMPLEPLANS_COMPLETED,
			payload: result.data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: GETALLSAMPLEPLANS_FAILED, payload: e.message });
	}
}

export function* getTestPoints(request: any) {
	try {
		const result = yield call(SampleplanApi.getTestPoints, request.input);
		yield put({
			type: GETALLTESTPOINTS_COMPLETED,
			payload: result.data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: GETALLTESTPOINTS_FAILED, payload: e.message });
	}
}

export function* saveSamplePlan(request: any) {
	try {
		const result = yield call(SampleplanApi.saveSamplePlan, request.input);
		yield put({
			type: SAVESAMPLEPLAN_COMPLETED,
			payload: result.data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: SAVESAMPLEPLAN_FAILED, payload: e.message });
	}
}

export function* deleteSamplePlan(request: any) {
	try {
		const result = yield call(SampleplanApi.deleteSamplePlan, request.input);
		yield put({
			type: DELETESAMPLEPLAN_COMPLETED,
			payload: result.data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: DELETESAMPLEPLAN_FAILED, payload: e.message });
	}
}

export function* importSamplePlan(request: any) {
	try {
		const result = yield call(SampleplanApi.importSamplePlan, request.input);
		yield put({
			type: IMPORT_SAMPLEPLAN_COMPLETED,
			payload: result.data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: IMPORT_SAMPLEPLAN_FAILED, payload: e.message });
	}
}

