import * as React from 'react';
import { connect } from 'react-redux';
import SamplePlan from "../../sampleplan/sampleplan";
import CreatePlan from "../../sampleplan/createplan";
import "./sampleplan.css";
import FCMessageDialog from '../../../utils/library/dialog';
import { getUserData } from '../../../utils/common/functions';
import { user } from '../../../utils/models/model';

interface IProps {
	match: any
	usersData: any
}

interface IState {
	showAlert: boolean,
	isSuccessAlert: boolean,
	alertMessage: string,
	showConfirm: boolean,
	title: string,
	currentPage: any,
	templateId: any,
	sites: any,
	user: any,
}

class SamplePlanComponent extends React.Component<IProps, IState> {

	constructor(props: any) {
		super(props);
		this.state = {
			showAlert: false,
			isSuccessAlert: false,
			alertMessage: "",
			showConfirm: false,
			title: "",
			currentPage: "SamplePlan",
			templateId: -1,
			sites: [],
			user: user
		}
		this.showAlert = this.showAlert.bind(this);
	}

	showAlert(isShow, isSuccess, message) {
		this.setState({
			showAlert: isShow,
			isSuccessAlert: isSuccess,
			alertMessage: message
		});
	}

	closeDialog = () => {
		this.setState({ showAlert: false });
	}

	componentDidMount() {
		if (window.location.href.toLowerCase().indexOf("createplan") > -1) {
			this.setState({ currentPage: "CreatePlan" });
		}
		var alertMessage = window.localStorage.getItem('alertMessage');
		if (alertMessage && alertMessage != "") {
			this.setState({ showAlert: true, alertMessage: alertMessage, isSuccessAlert: true });
		}
		else {
		}
		window.localStorage.setItem('alertMessage', "");

		let usrData = getUserData();

		if (this.props.usersData?.usersResult.length > 0) {
			let usr = this.props.usersData?.usersResult.find((item) => item.id === usrData.id);
			if (usr)
				this.setState({ user: usr });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		let usrData = getUserData();

		if (prevProps.usersData?.usersResult !== this.props.usersData?.usersResult) {
			if (this.props.usersData?.usersResult.length > 0) {
				let usr = this.props.usersData?.usersResult.find((item) => item.id === usrData.id);
				if (usr)
					this.setState({ user: usr });
			}
		}
	}

	changePage = (value, templateId, sites) => {
		if (value) { this.setState({ currentPage: "CreatePlan", templateId: templateId, sites: sites }); }
		else { this.setState({ currentPage: "SamplePlan" }); }
	}

	render() {
		const { currentPage, showAlert, isSuccessAlert, alertMessage } = this.state;
		return (
			<div className="px-20">
				{
					this.state.user?.roleId === 1 ?
						<>
							{showAlert && <FCMessageDialog isSuccess={isSuccessAlert} modalCallBack={() => this.closeDialog()} message={alertMessage} />}
							{
								currentPage === "SamplePlan" ? <SamplePlan changepage={this.changePage} /> : ""
							}
							{
								currentPage === "CreatePlan" ? <CreatePlan changepage={this.changePage}
									templateId={this.state.templateId}
									sites={this.state.sites}
								/> : ""
							}
						</>
						:
						<h2 className="text-center mt-5">You do not have access to view this page</h2>
				}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	const { usersData } = state;
	return {
		usersData
	};
};

export default connect(mapStateToProps)(SamplePlanComponent);;