import * as React from 'react';
import { Route, Switch } from 'react-router';
import AppFrame from './components/layout/layout';
import Home from './components/Home';
import Login from './components/pages/account/login';
import Landing from './components/pages/landing/landing';
import ForgotPwd from './components/pages/account/forgotpwd';
import ResetPwd from './components/pages/account/resetpwd';
import Register from './components/pages/account/register';
import SamplePlanQuoteTool from './components/pages/sampleplanquote/sampleplanquote';
import Site from './components/pages/site/site';
import Test from './components/pages/test';
import { clearCache } from './utils/common/functions';
import packageJson from '../package.json';
import AuthVerify from './utils/common/authverify';
import CorrectiveAction from './components/pages/correctiveaction/correctiveaction';
import Clients from './components/pages/clients/clients';
import Users from './components/pages/users/users';
import UserProfile from './components/pages/userprofile/userProfile';
import Settings from './components/pages/settings/settings';
import SamplePlan from './components/pages/sampleplan/sampleplan';
import TestPoint from './components/pages/testpoint/testpoint';

class App extends React.Component {
	public state = {
		isLoggedIn: false,
		noLogInPage: false,
		samplePlanPage: false,
		showSideBar: false
	};

	public constructor(props: any) {
		super(props);
	}

	logout = () => {
		window.localStorage.removeItem('AUTHDATA');
		window.location.href = "./";
	};

	parseJwt = (token) => {
		try {
			return JSON.parse(atob(token.split('.')[1]));
		} catch (e) {
			return null;
		}
	};

	componentWillMount() {
		const authData = JSON.parse(localStorage.getItem("AUTHDATA"));

		if (authData) {
			const decodedJwt = JSON.parse(atob(authData.token.split('.')[1]));

			if (decodedJwt.exp * 1000 < Date.now()) {
				this.logout();
			}

			const quoteData = localStorage.getItem("QUOTEDATA");
			if (quoteData != null) {
				this.setState({ isLoggedIn: true, samplePlanPage: true });
				this.setState({ noLogInPage: true });
			}
			else {
				if (window.location.href.toLowerCase().indexOf("sampleplanquotetool") > 0) {
					this.setState({ isLoggedIn: true, samplePlanPage: true });
				}
				else {
					this.setState({ isLoggedIn: true, samplePlanPage: false });
				}
			}
		}
		else {
			if (window.location.href.toLowerCase().indexOf("sampleplanquotetool") > 0) {
				this.setState({ isLoggedIn: false, samplePlanPage: true });
			}
			else {
				this.setState({ isLoggedIn: false, samplePlanPage: false });
			}
		}

		//if the new version deployed in the server,
		//Clear the cleints cache for this application
		clearCache(packageJson.version);
	}

	//componentDidMount () {
	//	const authData = JSON.parse(localStorage.getItem("AUTHDATA"));

	//	if (authData) {
	//		const decodedJwt = JSON.parse(atob(authData.token.split('.')[1]));

	//		if (decodedJwt.exp * 1000 < Date.now()) {
	//			this.logout();
	//		}
	//	}
	//}

	public render() {
		return (
			<React.Fragment>
				<Switch>
					<AppFrame isLoggedIn={this.state.isLoggedIn}
						noLogInPage={this.state.noLogInPage}
						samplePlanPage={this.state.samplePlanPage}>
						<Route
							exact
							path={['/', '/landing']}
							component={
								this.state.isLoggedIn
									? this.state.samplePlanPage ? SamplePlanQuoteTool : Landing
									: (this.state.samplePlanPage ? SamplePlanQuoteTool : Login)
							}
						/>
						<Route exact path={'/correctiveaction'} component={CorrectiveAction} />
						<Route exact path='/login' component={Login} />
						<Route exact path='/register' component={Register} />
						<Route exact path='/forgotpassword' component={ForgotPwd} />
						<Route exact path='/resetpwd/:email/:token' component={ResetPwd} />
						<Route exact path='/sampleplanquotetool' component={SamplePlanQuoteTool} />
						<Route exact path='/sites' component={Site} />
						<Route exact path='/settings' component={Settings} />
						<Route exact path='/test' component={Test} />
						<Route exact path={["/clients", "/clients/:id"]} component={Clients} />
						<Route exact path={["/userprofile", "/userprofile/:pg"]} component={UserProfile} />
						<Route exact path={["/users", "/users/:id"]} component={Users} />
						<Route exact path='/sampleplans' component={SamplePlan} />
						<Route exact path='/testpoints' component={TestPoint} />
						<Route path="/404" component={Home} />
					</AppFrame>
				</Switch>
			</React.Fragment>
		);
	}
}

export default App;