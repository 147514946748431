import * as React from 'react';
import { connect } from 'react-redux';
import Clients from "../../clients/clients";
import ClientDetails from "../../clients/clientdetails";
import { RouteComponentProps } from 'react-router';
import FCMessageDialog from '../../../utils/library/dialog';
import { getUserData } from '../../../utils/common/functions';
import { user } from '../../../utils/models/model';

interface IProps {
	id: number,
	match: any
	usersData: any
}

interface IState {
	id: number,
	showAlert: boolean,
	isSuccessAlert: boolean,
	alertMessage: string,
	showConfirm: boolean,
	title: string,
	user: any,
}

class ClientsComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	state = {
		id: 0,
		showAlert: false,
		isSuccessAlert: false,
		alertMessage: "",
		showConfirm: false,
		title: "",
		user: user
	}

	constructor(props: RouteComponentProps<{}> & IProps) {
		super(props);
		this.state = {
			id: this.props.match.params.id,
			showAlert: false,
			isSuccessAlert: false,
			alertMessage: "",
			showConfirm: false,
			title: "",
			user: user
		}
		this.showAlert = this.showAlert.bind(this);
	}

	showAlert(isShow, isSuccess, message) {
		this.setState({
			showAlert: isShow,
			isSuccessAlert: isSuccess,
			alertMessage: message
		});
	}

	closeDialog = () => {
		this.setState({ showAlert: false });
	}

	componentWillMount() {
		//if (this.props.match.params.id !== undefined) {
		//  this.setState({ id: this.props.match.params ? match.params.id: -1 });
		//}
	}

	componentDidUpdate(previousProps, previousState) {
		if (previousProps.match.params !== this.props.match.params) {
			this.setState({ id: this.props.match.params ? this.props.match.params.id : -1 });
		}

		let usrData = getUserData();

		if (previousProps.usersData?.usersResult !== this.props.usersData?.usersResult) {
			if (this.props.usersData?.usersResult.length > 0) {
				let usr = this.props.usersData?.usersResult.find((item) => item.id === usrData.id);
				if (usr)
					this.setState({ user: usr });
			}
		}
	}

	componentDidMount() {
		var alertMessage = window.localStorage.getItem('alertMessage');
		if (alertMessage && alertMessage != "") {
			this.setState({ showAlert: true, alertMessage: alertMessage, isSuccessAlert: true });
		}
		else {
		}
		window.localStorage.setItem('alertMessage', "");

		let usrData = getUserData();

		if (this.props.usersData?.usersResult.length > 0) {
			let usr = this.props.usersData?.usersResult.find((item) => item.id === usrData.id);
			if (usr)
				this.setState({ user: usr });
		}
	}

	render() {
		const { id, showAlert, isSuccessAlert, alertMessage } = this.state;
		return (
			<div className="px-20">
				{
					this.state.user?.roleId === 1 && this.state.user?.clientId === 1 ?
						<>
							{showAlert && <FCMessageDialog isSuccess={isSuccessAlert} modalCallBack={() => this.closeDialog()} message={alertMessage} />}
							{id >= 0 && <ClientDetails clientId={this.state.id} seeAlert={this.showAlert} />}
							{(id < 0 || id === undefined) && <Clients seeAlert={this.showAlert} />}
						</>
						:
						<h2 className="text-center mt-5">You do not have access to view this page</h2>
				}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	const { usersData } = state;
	return {
		usersData
	};
};

export default connect(mapStateToProps)(ClientsComponent);;