export const GETALLSITES_STARTED = "GETALLSITES_STARTED";
export const GETALLSITES_COMPLETED = "GETALLSITES_COMPLETED";
export const GETALLSITES_FAILED = "GETALLSITES_FAILED";

export const GETSITES_STARTED = "GETSITES_STARTED";
export const GETSITES_COMPLETED = "GETSITES_COMPLETED";
export const GETSITES_FAILED = "GETSITES_FAILED";

export const GETSITE_STARTED = "GETSITE_STARTED";
export const GETSITE_COMPLETED = "GETSITE_COMPLETED";
export const GETSITE_FAILED = "GETSITE_FAILED";

export const DELETESITE_STARTED = "DELETESITE_STARTED";
export const DELETESITE_COMPLETED = "DELETESITE_COMPLETED";
export const DELETESITE_FAILED = "DELETESITE_FAILED";

export const SAVESITE_STARTED = "SAVESITE_STARTED";
export const SAVESITE_COMPLETED = "SAVESITE_COMPLETED";
export const SAVESITE_FAILED = "SAVESITE_FAILED";

export const getAllSites = (input: any) => {
	return {
		type: GETALLSITES_STARTED,
		input: input
	};
};

export const getSitesByClient = (input: any) => {
  return {
    type: GETSITES_STARTED,
    input: input
  };
};

export const getSite = (input: any) => {
  return {
    type: GETSITE_STARTED,
    input: input
  };
};

export const deleteSite = (input: any) => {
  return {
    type: DELETESITE_STARTED,
    input: input
  };
};

export const saveSite = (input: any) => {
  return {
    type: SAVESITE_STARTED,
    input: input
  };
};