export const LOGIN_STARTED = "LOGIN_STARTED";
export const LOGIN_COMPLETED = "LOGIN_COMPLETED";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const LOGOUT_STARTED = "LOGOUT_STARTED";
export const LOGOUT_COMPLETED = "LOGOUT_COMPLETED";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const CHECKLOGGEDUSER_STARTED = "CHECKLOGGEDUSER_STARTED";
export const CHECKLOGGEDUSER_COMPLETED = "CHECKLOGGEDUSER_COMPLETED";
export const CHECKLOGGEDUSER_FAILED = "CHECKLOGGEDUSER_FAILED";

export const FORGET_PASSWORD_STARTED = "FORGET_PASSWORD_STARTED";
export const FORGET_PASSWORD_COMPLETED = "FORGET_PASSWORD_COMPLETED";
export const FORGET_PASSWORD_FAILED = "FORGET_PASSWORD_FAILED";

export const FORGET_PASSWORD_TOKEN_STARTED = "FORGET_PASSWORD_TOKEN_STARTED";
export const FORGET_PASSWORD_TOKEN_COMPLETED = "FORGET_PASSWORD_TOKEN_COMPLETED";
export const FORGET_PASSWORD_TOKEN_FAILED = "FORGET_PASSWORD_TOKEN_FAILED";

export const FORGET_PASSWORD_CHANGE_STARTED = "FORGET_PASSWORD_CHANGE_STARTED";
export const FORGET_PASSWORD_CHANGE_COMPLETED = "FORGET_PASSWORD_CHANGE_COMPLETED";
export const FORGET_PASSWORD_CHANGE_FAILED = "FORGET_PASSWORD_CHANGE_FAILED";

export const ACCOUNT_REGISTER_STARTED = "ACCOUNT_REGISTER_STARTED";
export const ACCOUNT_REGISTER_COMPLETED = "ACCOUNT_REGISTER_COMPLETED";
export const ACCOUNT_REGISTER_FAILED = "ACCOUNT_REGISTER_FAILED";


export const login = (loginInput: any) => {
  return {
    type: LOGIN_STARTED,
    input: loginInput
  };
};

export const logout = () => {
  return {
    type: LOGOUT_STARTED
  };
};

export const fetchCheckLoggedUser = () => {
  return {
    type: CHECKLOGGEDUSER_STARTED
  };
};

export const changeForgetPassword = (input: any) => {
  return {
    type: FORGET_PASSWORD_CHANGE_STARTED,
    input: input
  };
};

export const forgetpasswordRequest = (input: any) => {
  return {
    type: FORGET_PASSWORD_STARTED,
    input: input
  };
};

export const forgetPasswordValidateToken = (input) => {
  return {
    type: FORGET_PASSWORD_TOKEN_STARTED,
    input: input
  };
};

export const registerAccount = (input) => {
  return {
    type: ACCOUNT_REGISTER_STARTED,
    input: input
  };
};