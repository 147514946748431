import React from 'react';
import PropTypes from 'prop-types';
import { testPoint } from '../models/model';

const FCCheckBox = ({ onChange, className, options, selectedValues, label, isError, disabled, width }) => {
	const handleChange = (event) => {
		onChange(event);
	};

	return (
		<div className={"mb-3 mx-auto " + width}>
			<div className={"border-2 pl-2 rounded-2xl " + (isError ? ' border-red-300 ' : ' border-gray-300 ')}>
				<div className="flex-row w-100 overflow-x-hidden overflow-y-scroll h-24">
					{options && options.length === 0 && <h2> No Data Found. </h2>}
					{options && options.length > 0 && options.map((item) =>
						<div key={"keydiv" + item.id } className="relative flex items-start">
							<div className="flex items-center h-8 checkbox">
								{selectedValues && selectedValues.length > 0 && selectedValues.findIndex((val) => val === item.id) > -1 ?
									<input
										key={"key" + item.id.toString() + label.replace(' ', '')}
										id={item.id.toString() + label.replace(' ', '')}
										data-id={item.id}
										name={item.id.toString() + label.replace(' ', '')}
										type="checkbox"
										disabled={disabled}
										checked={true}
										onChange={(event) => handleChange(event)}
										className={"h-4 w-4 border-2 border-gray-300 rounded-2xl "
											+ (item.selected ? " bg-primary " : " bg-gray-300 ")
											+ className}
									/>
									:
									<input
										key={"key" + item.id.toString() + label.replace(' ', '')}
										id={item.id.toString() + label.replace(' ', '')}
										data-id={item.id}
										name={item.id.toString() + label.replace(' ', '')}
										type="checkbox"
										disabled={disabled}
										checked={false}
										onChange={(event) => handleChange(event)}
										className={"h-4 w-4 border-2 border-gray-300 rounded-2xl "
											+ (item.selected ? " bg-primary " : " bg-gray-300 ")
											+ className}
									/>
								}
							</div>
							<div className="ml-3 items-center pt-1">
								<div htmlFor={item.id} className="break-all font-medium blueText">
									{item.name}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{label &&
				<label className="flex flex-row text-gray-500">{label}</label>
			}
		</div>
	)
};

FCCheckBox.propTypes = {
	checked: PropTypes.bool,
	id: PropTypes.string,
	onChange: PropTypes.func,
	className: PropTypes.string,
	selectedValues: PropTypes.array,
	isError: PropTypes.bool,
	disabled: PropTypes.bool,
	width: PropTypes.string
};

FCCheckBox.defaultProps = {
	checked: false,
	className: "",
	isError: false,
	disabled: false,
	selectedValues: [],
	label: "",
	isError: false,
	width: " w-3/4 "
};

export default FCCheckBox;