import * as React from 'react';
import { ReactComponent as UserSvg } from '../../utils/common/icons/user-regular.svg';
import { ReactComponent as ClientsSvg } from '../../utils/common/icons/building-regular.svg';
import { ReactComponent as CheveronCircleLeftSvg } from '../../utils/common/icons/chevron-circle-left-solid.svg';
import { ReactComponent as CheveronCircleRightSvg } from '../../utils/common/icons/chevron-circle-right-solid.svg';
import { ReactComponent as ClipboardCheckSvg } from '../../utils/common/icons/clipboard-check-regular.svg';
import { ReactComponent as TestPlanSvg } from '../../utils/common/icons/clipboard-list-check-regular.svg';
import { ReactComponent as SettingsSvg } from '../../utils/common/icons/cog-regular.svg';
import { ReactComponent as EditSvg } from '../../utils/common/icons/edit-regular.svg';
import { ReactComponent as EnvelopSvg } from '../../utils/common/icons/envelope-regular.svg';
import { ReactComponent as SamplePlanSvg } from '../../utils/common/icons/file-alt-regular.svg';
import { ReactComponent as LockSvg } from '../../utils/common/icons/lock-alt-regular.svg';
import { ReactComponent as PlusCircleSvg } from '../../utils/common/icons/plus-circle-solid.svg';
import { ReactComponent as SitemapSvg } from '../../utils/common/icons/sitemap-regular.svg';
import { ReactComponent as TrashSvg } from '../../utils/common/icons/trash-regular.svg';
import { ReactComponent as UserFriendsSvg } from '../../utils/common/icons/user-friends-regular.svg';
import { ReactComponent as CloseSvg } from '../../utils/common/icons/close_icon.svg';
import { ReactComponent as BarSvg } from '../../utils/common/icons/bars-regular.svg';
import { ReactComponent as CheckSvg } from '../../utils/common/icons/check_icon.svg';
import { ReactComponent as SearchSvg } from '../../utils/common/icons/search.svg';
import { ReactComponent as CloseCircleSvg } from '../../utils/common/icons/corss-circle-close.svg';
import { ReactComponent as Pound } from '../../utils/common/icons/poundicon.svg';
import { ReactComponent as DashboardSvg } from '../../utils/common/icons/dashboard.svg';
import { ReactComponent as CameraSvg } from '../../utils/common/icons/camera-light.svg';
import { ReactComponent as DotVerticalSvg } from '../../utils/common/icons/dotverticalcircle.svg';
import { ReactComponent as PassSvg } from '../../utils/common/icons/pass_icon.svg';
import { ReactComponent as WarningSvg } from '../../utils/common/icons/warning_icon.svg';
import { ReactComponent as FailSvg } from '../../utils/common/icons/fail_icon.svg';

export function SearchSvgIcon(value) {
	return (
		<SearchSvg className='ml-1 h-5 w-5 mr-3 flex-none text-gray-400 pointer-events-none' aria-hidden='true' />
	)
};
export function CloseSearchIcon(value) {
	return (
		<CloseSvg className='ml-1 h-5 w-5 mr-3 flex-none text-gray-500 pointer-events-auto' aria-hidden='true' />
	)
}
export function UserSvgIcon(value) {
	return (
		<UserSvg className='ml-1 h-6 w-6 mr-3 text-fcbutton' aria-hidden='true' />
	)
};
export function ClientsSvgIcon(value) {
	return (
		<ClientsSvg className={'ml-1 h-8 w-8 mr-3 ' + (value.selected ? ' text-primary ' : ' text-fcgray ')} aria-hidden='true' />
	)
};
export function ClientsSvgIconLogin(value) {
	return (
		<ClientsSvg className='ml-1 h-6 w-6 mr-3 text-fcbutton' aria-hidden='true' />
	)
};
export function CheveronCircleLeftSvgIcon(value) {
	return (
		<CheveronCircleLeftSvg className='ml-1 h-8 w-8 text-fcbutton' aria-hidden='true' />
	)
};
export function CheveronCircleRightSvgIcon(value) {
	return (
		<CheveronCircleRightSvg className='ml-1 h-8 w-8 text-white' aria-hidden='true' />
	)
};
export function ClipboardCheckSvgIcon(value) {
	return (
		<ClipboardCheckSvg className={'ml-1 h-8 w-8 mr-3 ' + (value.selected ? ' text-primary ' : ' text-fcgray ')} aria-hidden='true' />
	)
};
export function TestPlanSvgIcon(value) {
	return (
		<TestPlanSvg className={'ml-1 h-8 w-8 mr-3 ' + (value.selected ? ' text-primary ' : ' text-fcgray ')} aria-hidden='true' />
	)
};
export function SettingsSvgIcon(value) {
	return (
		<SettingsSvg className={'ml-1 h-8 w-8 mr-3 ' + (value.selected ? ' text-primary ' : ' text-fcgray ')} aria-hidden='true' />
	)
};
export function EditSvgIcon(value) {
	return (
		<EditSvg className='ml-1 h-6 w-6 text-gray-500' aria-hidden='true' />
	)
};
export function EnvelopSvgIcon(value) {
	return (
		<EnvelopSvg className='ml-1 h-6 w-6 text-fcbutton' aria-hidden='true' />
	)
};
export function SamplePlanSvgIcon(value) {
	return (
		<SamplePlanSvg className={'ml-1 h-8 w-8 mr-3 ' + (value.selected ? ' text-primary ' : ' text-fcgray ')} aria-hidden='true' />
	)
};
export function LockSvgIcon(value) {
	return (
		<LockSvg className='ml-1 h-6 w-6 text-fcbutton' aria-hidden='true' />
	)
};
export function PlusCircleSvgIcon(value) {
	return (
		<PlusCircleSvg className='h-8 w-8 text-primary' aria-hidden='true' />
	)
};
export function PlusCircleSvgIconOrange(value) {
	return (
		<PlusCircleSvg className='h-8 w-8 text-fcbutton' aria-hidden='true' />
	)
};
export function PlusCircleSvgIconTransparent(value) {
	return (
		<PlusCircleSvg className='h-4 w-4 mr-2 mt-1' aria-hidden='true' />
	)
};
export function SitemapSvgIcon(value) {
	return (
		<SitemapSvg className={'ml-1 h-8 w-8 mr-3 ' + (value.selected ? ' text-primary ' : ' text-fcgray ')} aria-hidden='true' />
	)
};
export function TrashSvgIcon(value) {
	return (
		<TrashSvg className='ml-1 h-6 w-6 text-gray-500' aria-hidden='true' />
	)
};
export function UserFriendsSvgIcon(value) {
	return (
		<UserFriendsSvg className={'ml-1 h-8 w-8 mr-3 ' + (value.selected ? ' text-primary ' : ' text-fcgray ')} aria-hidden='true' />
	)
};
export function CloseIcon(value) {
	return (
		<CloseSvg className={'ml-1 h-6 w-6 mr-3 ' + (value.selected ? ' text-primary ' : ' text-gray-500 ')} aria-hidden='true' />
	)
};
export function BarIcon(value) {
	return (
		<BarSvg className={'ml-1 h-8 w-8 mr-3 ' + (value.selected ? ' text-primary ' : ' text-fcgray ')} aria-hidden='true' />
	)
};
export function CheckIcon(value) {
	return (
		<CheckSvg className={'ml-1 h-7 w-7 mr-3 ' + (value.selected ? ' text-primary ' : ' text-fcgray ')} aria-hidden='true' />
	)
};


export function TickIcon(value) {
	return (
		<CheckSvg className={'ml-1 h-7 w-7 mr-3 ' + (value.selected ? ' text-primary ' : ' text-green-400 ')} aria-hidden='true' />
	)
};

export function CACloseIcon(value) {
	return (
		<CloseCircleSvg className={'h-7 w-7 mr-3 ' + (value.selected ? ' text-fcbutton ' : ' text-fcgray ')} aria-hidden='true' />
	)
};
export function PoundIcon(value) {
	return (
		<Pound className='h-3 w-5 text-gray-500 inline-block' aria-hidden='true' />
	)
};
export function DashboardSvgIcon(value) {
	return (
		<DashboardSvg className={'ml-1 h-8 w-8 mr-3 ' + (value.selected ? ' text-primary ' : ' text-fcgray ')} aria-hidden='true' />
	)
};
export function UserEnvelopSvgIcon(value) {
	return (
		<EnvelopSvg className='ml-1 h-5 w-5 text-gray-500' aria-hidden='true' />
	)
};

export function UserUserSvgIcon(value) {
	return (
		<UserSvg className='ml-1 h-5 w-5 mr-3 text-gray-500' aria-hidden='true' />
	)
};

export function UserLockSvgIcon(value) {
	return (
		<LockSvg className='ml-1 h-5 w-5 text-gray-500' aria-hidden='true' />
	)
};
export function CameraSvgIcon(value) {
	return (
		<CameraSvg className={'ml-1 h-5 w-5 mr-3 ' + (value.selected ? ' text-gray-900 ' : ' text-fcgray ')} aria-hidden='true' />
	)
};
export function DotVerticalSvgSvgIcon(value) {
	return (
		<DotVerticalSvg className={'ml-1 h-8 w-8 mr-3 ' + (value.selected ? ' text-gray-900 ' : ' text-fcbutton ')} aria-hidden='true' />
	)
};
export function PassIcon(value) {
	return (
		<PassSvg className={'ml-1 h-6 w-6 mr-3  text-primary'} aria-hidden='true' />
	)
};
export function WarningIcon(value) {
	return (
		<WarningSvg className={'ml-1 h-6 w-6 mr-3  text-primary'} aria-hidden='true' />
	)
};
export function FailIcon(value) {
	return (
		<FailSvg className={'ml-1 h-6 w-6 mr-3  text-primary'} aria-hidden='true' />
	)
};