import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useState } from 'react';
import FCInputField from '../../utils/library/textbox';
import FCButton from '../../utils/library/button';
import { Validators, validateInput } from '../../utils/library/Validator';
import { forgetpasswordRequest } from '../../store/actions/account';

import {
	EnvelopSvgIcon,
	CheveronCircleRightSvgIcon, CheveronCircleLeftSvgIcon
} from '../../utils/common/icon-hooks.js';

interface ILoginProps {
	dispatch: Dispatch<any>;
	authData: any;
}

const ForgotPwd: React.SFC<ILoginProps> = ({
	dispatch, authData }) => {

	const [email, setEmail] = useState("");
	const [isResend, setIsResend] = useState(false);
	const [isEmailEntered, setIsEmailEntered] = useState(false);
	const validators =
		[{
			check: Validators.required,
			message: 'Enter the email id.'
		}];

	const handleChange = (event) => {
		setEmail(event.target.value);
		setIsEmailEntered(validateInput(validators, event.target.value));
	};

	const handleChangePwd = async (event, isResend) => {
		event.preventDefault();
		if (isResend) { setIsResend(false); }
		setIsEmailEntered(validateInput(validators, email));
		if (email !== '') {
			dispatch(forgetpasswordRequest(email));
		}
	};

	const goToLoginPage = async (event) => {
		window.location.href = "./";
	};

	if (!authData.isFormSubmit && !isResend) {
		if (authData.status.statusValue) {
			setIsResend(true);
		}
	}

	return (
		<React.Fragment>
			<div className="min-h-screen flex items-center justify-center bg-primary p-12 px-4 sm:px-6 lg:px-8">
				<div className="max-w-md w-full space-y-8 p-8 bg-white rounded-2xl border-4">
					<div className="mt-8">
						<img
							className="mx-auto h-12 w-auto"
							src="../../../../assets/logo/logo.png"
							alt="FreshCheck"
						/>
					</div>
					<div className="pb-40">
						{!authData.status.statusValue && !authData.isError && !isResend &&
							<>
								<p className="text-lg  text-black  text-center">
									To reset password enter email below.
                </p>
								<form className="pt-3" onSubmit={(event) => handleChangePwd(event, false)}>
									<button
										type="button"
										onClick={goToLoginPage}
										className="inline-block border -mb-2 border-transparent rounded-full align-bottom bg-white-600 pt-3">
										<CheveronCircleLeftSvgIcon />
									</button>
									<div className="inline-block ml-10 -mt-14 py-6 w-4/5 -space-y-px rounded-2xl">
										<FCInputField isBorder={false} className="bg-fcinput w-full" type="text" id="username" placeholder="Email" icon="email" isError={isEmailEntered}
											onChange={(event) => handleChange(event)} value={email} />
										<div>
											<FCButton bgColor="bg-fcbutton w-full" type="submit" icon="rightarrow" value="Send" />
										</div>
									</div>
								</form>
							</>
						}

						{authData.isSuccess && !authData.isError && isResend &&
							<>
							<p className="text-lg  text-black  text-center">{authData.isSuccess && isResend &&
									<text className="text-black"> We sent you a recovery email.</text>
								}
								</p>
								<form className="pt-3" onSubmit={(event) => handleChangePwd(event, true)}>
									<button
										type="button"
										onClick={goToLoginPage}
										className="inline-block border border-transparent rounded-full align-middle bg-white-600 pt-3">
										<CheveronCircleLeftSvgIcon />
									</button>
									<div className="inline-block ml-10 -mt-16 py-6 w-4/5 -space-y-px rounded-2xl">
										<FCInputField isBorder={false} className="bg-fcinput w-full" type="text" id="username" placeholder="Email" icon="email" isError={isEmailEntered}
											onChange={(event) => handleChange(event)} value={email} />
										<div>
											<FCButton bgColor="bg-fcbutton w-full" type="submit" icon="rightarrow" value="Resend" />
										</div>
									</div>
								</form>
							</>
						}
						{
							<p className="text-lg h-2 text-red-500 mt-3   text-center">
								{authData.isSuccess && !authData.status.statusValue && !isResend && <text>Invalid Email <br /></text>}
								{(isEmailEntered) && "Please enter the required fields."}
							</p>
						}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state: any) => {
	const { authData } = state;
	return {
		authData
	};
};

export default connect(mapStateToProps)(ForgotPwd);
