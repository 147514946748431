import { put, call } from "redux-saga/effects";
import { UserProfileApi } from "../../utils/api/userProfileApi";

import {
  GET_USERPROFILE_COMPLETED, GET_USERPROFILE_FAILED,
  UPDATE_USERPROFILE_COMPLETED, UPDATE_USERPROFILE_FAILED,
  CHANGEPASSWORD_COMPLETED, CHANGEPASSWORD_FAILED,
  CHANGEAVATAR_COMPLETED, CHANGEAVATAR_FAILED
} from '../actions/userProfile';

export function* getUserProfile(request: any) {
  try {
    const result = yield call(UserProfileApi.getUserProfile, request.input);
    yield put({
      type: GET_USERPROFILE_COMPLETED,
      payload: result.data,
      input: request.input
    });
  } catch (e) {
    yield put({ type: GET_USERPROFILE_FAILED, payload: e.message });
  }
}

export function* updateUser(request: any) {
  try {
    const result = yield call(UserProfileApi.updateUser, request.input);
    yield put({
      type: UPDATE_USERPROFILE_COMPLETED,
      payload: result.data
    });
  } catch (e) {
    yield put({ type: UPDATE_USERPROFILE_FAILED, payload: e.message });
  }
}

export function* updateAvatar(request: any) {
  try {
    const result = yield call(UserProfileApi.updateAvatar, request.input);
    yield put({
      type: CHANGEAVATAR_COMPLETED,
      payload: result.data
    });
  } catch (e) {
    yield put({ type: CHANGEAVATAR_FAILED, payload: e.message });
  }
}

export function* changePassword(request: any) {
  try {
    const result = yield call(UserProfileApi.changePassword, request.input);
    yield put({
      type: CHANGEPASSWORD_COMPLETED,
      payload: result.data
    });
  } catch (e) {
    yield put({ type: CHANGEPASSWORD_FAILED, payload: e.message });
  }
}