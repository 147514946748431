import * as React from 'react';
import { connect } from 'react-redux';
import Settings from "../../settings/settings";
import FCMessageDialog from '../../../utils/library/dialog';
import { RouteComponentProps } from 'react-router';

interface IProps {
}

interface IState {
  showAlert: boolean,
  isSuccessAlert: boolean,
  alertMessage: string
}

class SettingsComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showAlert: false,
      isSuccessAlert: false,
      alertMessage: ""
    }
    this.seeAlert = this.seeAlert.bind(this);
  }

  seeAlert(isShow, isSuccess, message) {
    this.setState({
      showAlert: isShow,
      isSuccessAlert: isSuccess,
      alertMessage: message
    });
  }

  closeDialog = () => {
    this.setState({ showAlert: false });
  }

  componentDidMount() {
  }

  render() {
    const { showAlert, isSuccessAlert, alertMessage } = this.state;
    return (
      <>
        {showAlert && <FCMessageDialog isSuccess={isSuccessAlert} modalCallBack={() => this.closeDialog()} message={alertMessage} />}
        <div className="px-20">
					<Settings seeAlert={this.seeAlert} />
        </div>
      </>
    );}
}

export default connect()(SettingsComponent);;