import * as React from 'react';
import { connect } from 'react-redux';

const Home = () => (
  <div className="bg-gray-900 p-20 h-screen flex justify-center items-start flex-col">
    <h1 className="text-5xl text-white">Oops.. Invalid Request..</h1>
  </div>
);

export default connect()(Home);
