import {
  GET_USERPROFILE_STARTED, GET_USERPROFILE_COMPLETED, GET_USERPROFILE_FAILED,
  UPDATE_USERPROFILE_STARTED, UPDATE_USERPROFILE_COMPLETED, UPDATE_USERPROFILE_FAILED,
  CHANGEPASSWORD_STARTED, CHANGEPASSWORD_COMPLETED, CHANGEPASSWORD_FAILED,
  CHANGEAVATAR_STARTED, CHANGEAVATAR_COMPLETED, CHANGEAVATAR_FAILED
} from '../actions/userProfile';
import { userProfile } from '../../utils/models/model';

const initialState = {
  isError: false,
  isUserLoaded: false,
  isUserSaved: false,
  isPasswordChanged: false,
  isAvatarUpdated: false,
  userInput: {
    userId: -1,
    userToken: ""
  },
  userResult: userProfile
}

const userProfileData = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_USERPROFILE_STARTED:
      return {
        ...state,
				userInput: action.input,
				isUserLoaded: false
      };
    case GET_USERPROFILE_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        userResult: action.payload.result,
        isUserLoaded: true
      };
    case GET_USERPROFILE_FAILED:
      return {
        ...state,
        error: action.payload,
				isError: true,
				isUserLoaded: false
      };

    case UPDATE_USERPROFILE_STARTED:
      return {
        ...state,
				userInput: action.input,
				isUserSaved: false
      };
    case UPDATE_USERPROFILE_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        isUserSaved: true
      };
    case UPDATE_USERPROFILE_FAILED:
      return {
        ...state,
        error: action.payload,
				isError: true,
				isUserSaved: false
      };

    case CHANGEPASSWORD_STARTED:
      return {
        ...state,
				userInput: action.input,
				isPasswordChanged: false
      };
    case CHANGEPASSWORD_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        isPasswordChanged: true
      };
    case CHANGEPASSWORD_FAILED:
      return {
        ...state,
        error: action.payload,
				isError: true,
				isPasswordChanged: false
      };

    case CHANGEAVATAR_STARTED:
      return {
        ...state,
				userInput: action.input,
				isAvatarUpdated: false
      };
    case CHANGEAVATAR_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        isAvatarUpdated: true
      };
    case CHANGEAVATAR_FAILED:
      return {
        ...state,
        error: action.payload,
				isError: true,
				isAvatarUpdated: false
      };
    default:
      return state;
  }
};

export default userProfileData;