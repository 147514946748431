import * as React from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Fragment, useState } from 'react'
import { Dispatch } from 'redux';
import { Dialog, Transition } from '@headlessui/react'
import { getUsers, getUserRoles } from '../../store/actions/user';
import { getUserData, addDefaultSrc } from '../../utils/common/functions';

import {
	SettingsSvgIcon, TestPlanSvgIcon,
	ClientsSvgIcon, UserFriendsSvgIcon,
	SitemapSvgIcon, SamplePlanSvgIcon, DashboardSvgIcon,
	ClipboardCheckSvgIcon, BarIcon, CloseIcon
} from '../../utils/common/icon-hooks.js';

interface ISidebarProps {
	dispatch: Dispatch<any>;
	usersData: any;
	isLogged: boolean;
	callback: any;
	isOpenSideBar: any;
	children: any;
}

const userRole = [
	{ name: 'Dashboard', href: '/landing/#', icon: DashboardSvgIcon, current: true, id: 1 },
	{ name: 'na', href: '', icon: TestPlanSvgIcon, current: false, id: 2 },
	{ name: 'Settings', href: '/settings', icon: SettingsSvgIcon, current: false, id: 3 }
]

const adminRole = [
	{ name: 'Dashboard', href: '/landing/#', icon: DashboardSvgIcon, current: false, id: 1 },
	{ name: 'Sites', href: '/sites', icon: SitemapSvgIcon, current: false, id: 2 },
	{ name: 'Sample Plan', href: '/sampleplans', icon: SamplePlanSvgIcon, current: false, id: 3 },
	{ name: 'Test Points', href: '/testpoints', icon: TestPlanSvgIcon, current: false, id: 4 },
	{ name: 'User Management', href: '/users', icon: UserFriendsSvgIcon, current: false, id: 5 },
	{ name: 'na', href: '', icon: TestPlanSvgIcon, current: false, id: 6 },
	{ name: 'Settings', href: '/settings', icon: SettingsSvgIcon, current: false, id: 7 },
]

const FCAdminRole = [
	{ name: 'Dashboard', href: '/landing/#', icon: DashboardSvgIcon, current: true, id: 1 },
	{ name: 'Sites', href: '/sites', icon: SitemapSvgIcon, current: false, id: 2 },
	{ name: 'Sample Plan', href: '/sampleplans', icon: SamplePlanSvgIcon, current: false, id: 3 },
	{ name: 'Test Points', href: '/testpoints', icon: TestPlanSvgIcon, current: false, id: 4 },
	{ name: 'User Management', href: '/users', icon: UserFriendsSvgIcon, current: false, id: 5 },
	{ name: 'na', href: '', icon: TestPlanSvgIcon, current: false, id: 6 },
	{ name: 'Clients', href: '/clients', icon: ClientsSvgIcon, current: false, id: 7 },
	{ name: 'Corrective Action', href: '/correctiveaction', icon: ClipboardCheckSvgIcon, current: false, id: 8 },
	{ name: 'na', href: '', icon: TestPlanSvgIcon, current: false, id: 9 },
	{ name: 'Settings', href: '/settings', icon: SettingsSvgIcon, current: false, id: 10 },
]

const settingsRole = [
	{ name: 'Settings', href: '/settings', icon: SettingsSvgIcon, current: false },
]

const SideBar: React.SFC<ISidebarProps> = ({
	dispatch, usersData, isLogged, callback, isOpenSideBar, children }) => {

	const history = useHistory()
	const [navigation, setNavigation] = useState([{ name: '', href: '', icon: TestPlanSvgIcon, current: false, id: 0 }]);
	const logout = (event) => {
		callback(false);
	};
	const [userAvatar, setUserAvatar] = useState('');

	React.useEffect(() => {
		var usrData = getUserData();
		setUserAvatar(usrData.imageUrl);
		setNavigation(FCAdminRole); //TODO : temporary..

		if (usrData.roleId === 2) { setNavigation(userRole); }
		if (usrData.roleId === 1 && usrData.clientId !== 1) { setNavigation(adminRole); }
		if (usrData.roleId === 1 && usrData.clientId === 1) { setNavigation(FCAdminRole); }

		dispatch(getUsers({ userId: usrData.id, userToken: usrData.userToken }));
		dispatch(getUserRoles({ userId: usrData.id, userToken: usrData.userToken }));
	}, []);

	React.useEffect(() => {
		var usrData = getUserData();

		if (usersData?.usersResult.length > 0) {
			let usr = usersData?.usersResult.find((item) => item.id === usrData.id);

			if (usr?.roleId === 2) { setNavigation(userRole); }
			if (usr?.roleId === 1 && usr?.clientId !== 1) { setNavigation(adminRole); }
			if (usr?.roleId === 1 && usr?.clientId === 1) { setNavigation(FCAdminRole); }
		}
	}, [usersData?.usersResult])

	const [sidebarOpen, setSidebarOpen] = useState(isOpenSideBar);

	function classNames(...classes) {
		return classes.filter(Boolean).join(' ')
	}

	return (
		<div className="h-screen flex overflow-hidden bg-white">
			<div className="h-screen flex overflow-hidden bg-white">
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={() => setSidebarOpen(false)}>
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
						</Transition.Child>
						<Transition.Child
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
							<div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute top-0 right-0 -mr-12 pt-2">
										<button
											type="button"
											className="ml-1 pl-2 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none"
											onClick={() => setSidebarOpen(false)}
										>
											<span className="sr-only">Close sidebar</span>
											<CloseIcon />
										</button>
									</div>
								</Transition.Child>
								<div className="flex flex-col pt-5 pb-4 px-10 overflow-y-auto">
									<div className="flex items-center flex-shrink-0 py-6">
										<img
											className="h-8 w-auto"
											src="../../../../assets/logo/logo.png"
											alt="Workflow"
										/>
									</div>
									<nav className="mt-5 flex-1 px-2 bg-white space-y-3">
										{navigation.map((item) => {
											if (item.name === 'na') {
												return <hr key={"hr1" + item.id} />;
											}
											else {
												return (
													<div
														key={item.name}
														className={classNames(
															(window.location.href.indexOf(item.href.substring(1)) >= 0)
																? 'text-primary cursor-pointer '
																: 'text-fcgray hover:bg-gray-50 hover:text-gray-700 cursor-pointer ',
															'group flex items-center px-2 py-1 text-base  rounded-md sideBar cursor-pointer '
														)}
														onClick={() => history.push(item.href)}
													>
														<item.icon key={"icon" + item.name} multiColor={true}
															selected={(window.location.href.indexOf(item.href.substring(1)) >= 0) ? true : false} />
														{item.name}
													</div>
												)
											}
										})}
									</nav>
								</div>
								<div className="flex-shrink-0 flex mx-auto mt-5 mb-2">
									<a href="#" className="flex-shrink-0 w-full group block">
										<div className="flex items-center">
											<div>
												<img
													id="userImg"
													className="inline-block object-cover h-16 w-16 rounded-full"
													src={userAvatar}
													onError={addDefaultSrc}
													alt=""
												/>
											</div>
										</div>
									</a>
								</div>
								<div className="flex-shrink-0 flex mx-auto">
									<a href="./userprofile" className="flex-shrink-0 w-full ">
										<div className="items-center">
											<div className={
												classNames(
													(window.location.href.toLowerCase().indexOf('userprofile') >= 0)
														? 'text-primary '
														: 'text-fcgray hover:bg-gray-50 hover:text-gray-700 ',
													'group flex items-center px-2 py-1 text-base  sideBar'
												)}>
												My Account
											</div>
										</div>
									</a>
								</div>
								<div className="flex-shrink-0 flex mx-auto">
									<a href="#" className="flex-shrink-0 w-full " onClick={logout}>
										<div className="items-center">
											<div className=" text-base text-gray-500 hover:bg-gray-50 hover:text-gray-700 sideBar">
												Logout
											</div>
										</div>
									</a>
								</div>
							</div>
						</Transition.Child>
						<div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
					</Dialog>
				</Transition.Root>
			</div>
			{/* Static sidebar for desktop */}
			<div className="hidden md:flex md:flex-shrink-0">
				<div className="flex flex-col w-80 border-4 rounded-r-2xl border-gray-200">
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className="flex-1 flex flex-col min-h-0">
						<div className="flex flex-col pt-5 pb-4 px-10 overflow-y-auto">
							<div className="flex items-center flex-shrink-0 py-6">
								<img
									className="h-12 w-auto"
									src="../../../../assets/logo/logo.png"
									alt="Workflow"
								/>
							</div>
							<nav className="mt-5 flex-1 px-2 bg-white space-y-3">
								{navigation.map((item) => {
									if (item.name === 'na') {
										return <hr key={"hr2" + item.id} />;
									}
									else {
										return (
											<div
												key={"b" + item.name}
												className={classNames(
													(window.location.href.indexOf(item.href.substring(1)) >= 0)
														? 'text-primary cursor-pointer '
														: 'text-fcgray hover:bg-gray-50 hover:text-gray-700 cursor-pointer ',
													'group flex items-center px-2 py-1 text-lg  rounded-md sideBar cursor-pointer '
												)}
												onClick={() => history.push(item.href)}
											>
												<item.icon key={"icon1" + item.name}
													selected={(window.location.href.indexOf(item.href.substring(1)) >= 0) ? true : false} />
												{item.name}
											</div>
										)
									}
								})}
							</nav>
						</div>
						<div className="flex-shrink-0 flex mx-auto mt-5 mb-2">
							<div className="flex-shrink-0 w-full group block cursor-pointer" onClick={() => history.push("/userprofile")}>
								<div className="flex items-center">
									<div>
										<img
											id="userImg"
											className="inline-block object-cover h-16 w-16 rounded-full"
											src={userAvatar}
											onError={addDefaultSrc}
											alt=""
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="flex-shrink-0 flex mx-auto">
							<div className="flex-shrink-0 w-full cursor-pointer " onClick={() => history.push("/userprofile")}>
								<div className="items-center">
									<div className={
										classNames(
											(window.location.href.toLowerCase().indexOf('userprofile') >= 0)
												? 'text-primary '
												: 'text-fcgray hover:bg-gray-50 hover:text-gray-700 ',
											'group flex items-center px-2 py-1 text-base rounded-md sideBar '
										)}>
										My Account
									</div>
								</div>
							</div>
						</div>
						<div className="flex-shrink-0 flex mx-auto">
							<a href="#" className="flex-shrink-0 w-full " onClick={logout}>
								<div className="items-center">
									<div className="text-base  text-gray-500 hover:bg-gray-50 hover:text-gray-700 sideBar">
										Logout
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col w-0 flex-1 overflow-hidden">
				<div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
					<button
						type="button"
						className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none"
						onClick={() => setSidebarOpen(true)}
					>
						<span className="sr-only">Open sidebar</span>
						<BarIcon />
					</button>
				</div>
				<main className="flex-1 relative z-0 focus:outline-none">
					<div className="mx-10 my-5 h-screen-body overflow-y-auto border-4 border-gray-300 sm:rounded-3xl">
						{children}
					</div>
				</main>
			</div>
		</div>
	)
}

const mapStateToProps = (state: any) => {
	const { usersData } = state;
	return {
		usersData
	};
};

export default connect(mapStateToProps)(SideBar);
