import { Http } from "../Http";
import { AxiosPromise } from "axios";
export class SiteApi {

	public static getAllSites(input: any) {
		return Http.axios().get('/Site/GetAll?'
			+ 'userId=' + input.userId
			+ '&userToken=' + input.userToken
			+ '&clientId=' + input.clientId
		).then(response => {
			return response;
		})
			.catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

  public static getSitesByClient(input: any) {
		return Http.axios().get('/Site/GetSitesByClient?'
      + 'userId=' + input.userId 
      + '&userToken=' + input.userToken
      + '&clientId=' + input.clientId
    ).then(response => {
      return response;
    })
    .catch((e) => {
      return e.response;
    }) as AxiosPromise<any>;
  }

  public static getSite(input: any) {
    return Http.axios().get('/Site/GetSite?'
      + 'userId=' + input.userId
      + '&userToken=' + input.userToken
      + '&clientId=' + input.clientId
    ).then(response => {
      return response;
    })
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static deleteSite(input: any) {
    var obj = JSON.stringify(input);
    var url = '/Site/DeleteSite';
    return Http.axios().post(url, obj, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        return response;
      }).catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static saveSite(input: any) {
    var obj = JSON.stringify(input);
    var url = '/Site/SaveSite';
    return Http.axios().post(url, obj, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        return response;
      }).catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }
}