import * as React from 'react';
import { connect } from 'react-redux';
import ResetPwd from "../../../components/account/resetpwd";

import "./login.css";

class ResetPwdComponent extends React.Component {
  state = {
    email: "", token: ""
  }
  constructor(props: any) {
    super(props);
    this.state = {
      email: props.match.params.email,
      token: props.match.params.token,
    };
  }

  render() {
    return (
      <ResetPwd token={this.state.token} email={this.state.email} />
    );
  }
}

export default connect()(ResetPwdComponent);