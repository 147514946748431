import { Http } from "../Http";
import { AxiosPromise } from "axios";
import authHeader from '../common/functions'
export class AccountAPI {

  public static register(input: any) {
    var obj = JSON.stringify(input);
    return Http.axios().post('/Account/RegisterAccount', obj, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        return response;
      }).catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static login(input: any) {
    var obj = JSON.stringify(input);
    return Http.axios().post('/Account/Login', obj, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        return response;
      }).catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static forgotPassord(email: string) {
    return Http.axios().post('/Account/ForgetPwd?email=' + email)
      .then(response => {
        return response;
      }).catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static forgetPasswordValidateToken(email:string, token: string) {
    var obj = JSON.stringify("");
    return Http.axios().post('/Account/ForgotPwdValidateToken?email=' + email + '&resetToken=' + token)
      .then(response => {
        return response;
      })
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static forgotPassordChange(input: any) {
    var obj = JSON.stringify(input);
    return Http.axios().post('/Account/ChangePassword', obj, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        return response;
      }).catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
	}

	public static changePassword(input: any) {
		var obj = JSON.stringify(input);
		return Http.axios().post('/Account/ChangePassword', obj, { headers: { 'Content-Type': 'application/json' } })
			.then(response => {
				return response;
			}).catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

  public static logout() {
    return Http.axios().get('/Account/logout')
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  //TODO: may have to remove
  public static fetchCheckLoggedUser(userName: string) {
    return Http.axios().get('/Account/CheckIsCurrentUserValid?userName=' + userName)
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static getData(userName: string) {
    return Http.axios().get('/Account/getData?userName=' + userName, { headers: { authHeader } })
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
    }

    public static validateCompanyExist(input: any) {
        return Http.axios().get('/Account/ValidateCompanyExist?companyName=' + input.name)
            .catch((e) => {
                return e.response;
            }) as AxiosPromise<any>;
    }

}