import {
	GETALLSITES_STARTED,
	GETALLSITES_COMPLETED,
	GETALLSITES_FAILED,
  GETSITES_STARTED,
  GETSITES_COMPLETED,
  GETSITES_FAILED,
  GETSITE_STARTED,
  GETSITE_COMPLETED,
  GETSITE_FAILED,
  DELETESITE_STARTED,
  DELETESITE_COMPLETED,
  DELETESITE_FAILED,
  SAVESITE_STARTED,
  SAVESITE_COMPLETED,
  SAVESITE_FAILED
} from '../actions/site';

import { site } from '../../utils/models/model';

const initialState = {
  userId: 0,
  usertoken: "",
  isError: false,
  isSaved: false,
  isSiteLoaded: false,
	isSitesLoaded: false,
	isAllSitesLoaded: false,
  isSiteSaved: false,
  isSiteDeleted: false,
  sites: [site],
  site: site
};

const siteData = (state = initialState, action: any) => {
	switch (action.type) {
		case GETALLSITES_STARTED:
			return {
				...state,
				input: action.input,
				isAllSitesLoaded: false
			};
		case GETALLSITES_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				sites: action.payload.result,
				isAllSitesLoaded: true
			};
		case GETALLSITES_FAILED:
			return {
				...state,
				isError: true,
				isAllSitesLoaded: false
			};
    case GETSITES_STARTED:
      return {
        ...state,
        input: action.input,
        isSitesLoaded: false
      };
    case GETSITES_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        sites: action.payload.result,
        isSitesLoaded: true
      };
    case GETSITES_FAILED:
      return {
        ...state,
        isError: true,
        isSitesLoaded: false
      };
    case GETSITE_STARTED:
      return {
        ...state,
        isLoading: true,
        isFormSubmit: true,
				input: action.input,
				isSiteLoaded: false
      };
    case GETSITE_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        site: action.payload.result,
        isSiteLoaded: true
      };
    case GETSITE_FAILED:
      return {
        ...state,
				isError: true,
				isSiteLoaded: false
      };
    case DELETESITE_STARTED:
      return {
        ...state,
        isLoading: true,
        isFormSubmit: true,
				input: action.input,
				isSiteDeleted: false
      };
    case DELETESITE_COMPLETED:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        isFormSubmit: true,
        isSiteDeleted: true
      };
    case DELETESITE_FAILED:
      return {
        ...state,
				isError: true,
				isSiteDeleted: false
      };
    case SAVESITE_STARTED:
      return {
        ...state,
				input: action.input,
				isSiteSaved: false
      };
    case SAVESITE_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        isSiteSaved: true
      };
    case SAVESITE_FAILED:
      return {
        ...state,
				isError: true,
				isSiteSaved: false
      };
    default:
      return state;
  }
};

export default siteData;
