import { all, put, call } from "redux-saga/effects";
import { DashboardAPI } from "../../utils/api/dashboardApi";
import { ClientPreferencesApi } from "../../utils/api/clientPreferencesApi";
import {
	GET_ALL_REPORT_COMPLETED,
	GET_ALL_REPORT_FAILED,

	GET_GLOBAL_COMPARISON_COMPLETED,
	GET_GLOBAL_COMPARISON_FAILED,

	GET_DASHBOARD_DATA_COMPLETED,
	GET_DASHBOARD_DATA_FAILED,

	GET_DOWNLOAD_REPORT_COMPLETED,
	GET_DOWNLOAD_REPORT_FAILED,

	GET_DOWNLOAD_DATA_COMPLETED,
	GET_DOWNLOAD_DATA_FAILED,

	GET_TESTHISTORY_FAILED,
  GET_TESTHISTORY_COMPLETED
} from '../actions/dashboard';
import test from "../../components/pages/test";

export function* getAllReport(request: any) {
	try {
		const result = yield call(DashboardAPI.getAllReport, request.input);
		yield put({
			type: GET_ALL_REPORT_COMPLETED,
			payload: result.data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: GET_ALL_REPORT_FAILED, payload: e.message });
	}
}

export function* getGlobalComparisonData(request: any) {
	try {
		const result = yield call(DashboardAPI.getGlobalComparisonData, request.input);
		yield put({
			type: GET_GLOBAL_COMPARISON_COMPLETED,
			payload: result.data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: GET_GLOBAL_COMPARISON_FAILED, payload: e.message });
	}
}

export function* getDashboardData(request: any) {
	try {
		const result = yield call(DashboardAPI.getDashboardData, request.input);
		yield put({
			type: GET_DASHBOARD_DATA_COMPLETED,
			payload: result.data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: GET_DASHBOARD_DATA_FAILED, payload: e.message });
	}
}

export function* getDownloadReport(request: any) {
	try {
		const result = yield call(DashboardAPI.getDownloadReport, request.input);
		yield put({
			type: GET_DOWNLOAD_REPORT_COMPLETED,
			payload: result.data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: GET_DOWNLOAD_REPORT_FAILED, payload: e.message });
	}
}

export function* getDownloadData(request: any) {
	try {
		const result = yield call(DashboardAPI.getDownloadData, request.input);
		yield put({
			type: GET_DOWNLOAD_DATA_COMPLETED,
			payload: result.data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: GET_DOWNLOAD_DATA_FAILED, payload: e.message });
	}
}

export function* getTestHistoryData(request: any) {
	try {
		const { testHistorys, clientPreferences, testResultTypes } = yield all({
			testHistorys: call(DashboardAPI.getTestHistoryData, request.input),
			clientPreferences: call(ClientPreferencesApi.getClientPreferences, request.input),
			testResultTypes: call(ClientPreferencesApi.getTestResultTypes, request.input),
		});
		const testHistoryData = [...testHistorys?.data?.result];
		const clientPreferenceData = [...clientPreferences?.data?.result];
		const testResultTypeData = [...testResultTypes?.data?.result];
		const clientData = clientPreferenceData?.map((clientPreference) => {
			const data = testResultTypeData?.filter((item) => item.id === clientPreference.testScaleId);
			return {
				...clientPreference,
				testResult: data.length > 0 ? data[0].name : "",
			}
		});
		const tests = testHistoryData?.map((testHistory) => {
			const data = clientData?.filter((item) => item.scaleId === testHistory.result);
			return {
				...testHistory,
				resultType: data.length > 0 ? data[0].testResult : ""
			}
		});
		const data = { result: tests, status: testHistorys.status };
		yield put({
			type: GET_TESTHISTORY_COMPLETED,
			payload: data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: GET_TESTHISTORY_FAILED, payload: e.message });
	}
}