import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getSettings, updateSettings } from '../../store/actions/settings';
import ToggleSwitch from '../../utils/library/toggleSwitch';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import { getUserData } from '../../utils/common/functions';
import FCMessageDialog from '../../utils/library/dialog';
import Select, { StylesConfig } from 'react-select';

interface ISettingProps {
	dispatch: Dispatch<any>;
	settingsData: any;
	usersData: any;
	seeAlert: any
}
const Settings: React.SFC<ISettingProps> = ({
	dispatch, settingsData, usersData, seeAlert }) => {
	const initialSettings = {
		deleted: false,
		id: 1,
		preference: "",
		value: "",
	}

	const format = 'HH:mm';
	const now = moment();

	const [dailyReportTime, setDailyReportTime] = useState(null);
	const [dailyAlertTime, setDailyAlertTime] = useState(null);
	const [dailyAlertToggle, setDailyAlertToggle] = useState(false);
	const [dailyReportToggle, setDailyReportToggle] = useState(false);
	const [clientPreferences, setClientPreferences] = useState(null);
	const [userData, setUserData] = useState(null);
	const [settingsDataLoaded, setSettingsDataLoaded] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [isSuccessAlert, SetIsSuccessAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	React.useEffect(() => {
		var usrData = getUserData();
		setUserData(usrData);
		dispatch(getSettings({ userId: usrData.id, userToken: usrData.userToken }));
	}, []);
	React.useEffect(() => {
		setClientPreferences(settingsData?.settingsClientPreferences);
	}, [settingsData?.settingsClientPreferences]);

	const saveSettings = (name, value, clients) => {
		setIsSaved(false);
		setShowAlert(false);
		dispatch(updateSettings({
			userId: userData.id,
			userToken: userData.userToken,
			preference: name,
			value: value,
			clientPreferences: clients,
		}));
	}

	const handleDATime = (value) => {
		setDailyAlertTime(value);
	}

	const handleDRTime = (value) => {
		setDailyReportTime(value);
	}

	const handleToggle = (value, name) => {
		if (name === 'DA') {
			setDailyAlertToggle(value);
			saveSettings("DailyAlerts", value ? "True" : "False", clientPreferences);
		}
		if (name === 'DR') {
			setDailyReportToggle(value);
			saveSettings("DailyReports", value ? "True" : "False", clientPreferences);
		}
	}

	const handleClientPreferenceChange = (event, index) => {
		let updateClientPreferences = [...clientPreferences];
		updateClientPreferences[index].testScaleId = event.value;
		setClientPreferences(updateClientPreferences);
		saveSettings("", "False", updateClientPreferences);
	}

	if (settingsData.isSaved && !isSaved) {
		if (settingsData?.status?.statusValue) {
			setAlertMessage("Saved successfully.");
			dispatch(getSettings({ userId: userData.id, userToken: userData.userToken }));
		}
		else {
			setAlertMessage("Error occured.");
		}

		SetIsSuccessAlert(settingsData.status.statusValue);
		setShowAlert(true);
		setIsSaved(true);
	}

	if (settingsData.settings && !settingsDataLoaded && settingsData.settings.length > 0) {
		if (settingsData.settings[0] && settingsData.settings[0].preference == "DailyAlerts" && settingsData.settings[0].value == "True") {
			setDailyAlertToggle(true);
		}
		else {
			setDailyAlertToggle(false);
		}
		if (settingsData.settings[1] && settingsData.settings[1].preference == "DailyReports" && settingsData.settings[1].value == "True") {
			setDailyReportToggle(true);
		}
		else {
			setDailyReportToggle(false);
		}
		if (settingsData.settings[2] && settingsData.settings[2].preference == "DailyAlertTime") {
			var alertTime = settingsData.settings[2].value;
			setDailyAlertTime(moment(alertTime, 'HH:mm'));
		}
		if (settingsData.settings[3] && settingsData.settings[3].preference == "DailyReportTime") {
			var alertTime = settingsData.settings[3].value;
			setDailyReportTime(moment(alertTime, 'HH:mm'));
		}
		setSettingsDataLoaded(true);
	}

	const onCloseTimer = (value, name) => {
		saveSettings(name, moment(value).format('HH:mm').toString(), clientPreferences);
	}

	const closeDialog = () => {
		setShowAlert(false);
	}

	const Styles: StylesConfig = {
		control: (styles, state) => ({
			...styles, backgroundColor: 'white', borderRadius: '1rem',
			boxShadow: '0 !important',
			border: '2px solid lightgray',
			'&:hover': { borderColor: 'lightgray' }
		}),
	};

	const StylesDiv = {
		borderRadius: '5px',
		width: "80px",
		height: "40px",
		color: "#ffffff",
		verticalAlign: "text-top",
		padding: "2px"
	};

	const colors = ["#6d468e", "#4095bd", "#3abde6", "#41c7dc", "#6fc79f", "#95bc6d", "#c0c940", "#e3c940",];
	const clientPreferenceOptions = [
		{ label: 'Pass', value: 1 },
		{ label: 'Warning', value: 2 },
		{ label: 'Fail', value: 3 }
	];

	const getClientPreferenceLabel = (value) => {
		const result = clientPreferenceOptions.filter((clientPreference) => {
			return clientPreference.value === value;
		});

		if (result.length > 0)
			return result[0].label;
		return "";
	};
	return (
		<React.Fragment>
			{showAlert && <FCMessageDialog isSuccess={isSuccessAlert} message={alertMessage} modalCallBack={() => closeDialog()} />}
			<div className="py-3 ">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
					<h1 className="text-2xl text-gray-500 text-center">Settings</h1>
				</div>
				<div className="flex justify-center pt-30">
					<div className="text-gray-500">
						<div className="mt-10 p-10">
							<div className="grid grid-cols-2 mt-10">
								<div className="text-gray-400">
									<span className="">Daily Alerts</span>
									<div className="mt-1">
										<ToggleSwitch checked={dailyAlertToggle} name="dailyAlert" onChange={(value) => handleToggle(value, 'DA')} />
									</div>
									<div>
										<span className="block mt-10 mb-1">Daily Reports</span>
										<ToggleSwitch checked={dailyReportToggle} onChange={(value) => handleToggle(value, 'DR')} name="dailyReport" />
									</div>
								</div>
								<div className="text-gray-400 ml-10">
									<span>Daily Alert Time </span>
									<div className="mb-10">
										<TimePicker
											key="datime"
											name='dailyAlertTime'
											id='dailyAlertTime'
											showSecond={false}
											defaultValue={now}
											onChange={handleDATime}
											onClose={(value) => onCloseTimer(dailyAlertTime, "DailyAlertTime")}
											value={dailyAlertTime}
											className="w-1/2 mt-1 text-fclabel"
											format={format}
											use12Hours={false}
										/>
									</div>
									<span>Daily Report Time</span>
									<div className="mb-10" key="divDRTime">
										<TimePicker
											key="drtime"
											name='dailyReportTime'
											id='dailyReportTime'
											showSecond={false}
											defaultValue={now}
											onChange={handleDRTime}
											value={dailyReportTime}
											onClose={(value) => onCloseTimer(dailyReportTime, "DailyReportTime")}
											className="w-1/2 rounded-full"
											format={format}
											use12Hours={false}
										/>
									</div>
								</div>
							</div>
							{userData?.roleId == 1 && clientPreferences?.map((clientPreference, index) => {
								return (
									<div key={clientPreference.scaleId} className="grid grid-cols-2 mt-5">
										<div className="text-gray-400 p-2" style={{ backgroundColor: `${colors[index]}`, ...StylesDiv, textAlign: "left" }}>
											{index + 1}.
										</div>
										<div className="text-gray-400 ml-10">
											<Select
												value={{ label: getClientPreferenceLabel(clientPreference.testScaleId), value: clientPreference.testScaleId }}
												onChange={(event) => handleClientPreferenceChange(event, index)}
												name="scale"
												options={clientPreferenceOptions}
												styles={{ ...Styles }}
												className="basic-multi-select text-fcinputtext"
												classNamePrefix="select"
												isDisabled={index === 0 || index === 1}
											/>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

const mapStateToProps = (state: any) => {
	const { settingsData, usersData } = state;
	return {
		settingsData,
		usersData
	};
};

export default connect(mapStateToProps)(Settings);