import * as React from 'react';
import { connect } from 'react-redux';
import ForgotPwd from "../../account/forgotpwd";

class ForgotPwdComponent extends React.Component {
  state = {
    isForgetPasswordPage: false,
    isForgetPasswordResendPage: false
  }
  constructor(props: any) {
    super(props);

    this.state = {
      isForgetPasswordPage: false,
      isForgetPasswordResendPage: false
    }
  }

  componentDidMount() {
  }

  render() {
    const {  } = this.state;
    return (
      <div className="">
        <ForgotPwd />
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { login } = state;
  return {
    login
  };
}

export default connect(mapStateToProps)(ForgotPwdComponent);;