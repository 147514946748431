import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  UserUserSvgIcon,
  UserLockSvgIcon,
  CheveronCircleRightSvgIcon,
  ClientsSvgIconLogin,
  UserEnvelopSvgIcon,
} from '../../utils/common/icon-hooks.js';

const FCProfileInputField = ({ value, placeholder, onChange, id, type, icon, isError, isReadOnly, className, required }) => {

  const handleChange = (event) => {
    onChange(event);
  };
  return (
    <>
      <div>
				<div className={"relative p-3 mb-5 border-b-3 border-gray-300 samplePlan"
          + (isError ? " border-b-2 border-red-300 " : " border-b-2 border-white ")
          + (className !== '' ? className : " ")}>
          {icon !== '' && <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            {(icon == "lock") && <ClientsSvgIconLogin />}
            {(icon == "user") && <UserUserSvgIcon />}
            {(icon == "pwd") && <UserLockSvgIcon />}
            {(icon == "email") && <UserEnvelopSvgIcon />}
          </div>}
          <input
            required={required}
            type={type}
            name={id}
            id={id}
						className={"block blueText w-full pl-8  outline-none " + (className !== '' ? className : " ")}
            placeholder={placeholder}
            onChange={handleChange}
            value={value}
            readOnly={isReadOnly}
          />
        </div>
      </div>
    </>
  )
};

FCProfileInputField.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.any,
  isError: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool
};

FCProfileInputField.defaultProps = {
  value: '',
  placeholder: 'text',
  type: 'text',
  required: false,
  isReadOnly: false
}

export default FCProfileInputField;