import { put, call } from "redux-saga/effects";
import { UsersApi } from "../../utils/api/userApi";

import {
  GET_USERS_COMPLETED,
  GET_USERS_FAILED,
  POST_SINGLE_USER_DELETE_COMPLETED,
  POST_SINGLE_USER_DELETE_FAILED,
  POST_ADD_USER_COMPLETED,
  POST_ADD_USER_FAILED,
  GET_SINGLE_USER_COMPLETED,
  GET_SINGLE_USER_FAILED,
  POST_UPDATE_USER_AVATAR_COMPLETED,
  POST_UPDATE_USER_AVATAR_FAILED,
  GET_USERS_ROLES_COMPLETED,
  GET_USERS_ROLES_FAILED
} from '../actions/user';

export function* getUsers(request: any) {
  try {
    const result = yield call(UsersApi.getAllUsers, request.input);
    yield put({
      type: GET_USERS_COMPLETED,
      payload: result.data,
      input: request.input
    });
  } catch (e) {
    yield put({ type: GET_USERS_FAILED, payload: e.message });
  }
}

export function* deleteUser(request: any) {
  try {
    const result = yield call(UsersApi.deleteUser, request.input);
    yield put({
      type: POST_SINGLE_USER_DELETE_COMPLETED,
      payload: result.data,
      input: request.input
    });
  } catch (e) {
    yield put({ type: POST_SINGLE_USER_DELETE_FAILED, payload: e.message });
  }
}

export function* getSingleUser(request: any) {
  try {
		const result = yield call(UsersApi.getSingleUserWithImg, request.input);
    console.log(result.data);
    yield put({
      type: GET_SINGLE_USER_COMPLETED,
      payload: result.data,
      input: request.input
    });
  } catch (e) {
    yield put({ type: GET_SINGLE_USER_FAILED, payload: e.message });
  }
}

export function* getSingleUserWithImg(request: any) {
  try {
    const result = yield call(UsersApi.getSingleUserWithImg, request.input);
    yield put({
      type: GET_SINGLE_USER_COMPLETED,
      payload: result.data,
      input: request.input
    });
  } catch (e) {
    yield put({ type: GET_SINGLE_USER_FAILED, payload: e.message });
  }
}

export function* addorUpdateUser(request: any) {
  try {
    const result = yield call(UsersApi.addOrUpdateUser, request.input);
    console.log(result.data);
    yield put({
      type: POST_ADD_USER_COMPLETED,
      payload: result.data,
      input: request.input
    });
  } catch (e) {
    yield put({ type: POST_ADD_USER_FAILED, payload: e.message });
  }
}

export function* updateUserAvatar(request: any) {
  try {
    const result = yield call(UsersApi.updateUserAvatar, request.input);
    yield put({
      type: POST_UPDATE_USER_AVATAR_COMPLETED,
      payload: result.data
    });
  } catch (e) {
    yield put({ type: POST_UPDATE_USER_AVATAR_FAILED, payload: e.message });
  }
}

export function* getUserRoles(request: any) {
  try {
    const result = yield call(UsersApi.getUserRoles, request.input);
    yield put({
      type: GET_USERS_ROLES_COMPLETED,
      payload: result.data,
      input: request.input
    });
  } catch (e) {
    yield put({ type: GET_USERS_ROLES_FAILED, payload: e.message });
  }
}