export const GETCORRECTIVEACTION_STARTED = "GETCORRECTIVEACTION_STARTED";
export const GETCORRECTIVEACTION_COMPLETED = "GETCORRECTIVEACTION_COMPLETED";
export const GETCORRECTIVEACTION_FAILED = "GETCORRECTIVEACTION_FAILED";
  
export const ADDCORRECTIVEACTION_STARTED = "ADDCORRECTIVEACTION_STARTED";
export const ADDCORRECTIVEACTION_COMPLETED = "ADDCORRECTIVEACTION_COMPLETED";
export const ADDCORRECTIVEACTION_FAILED = "ADDCORRECTIVEACTION_FAILED";

export const DELETECORRECTIVEACTION_STARTED = "DELETECORRECTIVEACTION_STARTED";
export const DELETECORRECTIVEACTION_COMPLETED = "DELETECORRECTIVEACTION_COMPLETED";
export const DELETECORRECTIVEACTION_FAILED = "DELETECORRECTIVEACTION_FAILED";

export const getCorretiveActions = (input: any) => {
    return {
      type: GETCORRECTIVEACTION_STARTED,
        input: input
    };
};

export const addCorretiveAction = (input: any) => {
  return {
    type: ADDCORRECTIVEACTION_STARTED,
    input: input
  };
};

export const deleteCorretiveAction = (input: any) => {
  return {
    type: DELETECORRECTIVEACTION_STARTED,
    input: input
  };
};