import { Http } from "../Http";
import { AxiosPromise } from "axios";
import authHeader from '../common/functions'
export class SampleplanQuoteApi {

  public static getTemplates(input: any) {
    return Http.axios().get('/SamplePlan/GetTemplates?userId=' + input.userId + "&userToken=" + input.userToken)
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

	public static getTemplateList() {
		return Http.axios().get('/SamplePlan/GetTemplateList')
			.catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

	public static getTemplateById(templateId: any) {
		return Http.axios().get('/SamplePlan/GetTemplateById?templateId=' + templateId)
			.catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

  public static getTemplate(input: any) {
    return Http.axios().get('/SamplePlan/GetTemplate?userId='
      + input.userId
      + "&userToken=" + input.userToken
      + "&templateId=" + input.templateId)
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static saveSamplePlanQuote(input: any) {
    var obj = JSON.stringify(input);
    return Http.axios().post('/SamplePlan/SaveSamplePlan', obj, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        return response;
      }).catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }
}