import {
  GETTEMPLATES_STARTED,
  GETTEMPLATES_COMPLETED,
  GETTEMPLATES_FAILED,
  GETTEMPLATE_STARTED,
  GETTEMPLATE_COMPLETED,
	GETTEMPLATE_FAILED,
	GETTEMPLATELIST_STARTED,
	GETTEMPLATELIST_COMPLETED,
	GETTEMPLATELIST_FAILED,
	GETTEMPLATEBYID_STARTED,
	GETTEMPLATEBYID_COMPLETED,
	GETTEMPLATEBYID_FAILED,
  SAVESAMPLEPLANQUOTE_STARTED,
  SAVESAMPLEPLANQUOTE_COMPLETED,
  SAVESAMPLEPLANQUOTE_FAILED
} from '../actions/samplePlanQuote';

const initialState= {
  userId: 0,
  usertoken: "",
  isFormSubmit: false,
  isSaved: false,
  isLoading: false,
  isError: false,
  isTemplatesLoaded: false,
  isTemplateLoaded: false,
  isPlanSaved: false,
  templateinput: {
    userId: -1,
    userToken: "",
    templateId: -1
  },
  sampleplaninput: {
    userId: 1,
    userToken: "1",
    id: -1,
    name: "",
    frequency: -1,
    testpointIds: "",
    siteIds: "",
    dayOfWeek: "-1", //(frequency <= 3 ? selectedWeekDays.toString() : ""),
    dayOfMonth: -1 //(frequency === 4 ? selectedDay : 0),
  },
  templatesResult: [{
    id: -1,
    name: ""
  }],
  templateResult: [{
    id: -1,
    name: "",
    frequency: "",
    dayOfWeek: "",
    dayOfMonth: "",
    testPoints: [{
        id: -1,
        name: "",
        location: "",
        where: ""
      }]
  }]
};

const sampleplanQuoteData = (state = initialState, action: any) => {
  switch (action.type) {
    case GETTEMPLATES_STARTED:
      return {
        ...state,
        isLoading: true,
        isFormSubmit: true,
				templateinput: action.input,
				isTemplatesLoaded: false

      };
    case GETTEMPLATES_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        templatesResult: action.payload.result,
        isSuccess: true,
        isFormSubmit: true,
        isTemplatesLoaded: true
      };
    case GETTEMPLATES_FAILED:
      return {
        ...state,
        error: action.payload,
				isError: true,
				isTemplatesLoaded: false
			};
		case GETTEMPLATELIST_STARTED:
			return {
				...state,
				isLoading: true,
				isFormSubmit: true,
				isTemplatesLoaded: false
			};
		case GETTEMPLATELIST_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				templatesResult: action.payload.result,
				isSuccess: true,
				isFormSubmit: true,
				isTemplatesLoaded: true
			};
		case GETTEMPLATELIST_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true,
				isTemplatesLoaded: false
			};
    case GETTEMPLATE_STARTED:
      return {
        ...state,
        isLoading: true,
        isFormSubmit: true,
				templateinput: action.input,
				isTemplateLoaded: false
      };
    case GETTEMPLATE_COMPLETED:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        templateResult: action.payload.result,
        isSuccess: true,
        isFormSubmit: true,
        isTemplateLoaded: true
      };
    case GETTEMPLATE_FAILED:
      return {
        ...state,
        error: action.payload,
				isError: true,
				isTemplateLoaded: false
      };
		case GETTEMPLATEBYID_STARTED:
			return {
				...state,
				isLoading: true,
				isFormSubmit: true,
				templateinput: action.input,
				isTemplateLoaded: false
			};
		case GETTEMPLATEBYID_COMPLETED:
			return {
				...state,
				isLoading: false,
				status: action.payload.status,
				templateResult: action.payload.result,
				isSuccess: true,
				isFormSubmit: true,
				isTemplateLoaded: true
			};
		case GETTEMPLATEBYID_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true,
				isTemplateLoaded: false
			};
    case SAVESAMPLEPLANQUOTE_STARTED:
      return {
        ...state,
        isLoading: true,
        isFormSubmit: true,
				sampleplaninput: action.input,
				isPlanSaved: false
      };
    case SAVESAMPLEPLANQUOTE_COMPLETED:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        isSuccess: true,
        isFormSubmit: true,
        isPlanSaved: true
      };
    case SAVESAMPLEPLANQUOTE_FAILED:
      return {
        ...state,
        error: action.payload,
				isError: true,
				isPlanSaved: false
      };
    default:
      return state;
  }
};

export default sampleplanQuoteData;
