import { Http } from "../Http";
import { AxiosPromise } from "axios";

export class UserProfileApi {

  public static getUserProfile(input: any) {
		return Http.axios().get('/Profile/GetUserProfileWithAvator?userId='
      + input.userId + "&userToken=" + input.userToken + "&id=" + input.userId)
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static updateUser(input: any) {
    var obj = JSON.stringify(input);
    return Http.axios().post('/Profile/UpdateUserProfile?', obj, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        return response;
      }).catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static updateAvatar(input: any) {
    var obj = JSON.stringify(input);
    return Http.axios().post('/Profile/UpdateAvatar', obj, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        return response;
      }).catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static changePassword(input: any) {
    var obj = JSON.stringify(input);
    return Http.axios().post('/Profile/changePassword?', obj, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        return response;
      }).catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }
}
