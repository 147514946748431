import { put, call } from "redux-saga/effects";
import {
  GETTEMPLATES_COMPLETED,
  GETTEMPLATES_FAILED,
  GETTEMPLATE_COMPLETED,
	GETTEMPLATE_FAILED,
	GETTEMPLATELIST_COMPLETED,
	GETTEMPLATELIST_FAILED,
	GETTEMPLATEBYID_COMPLETED,
	GETTEMPLATEBYID_FAILED,
  SAVESAMPLEPLANQUOTE_COMPLETED,
  SAVESAMPLEPLANQUOTE_FAILED} from "../actions/samplePlanQuote";
import { SampleplanQuoteApi } from "../../utils/api/sampleplanQuoteApi";

export function* getTemplates(request: any) {
  try {
		const result = yield call(SampleplanQuoteApi.getTemplates, request.input);
    console.log(result.data);
    yield put({
      type: GETTEMPLATES_COMPLETED,
      payload: result.data,
      input: request.input
    });
  } catch (e) {
    yield put({ type: GETTEMPLATES_FAILED, payload: e.message });
  }
}

export function* getTemplateList() {
	try {
		const result = yield call(SampleplanQuoteApi.getTemplateList);
		console.log(result.data);
		yield put({
			type: GETTEMPLATELIST_COMPLETED,
			payload: result.data
		});
	} catch (e) {
		yield put({ type: GETTEMPLATELIST_FAILED, payload: e.message });
	}
}

export function* getTemplate(request: any) {
  try {
		const result = yield call(SampleplanQuoteApi.getTemplate, request.input);
    console.log(result.data);
    yield put({
      type: GETTEMPLATE_COMPLETED,
      payload: result.data,
      input: request.input
    });
  } catch (e) {
    yield put({ type: GETTEMPLATE_FAILED, payload: e.message });
  }
}

export function* getTemplateById(request: any) {
	try {
		const result = yield call(SampleplanQuoteApi.getTemplateById, request.input);
		console.log(result.data);
		yield put({
			type: GETTEMPLATEBYID_COMPLETED,
			payload: result.data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: GETTEMPLATEBYID_FAILED, payload: e.message });
	}
}
export function* saveSamplePlanQuote(request: any) {
  try {
		const result = yield call(SampleplanQuoteApi.saveSamplePlanQuote, request.input);
    yield put({
      type: SAVESAMPLEPLANQUOTE_COMPLETED,
      payload: result.data
    });
  } catch (e) {
    yield put({ type: SAVESAMPLEPLANQUOTE_FAILED, payload: e.message });
  }
}
