import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useState, useRef } from 'react';
import Select, { StylesConfig } from 'react-select';
import moment from 'moment';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { usePopper } from "react-popper";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "../../custom.css";
import ToggleSwitch from '../../utils/library/toggleSwitch';
import FCSelectSiteField from '../../utils/library/selectsitefield';
import { getUserData, covertBase64ToFile } from '../../utils/common/functions';
import { getSitesByClient } from '../../store/actions/site';
import { getUsers } from '../../store/actions/user';
import { getDashboardData, getAllReport, getDownloadData, getDownloadReport } from '../../store/actions/dashboard';
import { getAllSamplePlans } from '../../store/actions/samplePlan';
import {
	PlusCircleSvgIconTransparent, DotVerticalSvgSvgIcon
} from '../../utils/common/icon-hooks.js';
import FCMessageDialog from '../../utils/library/dialog';

interface IDashboard {
	dispatch: Dispatch<any>;
	dashboardData: any;
	siteData: any;
	usersData: any;
	sampleplanData: any;
}

const Landing: React.SFC<IDashboard> = ({
	dispatch, dashboardData, siteData, usersData, sampleplanData }) => {

	const [showAlert, setShowAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [isSuccessAlert, setIsSuccessAlert] = useState(false);
	const [popVisible, setPopVisibility] = useState(false);
	const [referenceRef, setReferenceRef] = useState(null);
	const [popperRef, setPopperRef] = useState(null);
	const { styles, attributes } = usePopper(referenceRef, popperRef, {
		placement: "bottom-end",
		modifiers: [
			{
				name: "offset",
				enabled: true,
				options: {
					offset: [0, 10]
				}
			}
		]
	});
	const frequencyOptions = [
		{ label: 'Today', value: 'Today' },
		{ label: 'This Week', value: 'This Week' },
		{ label: 'This Month', value: 'This Month' }];
	const Styles: StylesConfig = {
		option: provided => ({
			...provided,
			color: '#002366'
		}),
		control: (provided, state) => ({
			...provided,
			boxShadow: "none",
			border: "none",
			backgroundColor: "none"
		}),
		singleValue: (provided) => ({
			...provided,
			color: '#002366'
		})
	};

	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;

	const [userData, setUserData] = useState(null);
	const [isSitesLoaded, setIsSitesLoaded] = useState(false);
	const [siteOptions, setSiteOptions] = useState([]);
	const [selectedSiteId, setSelectedSiteId] = useState(0);
	const [selectedSite, setSelectedSite] = useState({ label: '', value: 0 });

	const [allUsers, setAllUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState({ label: '', value: 0 });
	const [selectedUserId, setSelectedUserId] = useState(0);
	const [userOptions, setUserOptions] = useState([]);
	const [isAllUsersLoaded, setIsAllUsersLoaded] = useState(false);

	const [selectedSamplePlanId, setSelectedSamplePlanId] = useState(0);
	const [isSamplePlansLoaded, setIsSamplePlansLoaded] = useState(false);
	const [samplePlanOptions, setSamplePlanOptions] = useState([]);
	const [selectedSamplePlan, setSelectedSamplePlan] = useState({ label: '', value: 0 });

	const [isDashboardDataLoaded, setIsDashboardDataLoaded] = useState(false);
	const [pieOptions, setPieOptions] = useState(null);
	const [barOptions, setBarOptions] = useState(null);
	const [barSingleOptions, setBarSingleOptions] = useState(null);
	const [graphData, setGraphData] = useState({});
	const [showedInitGraph, setShowedInitGraph] = useState(false);
	const [loadedInitGraphData, setLoadedInitGraphData] = useState(false);
	const [failedTestPoints, setFailedTestPoints] = useState([]);
	const [completedTestPoints, setCompletedTestPoints] = useState([]);
	const [failRate, setFailRate] = useState(0);
	const [successRate, setSuccessRate] = useState(0);
	const [totalFail, setTotalFail] = useState(0);
	const [totalRetests, settTotalRetests] = useState(0);
	const [frequency, setFrequency] = useState({ label: 'Today', value: 'Today' });
	const [orderByTestToggle, setOrderByTestToggle] = useState(true);
	const pieChartComponentRef = useRef<HighchartsReact.RefObject>(null);
	const barChartComponentRef = useRef<HighchartsReact.RefObject>(null);
	const barChartSingleComponentRef = useRef<HighchartsReact.RefObject>(null);

	const [isDownloadReportLoaded, setIsDownloadReportLoaded] = useState(false);
	const [isDownloadDataLoaded, setIsDownloadDataLoaded] = useState(false);

	const refreshGraph = (data, refreshPie, refreshBar, isSort) => {
		if (refreshPie) {
			//Pie Chart
			setPieOptions({
				title: {
					text: ''
				},
				dataLabels: {
					enabled: true
				},
				plotOptions: {
					pie: {
						dataLabels: {
							distance: '-40%'
						}
					}
				},
				colors: ['#26a4ca', '#ffcc00', '#f70000'],
				series: [{
					type: 'pie',
					style: {
						fontFamily: 'DINNextLTPro-Bold',
						color: '#646464',
						fill: 'none'
					},
					dataLabels: {
						enabled: true,
						align: 'center',
						color: "black",
						style: {
							textOutline: false
						},
						formatter: function () {
							return this.y + " (" + Highcharts.numberFormat(this.percentage, 0) + "%)";
						}
					},
					data: [{ name: 'Pass', y: data?.totalPass }, { name: 'Warning', y: data?.totalWarning }, { name: 'Fail', y: data?.totalFail }]
				}]
			});

			if (data?.failedTestPoints) {
				setFailedTestPoints(data?.failedTestPoints);
			}

			setFailRate(data?.failRate);
			setSuccessRate(data?.retestSuccessRate);
			setTotalFail(data?.totalFail);
			settTotalRetests(data?.totalRetests);
			setCompletedTestPoints(data?.completedTests);
			refreshCompletedTestGraph(data?.completedTests, frequency.value);
		}

		if (refreshBar) {
			//Bar chart
			var nameData = [];
			var passData = [];
			var warningData = [];
			var failData = [];
			var seriousData = [];

			if (data?.testPointReport) {
				var result = data?.testPointReport
					.sort((a, b) => {
						if (isSort) {
							return a.id > b.id ? 1 : -1;
						}
						else {
							return a.totalFail < b.totalFail ? 1 : -1;
						}
					});
				data?.testPointReport
					.map((item) => {
						passData.push(item.totalPass);
						warningData.push(item.totalWarning);
						failData.push(item.totalFail);
						nameData.push(item.name);
					});
			}
			seriousData.push({ name: 'Fail', data: failData });
			seriousData.push({ name: 'Warning', data: warningData });
			seriousData.push({ name: 'Pass', data: passData });

			setBarOptions({
				chart: {
					renderTo: 'container',
					style: {
						fontFamily: 'DINNextLTPro-Bold',
						color: '#646464',
						fill: 'none'
					},
					marginLeft: 100,
					type: 'bar',
					scrollablePlotArea: {
						minHeight: nameData.length * 40
					},
					events: {
						afterSetExtremes: function () {
							var xAxis = this,
								numberOfPoints = xAxis.series[0].points.length - 1,
								minRangeValue = xAxis.getExtremes().min,
								maxRangeValue = xAxis.getExtremes().max;

							if (minRangeValue < 0) {
								xAxis.setExtremes(null, xAxis.options.max);
							} else if (maxRangeValue > numberOfPoints) {
								xAxis.setExtremes(numberOfPoints - xAxis.options.max, numberOfPoints);
							}
						}
					}
				},
				scrollbar: {
					minWidth: 6,
					height: 4
				},
				title: {
					text: ''
				},
				colors: ['#f70000', '#ffcc00', '#26a4ca'],
				xAxis: {
					lineWidth: 0,
					minorGridLineWidth: 0,
					lineColor: 'transparent',
					type: 'category',
					categories: nameData,
					labels: {
						style: {
							fontWeight: 'bold',
							color: 'gray',
							fill: 'none'
						}
					}
				},
				plotOptions: {
					series: {
						stacking: 'percent',
						dataSorting: {
							enabled: true,
							sortKey: 'custom.id'
						},
						pointWidth: 25,
						shadow: false,
						dataLabels: {
							enabled: true,
							align: 'center',
							color: "black",
							style: {
								textOutline: false
							},
							formatter: function () {
								return this.y + " (" + Highcharts.numberFormat(this.percentage, 0) + "%)";
							}
						}
					}
				},
				legend: {
					enabled: false
				},
				yAxis: {
					labels: {
						enabled: false
					},
					opposite: true,
					title: false,
					gridLineColor: 'transparent'
				},
				series: seriousData
			});
		}
	}

	const refreshCompletedTestGraph = (data, period) => {
		//Bar chart
		var nameData = [];
		var completedData = [];
		var remainingData = [];
		var seriousData = [];

		if (completedTestPoints) {
			var selectedItem = data?.find((item) => { return item.period === period });
			if (selectedItem) {
				completedData.push(selectedItem.completed);
				remainingData.push(selectedItem.testToComplete - selectedItem.completed);
				nameData.push("Completed Test");
			}
		}

		seriousData.push({ name: 'Requires Testing', data: remainingData });
		seriousData.push({ name: 'Completed', data: completedData });

		setBarSingleOptions({
			chart: {
				style: {
					fontFamily: 'DINNextLTPro-Bold',
					color: '#646464',
					fill: 'none'
				},
				type: 'bar',
				backgroundColor: 'transparent',
				borderWidth: 0,
				plotBackgroundColor: 'transparent',
				plotShadow: false,
				plotBorderWidth: 0,
				margin: 0,
				padding: 0,
				spacing: [0, 0, 0, 0]
			},
			legend: {
				reversed: true
			},
			credits: { enabled: false },
			title: {
				text: 'Completed Tests'
			},
			subtitle: {
				text: ''
			},
			colors: ['#d1d5db', '#26a4ca'],
			xAxis: {
				lineWidth: 0,
				minorGridLineWidth: 0,
				lineColor: 'transparent',
				type: 'category',
				categories: nameData,
				labels: {
					enabled: false
				},
				minPadding: 0,
				maxPadding: 0
			},
			plotOptions: {
				series: {
					groupPadding: 0,
					stacking: 'percent',
					pointWidth: 25,
					shadow: false,
					dataLabels: {
						enabled: true,
						align: 'center',
						color: "black",
						style: {
							textOutline: false
						},
						formatter: function () {
							return this.y + " (" + Highcharts.numberFormat(this.percentage, 0) + "%)";
						}
					}
				}
			},
			yAxis: {
				gridLineColor: 'transparent',
				gridLineWidth: 0,
				lineColor: 'transparent',
				lineWidth: 0,
				labels: {
					enabled: false
				},
				title: {
					enabled: false
				},
				tickWidth: 0
			},
			series: seriousData
		});
	}

	React.useEffect(() => {
		var usrData = getUserData();
		setUserData(usrData);

		setDateRange([new Date(`${moment().subtract(8, 'days').format('LL')} 00:00:00`), new Date(`${moment().subtract(1, 'days').format('LL')} 23:59:59`)]);
		//setFrequency({ label: 'Today', value: 'Today' });
		if (usrData.roleId === 1) //Admin
		{
			//GetSitesByClient
			dispatch(getSitesByClient({ userId: usrData.id, userToken: usrData.userToken, clientId: usrData.clientId }));
			//Get all users
			dispatch(getUsers({ userId: usrData.id, userToken: usrData.userToken }));
			//Get all site points
			dispatch(getAllSamplePlans({ userId: usrData.id, userToken: usrData.userToken }));
		}
		else {
			//Get signle site : TODO:..
			//dispatch(getSitesByClient({ userId: userData.id, userToken: userData.userToken, clientId: usrData.clientId }));
		}
	}, []);

	const getGraphData = (data) => {
		setIsDashboardDataLoaded(false);
		dispatch(getDashboardData(data));
	}

	//UserSata
	if (usersData && usersData.status) {
		if (usersData.isUsersLoaded && !isAllUsersLoaded) {
			if (usersData.usersResult && usersData.usersResult.length > 0) {
				setAllUsers(usersData.usersResult);
				setIsAllUsersLoaded(true);
				var uOptions = [{
					label: 'All', value: 0
				}];
				usersData.usersResult.forEach(item => { uOptions.push({ label: item.userName, value: item.id }) });
				setUserOptions(uOptions);
				if (uOptions.length > 0) {
					setSelectedUser(uOptions[0]);
					setSelectedUserId(uOptions[0].value);
				}
			}
		}
	}

	//Site Data
	if (siteData && siteData.sites) {
		if (siteData.isSitesLoaded && !isSitesLoaded) {
			var options = [];
			siteData.sites.forEach(item => { options.push({ label: item.name, value: item.id }) });
			setSiteOptions(options);
			if (options.length > 0) {
				setSelectedSite(options[0]);
				if (selectedSiteId === 0) {
					setSelectedSiteId(options[0].value);
				}
			}
			setIsSitesLoaded(true);
			siteData.isSitesLoaded = false;
		}
	}

	//Sample plan data
	if (sampleplanData) {
		if (sampleplanData.isSamplePlansLoaded && !isSamplePlansLoaded) {
			var pOptions = [{
				label: 'All', value: 0
			}];
			sampleplanData.samplePlans.forEach(item => { pOptions.push({ label: item.name, value: item.id }) });
			setSamplePlanOptions(pOptions);
			if (selectedSamplePlanId === 0 && pOptions.length > 0) {
				setSelectedSamplePlanId(pOptions[0].value);
			}
			if (pOptions.length > 0) {
				setSelectedSamplePlan(pOptions[0]);
			}
			setIsSamplePlansLoaded(true);
		}
	}

	//Graph Data
	if (dashboardData) {
		if (dashboardData.isDashboardDataLoaded && !isDashboardDataLoaded) {
			dashboardData.isDashboardDataLoaded = false;
			setIsDashboardDataLoaded(true);
			setGraphData(dashboardData.graphData);
			refreshGraph(dashboardData.graphData, true, true, false);
		}

		if (dashboardData.isDownloadReportLoaded && !isDownloadReportLoaded) {
			dashboardData.isDownloadReportLoaded = false;
			setIsDownloadReportLoaded(true);
			if (dashboardData && dashboardData.downloadReportData && dashboardData.downloadReportData.fileContents) {
				covertBase64ToFile(dashboardData.downloadReportData.fileContents,
					dashboardData.downloadReportData.fileDownloadName,
					dashboardData.downloadReportData.contentType);
			} else {
				setShowAlert(true);
				setAlertMessage("No Report Found");
				setIsSuccessAlert(false);
			}
		}

		if (dashboardData.isDownloadDataLoaded && !isDownloadDataLoaded) {
			dashboardData.isDownloadDataLoaded = false;
			setIsDownloadDataLoaded(true);
			if (dashboardData && dashboardData.downloadData && dashboardData.downloadData.fileContents) {
				covertBase64ToFile(dashboardData.downloadData.fileContents,
					dashboardData.downloadData.fileDownloadName,
					dashboardData.downloadData.contentType)
			} else {
				setShowAlert(true);
				setAlertMessage("No Data Found");
				setIsSuccessAlert(false);
			}
		}
	}

	//To show the graph on afer page load
	if (selectedSiteId > 0 &&
		selectedSamplePlanId > 0 &&
		selectedUserId > 0 &&
		graphData == null && !loadedInitGraphData) {
		setLoadedInitGraphData(true);

		let reqData = {
			siteId: selectedSiteId,
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
			samplePlanId: selectedSamplePlanId,
			userIdFliter: selectedUserId,
			dateFrom: moment(startDate, 'yyyy-mm-dd'),
			dateTo: moment(endDate, 'yyyy-mm-dd')
		}
		getGraphData(reqData);
	}

	if (!showedInitGraph && graphData != null) {
		refreshGraph(graphData, true, true, true);
		setShowedInitGraph(true);
	}

	const onSiteChange = (event) => {
		if (event.value === null) { return; }
		setSelectedSiteId(event.value);
		if (siteOptions) {
			setSelectedSite(siteOptions.find(o => o.value === event.value));
		}

		let reqData = {
			siteId: event.value,
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
			samplePlanId: selectedSamplePlanId,
			userIdFliter: selectedUserId,
			dateFrom: moment(startDate, 'yyyy-mm-dd'),
			dateTo: moment(endDate, 'yyyy-mm-dd')
		}
		getGraphData(reqData);
	}

	const onUserChange = (event) => {
		if (event.value === null) { return; }
		setSelectedUserId(event.value);
		if (userOptions) {
			setSelectedUser(userOptions.find(o => o.value === event.value));
		}

		let reqData = {
			siteId: selectedSiteId,
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
			samplePlanId: selectedSamplePlanId,
			userIdFliter: event.value,
			dateFrom: moment(startDate, 'yyyy-mm-dd'),
			dateTo: moment(endDate, 'yyyy-mm-dd')
		}
		getGraphData(reqData);
	}

	const onPlanChange = (event) => {
		if (event.value === null) { return; }
		setSelectedSamplePlanId(event.value);
		if (samplePlanOptions) {
			setSelectedSamplePlan(samplePlanOptions.find(o => o.value === event.value));
		}

		let reqData = {
			siteId: selectedSiteId,
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
			samplePlanId: event.value,
			userIdFliter: selectedUserId,
			dateFrom: moment(startDate, 'yyyy-mm-dd'),
			dateTo: moment(endDate, 'yyyy-mm-dd')
		}
		getGraphData(reqData);
	}

	const onDateChange = (dates) => {
		setDateRange([dates[0] ? new Date(`${moment(dates[0]).format('LL')} 00:00:00`) : dates[0], dates[1] ? new Date(`${moment(dates[1]).format('LL')} 23:59:59`) : dates[1]]);

		if (dates[0] && dates[1]) {
			let reqData = {
				siteId: selectedSiteId,
				userId: userData.id,
				userToken: userData.userToken,
				clientId: userData.clientId,
				samplePlanId: selectedSamplePlanId,
				userIdFliter: selectedUserId,
				dateFrom: moment(startDate, 'yyyy-mm-dd'),
				dateTo: moment(endDate, 'yyyy-mm-dd')
			}
			getGraphData(reqData);
		}
	}

	const handleCalendarClose = () => {
		let reqData = {
			siteId: selectedSiteId,
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
			samplePlanId: selectedSamplePlanId,
			userIdFliter: selectedUserId,
			dateFrom: moment(startDate, 'yyyy-mm-dd'),
			dateTo: moment(endDate, 'yyyy-mm-dd')
		}
		getGraphData(reqData);
	}

	const handlePopup = (event, rptType) => {
		event.preventDefault();
		setPopVisibility(!popVisible);
		var input = {
			siteId: selectedSiteId,
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
			samplePlanId: selectedSamplePlanId,
			userIdFliter: selectedUserId,
			dateFrom: moment(startDate, 'yyyy-mm-dd'),
			dateTo: moment(endDate, 'yyyy-mm-dd')
		};
		if (rptType === "Report") {
			setIsDownloadReportLoaded(false);
			dispatch(getDownloadReport(input));
		}
		if (rptType === "Data") {
			setIsDownloadDataLoaded(false);
			dispatch(getDownloadData(input));
		}
	}

	const handlePrint = (event) => {
		event.preventDefault();
		setPopVisibility(!popVisible);
		setTimeout(function () {
			window.print();
		}.bind(this), 1000)
	}

	const handleDropdownClick = (event) => {
		setPopVisibility(!popVisible);
	}

	const handleOrderByTestToggle = (event) => {
		setOrderByTestToggle(event);
		refreshGraph(graphData, false, true, event);
	}

	const handleFrequencyChange = (event) => {
		setFrequency({ label: event.value, value: event.value });
		refreshCompletedTestGraph(completedTestPoints, event.value);
	}

	const closeDialog = () => {
		setShowAlert(false);
	}

	return (
		<div className="section-to-print">
			<div className="max-w-7xl mt-3">
				{showAlert && <FCMessageDialog isSuccess={isSuccessAlert} modalCallBack={() => closeDialog()} message={alertMessage} />}
				<div className="w-full flex pb-3 border-b-2 border-gray-300">
					<div className="w-1/2 flex">
						<div className="w-1/2">
							<div className="block pl-1 text-fclabel">
								Select Site
							</div>
							<FCSelectSiteField id="sites" onChange={(event) => onSiteChange(event)}
								options={siteOptions} value={selectedSite} className=" ml-1 " />
						</div>
						<div className="w-1/2">
							<div className="block pl-1 text-fclabel">
								Select Sample Plan
							</div>
							<FCSelectSiteField id="plans" onChange={(event) => onPlanChange(event)}
								options={samplePlanOptions} value={selectedSamplePlan} className=" ml-1 " />
						</div>
					</div>
					<div className="w-1/2">
						<div className="w-full pr-5 text-right">
							<div className="block pl-1 text-fclabel">&nbsp;</div>
							<button ref={setReferenceRef} onClick={handleDropdownClick}>
								<DotVerticalSvgSvgIcon />
							</button>
							<div ref={setPopperRef} style={styles.popper} {...attributes.popper}
								className={(!popVisible ? "hidden" : "w-26 rounded-2xl border-2 border-gray z-10")}>
								<div className="rounded-2xl border-2 border-white" style={{ backgroundColor: "#fff" }}>
									<a href="#" className="block flex flex-inline w-full py-1" onClick={(event) => handlePrint(event)}>
										<PlusCircleSvgIconTransparent /> Print Report
									</a>
									<a href="#" className="block flex flex-inline w-full py-1" onClick={(event) => handlePopup(event, 'Report')}>
										<PlusCircleSvgIconTransparent /> Download Report
									</a>
									<a href="#" className="block flex flex-inline w-full py-1" onClick={(event) => handlePopup(event, 'Data')}>
										<PlusCircleSvgIconTransparent /> Download Data
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="w-full flex pt-1 border-b-2 border-gray-300">
					<div className="w-1/4">
						<div className="block pl-1 text-fclabel">
							Period
						</div>
						<div className="flex">
							<Select
								value={frequency}
								onChange={(event) => handleFrequencyChange(event)}
								name="frequency"
								options={frequencyOptions}
								styles={Styles}
								className="basic-multi-select border-2 rounded-2xl border-gray-300 text-fcinputtext w-full"
								classNamePrefix="select"
							/>
						</div>
					</div>
					<div className="w-3/4">
						<div className="flex w-full pl-2">
							<HighchartsReact
								containerProps={{ style: { height: "80px", width: "90%", padding: "0" } }}
								highcharts={Highcharts}
								options={barSingleOptions}
								ref={barChartSingleComponentRef}
							/>
						</div>
					</div>
				</div>
				<div className="w-full flex pb-3 pt-1">
					<div className="w-3/5 flex">
						<div className="w-5/12">
							<div className="block pl-1 text-fclabel">
								Select User
							</div>
							<FCSelectSiteField id="users" onChange={(event) => onUserChange(event)}
								options={userOptions} value={selectedUser} className=" w-full" />
						</div>
						<div className="w-1/12"></div>
						<div className="w-1/2 px-5">
							<div className="block pl-1 text-fclabel">
								Select Date Range
							</div>
							<DatePicker
								dateFormat={'dd/MM/yyyy'}
								selectsRange={true}
								startDate={startDate}
								endDate={endDate}
								onChange={(value) => onDateChange(value)}
								onCalendarClose={() => handleCalendarClose()}
								className="border-2 border-gray-300 p-2 rounded-2xl blueText w-full"
							/>
						</div>
					</div>
					<div className="w-2/5 flex justify-center">
						<ToggleSwitch checked={orderByTestToggle} name="dailyAlert"
							disableText="Order Fail Rate" enableText="Order by Point"
							disableColor="bg-primary" enableColor="bg-primary"
							onChange={(event) => handleOrderByTestToggle(event)} />
					</div>
				</div>
				<div className="w-full flex">
					<div className="w-3/5 flex">
						<div className="w-1/2">
							<div>
								<div className="block pl-1 text-fclabel text-center">
									Total Test Results
								</div>
								<HighchartsReact
									highcharts={Highcharts}
									options={pieOptions}
									ref={pieChartComponentRef}
									containerProps={{ style: { height: "250px" } }}
								/>
							</div>
							<div>
								<div className="block pl-1 text-fclabel text-center">
									Retest Success Rate
								</div>
								<div className="flex justify-center">
									<span className="block w-1/2 text-center blueText p-1 border-2 border-gray-300 rounded rounded-2xl">
										{totalRetests === 0 ? 'No retests completed' : successRate !== undefined && totalRetests !== undefined ? successRate?.toFixed(0) + '% (' + totalRetests + 'tests)' : ''}
									</span>
								</div>
							</div>
						</div>
						<div className="w-1/2">
							<div>
								<div className="block pl-1 text-fclabel text-center">
									Fail Rate
								</div>
								<div className="flex justify-center">
									<span className="block w-1/2 text-center blueText p-1 border-2 border-gray-300 rounded rounded-2xl">
										{failRate === 0 ? 'No failed tests' : failRate !== undefined && totalFail !== undefined ? failRate?.toFixed(0) + '% (' + totalFail + 'tests)' : ''}
									</span>
								</div>
							</div>
							<div>
								<div className="block pl-1 text-fclabel text-center pt-4">
									Failed Test Points
								</div>
								<div className="border-2 border-gray-300 rounded-2xl h-60 mx-5 p-4">
									<div className="w-full flex">
										<div className="w-2/3 pl-1 text-fclabel">
											Name
										</div>
										<div className="w-1/3 pl-1 text-fclabel">
											Fails
										</div>
									</div>
									<div className="w-full h-44 overflow-y-scroll">
										{failedTestPoints && failedTestPoints.map((item) =>
											<div className="w-full flex blueText">
												<div className="w-2/3 pl-1">
													{item.name}
												</div>
												<div className="w-1/3 pl-1">
													{item.totalFails}
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="w-2/5 border-2 border-gray-300 rounded-2xl">
						<div className="w-full barChart p-2" id="container">
							<HighchartsReact
								containerProps={{ style: { height: "300px" } }}
								highcharts={Highcharts}
								options={barOptions}
								ref={barChartComponentRef}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => {
	const { dashboardData, siteData, usersData, sampleplanData } = state;
	return {
		dashboardData, siteData, usersData, sampleplanData
	};
};

export default connect(mapStateToProps)(Landing);

