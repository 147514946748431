export const GETTEMPLATES_STARTED = "GETTEMPLATES_STARTED";
export const GETTEMPLATES_COMPLETED = "GETTEMPLATES_COMPLETED";
export const GETTEMPLATES_FAILED = "GETTEMPLATES_FAILED";

export const GETTEMPLATELIST_STARTED = "GETTEMPLATELIST_STARTED";
export const GETTEMPLATELIST_COMPLETED = "GETTEMPLATELIST_COMPLETED";
export const GETTEMPLATELIST_FAILED = "GETTEMPLATELIST_FAILED";

export const GETTEMPLATE_STARTED = "GETTEMPLATE_STARTED";
export const GETTEMPLATE_COMPLETED = "GETTEMPLATE_COMPLETED";
export const GETTEMPLATE_FAILED = "GETTEMPLATE_FAILED";

export const GETTEMPLATEBYID_STARTED = "GETTEMPLATEBYID_STARTED";
export const GETTEMPLATEBYID_COMPLETED = "GETTEMPLATEBYID_COMPLETED";
export const GETTEMPLATEBYID_FAILED = "GETTEMPLATEBYID_FAILED";

export const SAVESAMPLEPLANQUOTE_STARTED = "SAVESAMPLEPLANQUOTE_STARTED";
export const SAVESAMPLEPLANQUOTE_COMPLETED = "SAVESAMPLEPLANQUOTE_COMPLETED";
export const SAVESAMPLEPLANQUOTE_FAILED = "SAVESAMPLEPLANQUOTE_FAILED";

export const getTemplates = (input: any) => {
  return {
    type: GETTEMPLATES_STARTED,
    input: input
  };
};

export const getTemplateList = () => {
	return {
		type: GETTEMPLATELIST_STARTED
	};
};

export const getTemplate = (input: any) => {
  return {
    type: GETTEMPLATE_STARTED,
    input: input
  };
};

export const getTemplateById = (input: any) => {
	return {
		type: GETTEMPLATEBYID_STARTED,
		input: input
	};
};

export const saveSamplePlanQuote = (input: any) => {
  return {
    type: SAVESAMPLEPLANQUOTE_STARTED,
    input: input
  };
};