import { Http } from "../Http";
import { AxiosPromise } from "axios";

export class ClientsApi {

  public static getClients(input: any) {
    return Http.axios().get('/Clients/GetClients?userId=' + input.userId + "&userToken=" + input.userToken)
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static deleteClient(input: any) {
    return Http.axios().get('/Clients/DeleteClient?userId=' + input.userId + "&userToken=" + input.userToken + "&deleteId=" + input.id)
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static getSingleClientWithImg(input: any) {
    return Http.axios().get('/Clients/GetClientWithImage?id=' + input.id)
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static addOrUpdateClient(input: any) {
    var obj = JSON.stringify(input);
    
    if (input.id > 0) {
      url = '/Clients/UpdateClient';
      return Http.axios().put(url, obj, { headers: { 'Content-Type': 'application/json' } })
        .then(response => {
          return response;
        }).catch((e) => {
          return e.response;
        }) as AxiosPromise<any>;
    }
    else {
      var url = '/Clients/AddClient';
      return Http.axios().post(url, obj, { headers: { 'Content-Type': 'application/json' } })
        .then(response => {
          return response;
        }).catch((e) => {
          return e.response;
        }) as AxiosPromise<any>;
    }
  }

  public static updateAvatar(input: any) {
    var obj = JSON.stringify(input);
    return Http.axios().post('/Clients/UpdateAvatar', obj, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        return response;
      }).catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }
}