import * as React from 'react';
import PropTypes from 'prop-types';

const siteBoxMinHeight = {
	minHeight: '4rem',
};

const FCSiteBox = ({ onClick, id, text, selected, isCAPage }) => {

	const handleClick = (event) => {
		onClick(event);
	};
	return (
		<a href="#" onClick={handleClick} id={id} key={"sitebox" + id}>
			<div key={"siteboxdiv1" + id} className={"border-2 rounded-2xl mx-1 my-2 shadow-md py-3"
				+ (selected && !isCAPage ? " border-primary  " : " border-gray-200 ")
				+ (isCAPage ? " border-gray-200 " : " ")}
				style={siteBoxMinHeight}>
				<div key={"siteboxdiv2" + id} className={"break-all ml-4 blueText my-1 h-full border-l-4 pl-5 flex flex-row place-items-center "
					+ (selected || isCAPage ? " border-primary rounded-t-sm rounded-b-sm" : " border-fcbutton rounded-t-sm rounded-b-sm")}>
					{text} 
				</div>
			</div>
		</a>
  )
};

FCSiteBox.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.any,
  selected: PropTypes.bool,
  isCAPage: PropTypes.bool
};

FCSiteBox.defaultProps = {
  selected: false
};

export default FCSiteBox;