import * as React from 'react';
import { connect } from 'react-redux';
import CorrectiveAction from "../../correctiveaction/correctiveaction";
import { getUserData } from '../../../utils/common/functions';
import { user } from '../../../utils/models/model';

interface IProps {
	usersData: any;
}

class CorrectiveActionComponent extends React.Component<IProps> {
	state = { isLogout: false, user: user }
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		let usrData = getUserData();

		if (this.props.usersData?.usersResult.length > 0) {
			let usr = this.props.usersData?.usersResult.find((item) => item.id === usrData.id);
			if (usr)
				this.setState({ user: usr });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		let usrData = getUserData();

		if (prevProps.usersData?.usersResult !== this.props.usersData?.usersResult) {
			if (this.props.usersData?.usersResult.length > 0) {
				let usr = this.props.usersData?.usersResult.find((item) => item.id === usrData.id);
				if (usr)
					this.setState({ user: usr });
			}
		}
	}

	render() {
		return (
			<div className="px-20">
				{
					this.state.user?.roleId === 1 && this.state.user?.clientId === 1 ?
						<CorrectiveAction />
						:
						<h2 className="text-center mt-5">You do not have access to view this page</h2>
				}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	const { usersData } = state;
	return {
		usersData
	};
};

export default connect(mapStateToProps)(CorrectiveActionComponent);;