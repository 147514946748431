import { put, call } from "redux-saga/effects";
import { CorrectiveActionApi } from "../../utils/api/CorrectiveActionApi";
import {
  GETCORRECTIVEACTION_COMPLETED, GETCORRECTIVEACTION_FAILED,
  ADDCORRECTIVEACTION_COMPLETED, ADDCORRECTIVEACTION_FAILED,
  DELETECORRECTIVEACTION_COMPLETED, DELETECORRECTIVEACTION_FAILED
} from "../actions/correctiveaction";

export function* getCorrectiveActions(request: any) {
    try {
        const result = yield call(CorrectiveActionApi.getCorrectiveActions, request.input);
        console.log(result.data);
        yield put({
            type: GETCORRECTIVEACTION_COMPLETED,
            payload: result.data
        });
    } catch (e) {
        yield put({ type: GETCORRECTIVEACTION_FAILED, payload: e.message });
    }
}

export function* addCorrectiveAction(request: any) {
  try {
    const result = yield call(CorrectiveActionApi.addCorrectiveAction, request.input);
    console.log(result.data);
    yield put({
      type: ADDCORRECTIVEACTION_COMPLETED,
      payload: result.data
    });
  } catch (e) {
    yield put({ type: ADDCORRECTIVEACTION_FAILED, payload: e.message });
  }
}

export function* deleteCorrectiveAction(request: any) {
  try {
    const result = yield call(CorrectiveActionApi.deleteCorrectiveAction, request.input);
    console.log(result.data);
    yield put({
      type: DELETECORRECTIVEACTION_COMPLETED,
      payload: result.data
    });
  } catch (e) {
    yield put({ type: DELETECORRECTIVEACTION_FAILED, payload: e.message });
  }
}