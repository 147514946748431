import { Http } from "../Http";
import { AxiosPromise } from "axios";
export class CorrectiveActionApi {

  public static getCorrectiveActions(input: any) {
    return Http.axios().get('/CorrectiveAction/GetCorrectiveActions?userId=' + input.userId + "&userToken=" + input.userToken)
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static addCorrectiveAction(input: any) {
    var obj = JSON.stringify(input);
    var url = '/CorrectiveAction/add';
    return Http.axios().post(url, obj, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        return response;
      }).catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static deleteCorrectiveAction(input: any) {
    var obj = JSON.stringify(input);
    var url = '/CorrectiveAction/delete';
    return Http.axios().post(url, obj, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        return response;
      }).catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }
}