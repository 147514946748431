import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';

const FCSiteInputField = ({ value, placeholder, onChange, id, className, isError, labelText, width, required, readOnly, type }) => {

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <>
      <input
        required={required}
        type={type}
        name={id}
        id={id}
        readOnly={readOnly}
          className={"flex-auto -mr-9 appearance-none border-2 rounded-2xl border-gray-300 "
						+ "bg-transparent px-4 py-2 blueText "
						+ " text-base placeholder-gray-500 focus:outline-none samplePlan"
            + (isError ? " border-2 border-red-300 " : " ")
            + (className && className !== '' ? className : "")
            + (width && width !== '' ? ' ' + width + ' ' : ' w-full ') }
        placeholder={placeholder} 
        onChange={(event) => handleChange(event)}
        value={value===null? "": value}
        />
			<label htmlFor="" className={"flex flex-row  text-fclabel " + (width && width !== '' ? width : ' w-full ')}>
        {labelText}
      </label>
    </>
  )
};

FCSiteInputField.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  labelText: PropTypes.string,
  width: PropTypes.string,
  required: PropTypes.bool,
  isError: PropTypes.bool,
  readOnly: PropTypes.bool,
  type: PropTypes.string
};

FCSiteInputField.defaultProps = {
  value: '',
  placeholder: '',
  isError: false,
  required: false,
  readOnly: false
};

export default FCSiteInputField;