import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useState } from 'react';
import { forgetPasswordValidateToken, changeForgetPassword } from '../../store/actions/account';
import FCInputField from '../../utils/library/textbox';
import FCButton from '../../utils/library/button';
import { Validators, validateInput } from '../../utils/library/Validator';

import {
    LockSvgIcon, EnvelopSvgIcon,
    CheveronCircleRightSvgIcon, CheveronCircleLeftSvgIcon
} from '../../utils/common/icon-hooks.js';

interface ILoginProps {
    dispatch: Dispatch<any>;
    authData: any;
    token: any;
    email: any;
}

const ResetPwd: React.SFC<ILoginProps> = ({
    dispatch, authData, token, email }) => {

    const [pwd, setPwd] = useState("");
    const [cnfPwd, setCnfPwd] = useState("");
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [isEqualPassword, setIsEqualPassword] = useState(true);
    const [isResend, setIsResend] = useState(false);
    const [isPwdEntered, setIsPwdEntered] = useState(false);
    const [isCnfPwdEntered, setIsCnfPwdEntered] = useState(false);
    const [isPageValid, setIsPageValid] = useState(false);

    const validators =
        [{
            check: Validators.required,
            message: 'Enter the email id.'
        }];

    const handleChange = (event) => {
        if (event.target.name === 'password') {
            setPwd(event.target.value);
            setIsPwdEntered(validateInput(validators, event.target.value));
        }
        else {
            setCnfPwd(event.target.value);
            setIsCnfPwdEntered(validateInput(validators, event.target.value));
        }
    };

    React.useEffect(() => {
        dispatch(forgetPasswordValidateToken({ email: email, resetToken: token }));
    }, []);

    const handleResetPwd = async (event) => {
        event.preventDefault();
        setIsPwdEntered(validateInput(validators, pwd));
        setIsCnfPwdEntered(validateInput(validators, cnfPwd));

        if ((pwd !== '' && cnfPwd !== '') && (pwd === cnfPwd)) {
            //Password RegEx validation
            if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}/.test(pwd)) {
                //API Call for reset password
                setIsValidPassword(true);
                setIsPageValid(true);
            }
            else {
                setIsValidPassword(false);
            }
            setIsEqualPassword(true);
        }
        else {
            setIsEqualPassword(false);
        }
    };

    if (isPageValid) {
        dispatch(changeForgetPassword({ email: email, resetToken: token, newPassword: pwd }));
        setIsPageValid(false);
    }

    const goToLoginPage = async (event) => {
        window.location.href = "./";
    };

    if (authData.isTokenValidated && authData.status.statusValue) {
        //alert('hi');
    }

    if (!authData.isFormSubmit && !isResend) {
        if (authData.status.statusValue) {
            setIsResend(true);
        }
    }

    return (
        <React.Fragment>
            <div className="min-h-screen flex items-center justify-center bg-primary p-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8 py-6 pl-6 pr-12 bg-white rounded-2xl border-4">
                    <div className="mt-8">
                        <img
                            className="mx-auto h-12 w-auto"
                            src="../../../../assets/logo/logo.png"
                            alt="FreshCheck"
                        />
                    </div>
                    <div className="pb-40">
                        {authData.isTokenValidated
                            && authData.status.statusValue
                            && !authData.isError
                            && !authData.isPasswordChanged &&
                            <>
                                <p className="text-lg  text-black  text-center">
                                    To reset password enter email below.
              </p>
                                <form className="p-6 space-y-8" onSubmit={handleResetPwd}>
                                    <input type="hidden" name="remember" defaultValue="true" />
                                    <div className="rounded-2xl -space-y-px">
                                        <FCInputField isBorder={false} className="bg-fcinput" type="password" id="password" placeholder="New Password" icon="pwd" isError={isPwdEntered}
                                            onChange={handleChange} value={pwd} />

                                        <FCInputField isBorder={false} className="bg-fcinput" type="password" id="cnfPassword" placeholder="Confirm Password" icon="pwd" isError={isCnfPwdEntered}
                                            onChange={handleChange} value={cnfPwd} />
                                    </div>
                                    <div>
                                        <FCButton bgColor="bg-fcbutton" type="submit" icon="rightarrow" value="Reset Password" />
                                    </div>
                                </form>
                            </>
                        }

                        {authData.isTokenValidated && !authData.status.statusValue && !authData.isPasswordChanged &&
                            <>
                                <p className="text-lg h-2  text-black  text-center pb-20">
                                    The reset link is no longer valid.
              </p>
                                <div>
                                    <button
                                        id="btnSend"
                                        type="button"
                                        onClick={goToLoginPage}
                                        className="inline-flex items-center px-6 py-3 border-transparent text-base bg-fcbutton
                             rounded-full text-white focus:outline-none w-4/5 ml-10 justify-between">
                                        <span className="text-white w-full ">Login</span>
                                        <CheveronCircleRightSvgIcon />
                                    </button>
                                </div>
                            </>
                        }

                        {authData.isPasswordChanged && authData.status.statusValue && authData.isTokenValidated &&
                            <div className="pb-20">
                                <p className="text-lg h-2  text-black  text-center pb-20">
                                    Password changed successfully.
              </p>
                                <div>
                                    <button
                                        id="btnSend"
                                        type="button"
                                        onClick={goToLoginPage}
                                        className="inline-flex items-center px-6 py-3 border-transparent text-base bg-fcbutton
                             rounded-full text-white focus:outline-none w-4/5 ml-10 justify-between">
                                        <span className="text-white w-full ">Login</span>
                                        <CheveronCircleRightSvgIcon />
                                    </button>
                                </div>
                            </div>
                        }
                        {
                            <>
                                <p className="text-lg h-2 text-red-500 mt-5  text-black  text-center">
                                    {authData.status.statusDisplay}
                                    {!isEqualPassword && <span>Password and confirm password are not same.</span>}
                                    {!isValidPassword && <span>Password should contain 8 characters with, 1 upper, 1 lower and 1 number</span>}
                                    <p className="h-2 text-red-500 mt-5  text-black  text-center">
                                        {(isPwdEntered || isCnfPwdEntered
                                        ) && "Please enter the required fields."}
                                    </p>
                                </p>

                            </>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => {
    const { authData } = state;
    return {
        authData
    };
};

export default connect(mapStateToProps)(ResetPwd);
