import { all, put, call } from "redux-saga/effects";
import {
	GET_SETTINGS_COMPLETED,
	GET_SETTINGS_FAILED,
	UPDATE_SETTINGS_COMPLETED,
	UPDATE_SETTINGS_FAILED
} from "../actions/settings";

import { SettingsApi } from "../../utils/api/settingsApi";
import { ClientPreferencesApi } from "../../utils/api/clientPreferencesApi";
import { GETSITES_FAILED } from "../actions/site";


export function* getSettings(request: any) {
	try {
		const { settings, clientPreferences } = yield all({
			settings: call(SettingsApi.getSettings, request.input),
			clientPreferences: call(ClientPreferencesApi.getClientPreferences, request.input)
		});
		const data = {
			settings: settings.data.result,
			clientPreferences: clientPreferences.data.result,
		}
		yield put({
			type: GET_SETTINGS_COMPLETED,
			payload: data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: GET_SETTINGS_FAILED, payload: e.message });
	}
}


export function* saveSettings(request: any) {
	try {
		const { settings, clientPreferences } = yield all({
			settings: call(SettingsApi.saveSettings, request.input),
			clientPreferences: call(ClientPreferencesApi.saveClientPreferences, request.input)
		});
		const data = {
			status: settings.data.status,
			settings: settings.data.result,
			clientPreferences: clientPreferences.data.result,
		}
		yield put({
			type: UPDATE_SETTINGS_COMPLETED,
			payload: data
		});
	} catch (e) {
		yield put({ type: UPDATE_SETTINGS_FAILED, payload: e.message });
	}
}
