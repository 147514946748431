import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import FCInputField from '../../utils/library/textbox';
import { Validators, validateInput } from '../../utils/library/Validator';
import FCButton from '../../utils/library/button';
import FCButtonWithClick from '../../utils/library/buttonwithclick';
import FCMessageDialog from '../../utils/library/dialog';
import Select, { StylesConfig } from 'react-select';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  PlusCircleSvgIcon, TrashSvgIcon, EditSvgIcon, CloseIcon, PoundIcon
} from '../../utils/common/icon-hooks.js';
import { saveSamplePlan } from '../../store/actions/samplePlan';
import { checkUniqueTestPointName } from '../../store/actions/testpoint';
import { getTemplate, saveSamplePlanQuote } from '../../store/actions/samplePlanQuote';
import { getUserData } from '../../utils/common/functions';
import FCConfirmAlert from '../../utils/library/confirmalert';
import { site } from '../../utils/models/model';
import FCCheckBox from '../../utils/library/checkbox';
import { useHistory } from "react-router-dom";


interface IProps {
  dispatch: Dispatch<any>;
	sampleplanQuoteData: any;
	sampleplanData: any;
	changepage: any;
	templateId: any;
	sites: any;
	testPointData: any;
}

const CreatePlan: React.SFC<IProps> = ({
	sampleplanQuoteData, dispatch, changepage, templateId, sites, sampleplanData, testPointData }) => {
  const validators =
    [{
      check: Validators.required,
      message: 'All fields are required.'
		}];
	const [isRequiredError, setIsRequiredError] = useState(false);
  const [planName, setPlanName] = useState("");
  const [isLive, setIsLive] = useState(false);
  const [isPlanNameEntered, setIsPlanNameEntered] = useState(false);
  const [pointName, setPointName] = useState("");
  const [location, setLocation] = useState("");
  const [where, setWhere] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [pointId, setPontId] = useState("");
  const [isDayOfWeekDropDown, setIsDayOfWeekDropDown] = useState(true);
  const [isPointNameEntered, setIsPointNameEntered] = useState(false);
  const [isLocationEntered, setIsLocationEntered] = useState(false);
  const [isWhereEntered, setIsWhereEntered] = useState(false);
  const [isPointsEntered, setIsPointsEntered] = useState(true);
  const [isSamplePlanSaved, setIsSamplePlanSaved] = useState(false);
	const [testPoints, setTestPoints] = useState([{ name: '', location: '', where: '', id: '0' }]);
	const [addedSites, setAddedSites] = useState([]);
  const [pointDialog, setPointDialog] = useState(false);
  const [saveDialog, setSaveDialog] = useState(false);
  const [days, setDays] = useState([{ label: '', value: '-1' }]);
  const [selectedDay, setSelectedDay] = useState(-1);
  const [selectedWeekDays, setSelectedWeekDays] = useState([0]);
  const [isWeekOrDaySelected, setIsWeekOrDaySelected] = useState(true);
  const [templateReceived, setTemplateRecevied] = useState(false);
  const [frequency, setFrequency] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [isSuccessAlert, SetIsSuccessAlert] = useState(false);
  const [isShowConfirmAlert, setIsShowConfirmAlert] = useState(false);
  const [addMode, setAddMode] = useState(false);
	const [isShowConfirmAlertForClearTable, setIsShowConfirmAlertForClearTable] = useState(false);
	const [userData, setUserData] = useState(null);
	const [alertMessage, setAlertMessage] = useState("");
	const [allSites, setAllSites] = useState([site]);
	const [isUniqueTestPoint, setIsUniqueTestPoint] = useState(false);
	const [isSiteSelected, setIsSiteSelected] = useState(false);
	const history = useHistory();

  const weekDays = [
    { label: 'Sun', value: 1 },
    { label: 'Mon', value: 2 },
    { label: 'Tue', value: 3 },
    { label: 'Wed', value: 4 },
    { label: 'Thu', value: 5 },
    { label: 'Fri', value: 6 },
    { label: 'Sat', value: 7 }];
  const cancelButtonRef = useRef(null);
	let selectRefDay = null;
	let selectRefWeeks = null;

	const handleClearPoints = () => {
		setTestPoints([]);
		if (selectRefDay !== null) {
			selectRefDay.clearValue();
		}
		if (selectRefWeeks !== null) {
			selectRefWeeks.clearValue();
		}
		setIsShowConfirmAlertForClearTable(false);
		setPlanName("");
	}
  
  if (sampleplanQuoteData && sampleplanQuoteData.status) {
    //Testpoints from the selected Template
    if (sampleplanQuoteData.isTemplateLoaded && !templateReceived) {
      let items = [{ name: '', location: '', where: '', id: '0' }];
			items.splice(0, 1);
			console.log(sampleplanQuoteData.templateResult);
      sampleplanQuoteData.templateResult.testPoints.forEach(function (item) {
        var obj = { name: item.name, location: item.location, where: item.where, id: item.id };
        items.push(obj);
      });
      setTestPoints([]);
      setTestPoints(items);
      setTemplateRecevied(true);
    }

    //Save
		if (sampleplanData.isPlanSaved && !isSamplePlanSaved) {
      //Show the success dialog
			setSaveDialog(false);
			setIsSamplePlanSaved(true);
			SetIsSuccessAlert(sampleplanData.status.statusValue);
			//setShowAlert(true); 
			if (sampleplanData.status.statusValue) {
				window.localStorage.setItem('alertMessage', "Successfully saved.");
				history.push('/sampleplans');
			}
			else {
				setAlertMessage("Error occured...");
			}
    }
  }

	React.useEffect(() => {
		var usrData = getUserData();
		setUserData(usrData);
		if (!usrData.id) { window.location.href = "./"; }

		//setFrequencyText("Weekly");
		setFrequency(1);
    setTestPoints([]);
		setSelectedWeekDays([]);

    //Set the days and weeks,
    days.shift();
    days.push({ label: "Select day", value: "-1" });
    for (var i = 1; i <= 31; i++) {
      days.push({ label: i.toString(), value: i.toString() });
    }
		setDays(days);

		setAllSites(sites);
		//Get the test points for the given tamplate id
		if (templateId && templateId > -1) {
			dispatch(getTemplate({ userId: usrData.id, userToken: usrData.userToken, templateId: templateId }));
		}
  }, []);

  const handleFrequencyChange = (event) => {
    if (selectRefDay !== null) {
      selectRefDay.clearValue();
    }
    if (selectRefWeeks !== null) {
      selectRefWeeks.clearValue();
    }
    setFrequency(event.value);
    //setFrequencyText(event.label);
    if (event.value <= 3) {
      setIsDayOfWeekDropDown(true);
    }
    else {
      setIsDayOfWeekDropDown(false);
    }
  }

  const handleDayChange = (event) => {
    if (event === null) { return; }
    setSelectedWeekDays([]);
    setSelectedDay(event.value);
    setIsWeekOrDaySelected(true);
  }

  const handleWeekChange = (event) => {
    if (event === null) { return; }
    setSelectedDay(-1);
    if (event.length > 0) {
      var items = new Array(0);
      items.splice(0, 1);
      event.forEach(function (item) {
        items.push(item.value);
      });
      setSelectedWeekDays([]);
      setSelectedWeekDays(items);
    }
    if (event.length === 0) {
      setSelectedWeekDays([]);
      setIsWeekOrDaySelected(false);
    }
    else {
      setIsWeekOrDaySelected(true);
    }
  }

  const handleChange = (event) => {
    if (event.target.name === 'pointName') {
      setPointName(event.target.value);
			setIsPointNameEntered(validateInput(validators, event.target.value));
			setIsUniqueTestPoint(false);
    }
    if (event.target.name === 'location') {
      setLocation(event.target.value);
      setIsLocationEntered(validateInput(validators, event.target.value));
    }
    if (event.target.name === 'where') {
      setWhere(event.target.value);
      setIsWhereEntered(validateInput(validators, event.target.value));
    }
    if (event.target.name === 'planName') {
      setPlanName(event.target.value);
      setIsPlanNameEntered(validateInput(validators, event.target.value));
	  }
  };

  const showPointDialog = (event) => {
    if (selectedWeekDays.length > 0 || selectedDay !== -1) {
      setIsWeekOrDaySelected(true);
      setPointDialog(true);
      setAddMode(true);
    }
    else {
      setIsWeekOrDaySelected(false);
		}
		setIsUniqueTestPoint(false);
		//setPointDialog(true);
  };

  const handleDeleteSite = (event) => {
    event.preventDefault();
    const index = testPoints.findIndex(({ id }) => id === pointId);
    if (index > -1) {
      testPoints.splice(index, 1)
      setTestPoints([...testPoints]);
    }
    setIsShowConfirmAlert(false);
  }

  const handleConfirmCancel = (event) => {
    setIsShowConfirmAlert(false);
  }

  const removePlan = (event, idToRemove) => {
    event.preventDefault();
    setPontId(idToRemove);
    setIsShowConfirmAlert(true);
  }

  const editPlan = (event, idToRemove) => {
    event.preventDefault();
    const index = testPoints.find(({ id }) => id === idToRemove);
    if (index) {
      setLocation(index.location);
      setWhere(index.where);
      setPointName(index.name);
      setPontId(idToRemove);
    }
    setIsEditMode(true);
    setAddMode(false);
    setPointDialog(true);
	}
	
  const handlePointName = (event) => {
    event.preventDefault();
    setIsPointNameEntered(validateInput(validators, pointName));
    setIsLocationEntered(validateInput(validators, location));
		setIsWhereEntered(validateInput(validators, where));
		if (!isEditMode) {
			var duplicate = false;
			testPoints.map((titem) => {
				if (pointName === titem.name) {
					setIsUniqueTestPoint(true);
					duplicate = true;
				}
			})
			if (!duplicate) {
				var name = {
					userId: userData.id,
					userToken: userData.userToken,
					name: pointName
				}
				dispatch(checkUniqueTestPointName(name));
			}
		} else {
			saveTestPoint();
		}
  };

	const saveTestPoint = () =>  {
		if (pointName !== '' && location !== '' && where !== '') {
			var newPointName = {
				name: pointName,
				location: location,
				where: where,
				id: isEditMode ? pointId : "-" + new Date().getTime().toString()
			}
			if (isEditMode) {
				const index = testPoints.findIndex(({ id }) => id === pointId);
				testPoints[index] = newPointName;
				setTestPoints(testPoints);
			}
			else {
				testPoints.push(newPointName);
				setTestPoints([...testPoints]);
			}

			setIsPointsEntered(true);
			//Clear the inputs
			setLocation('');
			setWhere('');
			setPointName('');
			//Close the point dialog
			setPointDialog(false);
		}
	}

	if (testPointData) {
		if (testPointData.isUniqueChecked) {
			testPointData.isUniqueChecked = false;
			if (testPointData.isUniqueTestPoint) {
				saveTestPoint();
				setIsUniqueTestPoint(true);
			} else {
				
			}
		}
	}

  const showSaveDialog = (event) => {
		event.preventDefault();
		if (!validateNullOrUndefined()) {
			setIsRequiredError(true);
		}
		else {
			setIsRequiredError(false);
			setSaveDialog(true);
		}
		
		var result = allSites.filter((item) => { return item.selected; });
		if (result.length <= 0) {
			setIsSiteSelected(true);
			return;
		}
		else {
			setIsSiteSelected(false);
		}

		if (selectedWeekDays.length > 0 || selectedDay !== -1) {
      setIsWeekOrDaySelected(true);
      //Show the save dialog
      setSaveDialog(true);
    }
    else {
      setIsWeekOrDaySelected(false);
    }
    if (testPoints.length <= 0) {
      setIsPointsEntered(false);
    }
  }

  const Styles: StylesConfig = {
		option: provided => ({
			...provided,
			color: '#002366'
		}),
		control: (provided, state) => ({
			...provided,
			boxShadow: "none",
			border: "none",
			backgroundColor: "none"
		}),
		singleValue: (provided) => ({
			...provided,
			color: '#002366'
		})
  };

  const columns = [
    {
			Header: <div style={{
				textAlign: "left", color: "#6b7280" }}>Name</div>,
      accessor: "name"
    },
    {
			Header: <div style={{ textAlign: "left", color: "#6b7280" }}>Location</div>,
      accessor: "location"
    },
    {
			Header: <div style={{ textAlign: "left", color: "#6b7280" }}>Where</div>,
      accessor: "where"
    },
    {
      Header: "", Cell: (obj)  => (
        <a href="#" onClick={(event) => editPlan(event, obj.original.id)}>
          <EditSvgIcon />
        </a>
      ), width: 70, accessor: "id" },
    {
      Header: "", Cell: (obj) => (
        <a href="#" onClick={(event) => removePlan(event, obj.original.id)}>
          <TrashSvgIcon />
        </a>
      ), width: 70, accessor: "id"
    }];

  const handleCancelClick = (event) => {
    event.preventDefault();
    setPointDialog(false);
  }

	const handleConfirmClearCancel = (event) => {
		setIsShowConfirmAlertForClearTable(false);
	}

	const closeDialog = () => {
		setShowAlert(false);
	}

	//Site change event
	const onAllSiteChange = (event) => {
		//var siteId = event.target.getAttribute("data-id");
		//sites.forEach(item => {
		//	if (item.id === parseInt(siteId)) {
		//		item.selected = !item.selected;
		//	}
		//});
		setAllSites(sites);
		var siteId = event.target.getAttribute("data-id");
		var selectedSites = [];
		var foundSiteId = addedSites.findIndex((val) => val === parseInt(siteId));
		if (foundSiteId > -1) {
			addedSites.splice(foundSiteId, 1);
		}
		else {
			addedSites.push(parseInt(siteId));
		}

		addedSites.forEach((item) => {
			selectedSites.push(parseInt(item));
		});
		setAddedSites(selectedSites);
	}

	const validateNullOrUndefined = () => {
		//var siteIds = [];
		//allSites.map((sitem) => { sitem.selected && siteIds.push(sitem.id); })
		if ((selectedWeekDays.length > 0 || selectedDay !== -1)
					&& testPoints.length !== 0 && addedSites.length !== 0) {
			return true;
		}
		else {
			return false;
		}
	}

	const handleIsLiveChange = () => {
		setIsLive(!isLive);
	}

	const addorUpdateSamplePlan = (event) => {
		event.preventDefault();
		setIsPlanNameEntered(validateInput(validators, planName));
		if (planName !== "") {
			//setIsPlanNameEntered(true);
			setIsRequiredError(false);
			setIsSamplePlanSaved(false);

			//get selected test points
			var pointIds = []; var siteIds = [];
			var newTestPoints = [] as any;
			testPoints.map((titem) => {
				if (Number(titem.id) > 0) {
					pointIds.push(titem.id);
				} else {
					var test = {
						name: titem.name,
						location: titem.location,
						where: titem.where,
						id: 0
					}
					newTestPoints.push(test);
				}
			})
			//allSites.map((sitem) => { sitem.selected && siteIds.push(sitem.id); })
			addedSites.forEach((id) => { siteIds.push(id); });
			var samplePlanObj = {
				userId: userData.id,
				userToken: userData.userToken,
				id: 0,
				name: planName,
				isLive: isLive,
				frequency: frequency.toString(),
				dayOfWeek: (frequency <= 3 ? selectedWeekDays.toString() : ""),
				dayOfMonth: (frequency === 4 ? selectedDay : 0),
				testpointIds: pointIds,
				siteIds: siteIds,
				testPoints: newTestPoints
			};
			dispatch(saveSamplePlan(samplePlanObj));
		}
	}

  return (
    <React.Fragment>
      <FCConfirmAlert message="Are you sure you want to delete this test Point?" isOpen={isShowConfirmAlert} title="Delete Test Point"
				onOkClick={(event) => handleDeleteSite(event)} onCancelClick={(event) => handleConfirmCancel(event)} />
			<FCConfirmAlert message="Are you sure you wish to clear all sample plan data?" isOpen={isShowConfirmAlertForClearTable} title="Test Point"
				onOkClick={() => handleClearPoints()} onCancelClick={(event) => handleConfirmClearCancel(event)} />

			{showAlert && <FCMessageDialog isSuccess={isSuccessAlert} modalCallBack={() => closeDialog()} message={alertMessage } />}
			<div className={"pb-5 pt-5"}>
        <div className="w-full text-lg  text-gray-600 text-center">Create Sample Plan</div>
        {/*<button onClick={showDialog}> Show dialog </button>*/}
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 ">
					<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div className="w-full flex">
							<div className="w-1/2">
								<div className="max-w-lg block focus:outline-none w-full">
									<label htmlFor="name" className="ml-px block px-2 py-1  samplePlanTextColor">
										Frequency
									</label>
									<div className="mt-1 sm:mt-0 sm:col-span-2 ">
										<Select
											defaultValue={{ label: 'Weekly', value: 1 }}
											onChange={(event) => handleFrequencyChange(event)}
											name="frequency"
											options={[{ label: 'Weekly', value: 1 },
											{ label: 'Fortnightly', value: 2 },
											{ label: 'Every 4 Weeks', value: 3 },
											{ label: 'Monthly', value: 4 }]}
											styles={Styles}
											className="basic-multi-select text-fcinputtext border-gray-300 border-2 rounded-2xl"
											classNamePrefix="select"
										/>
									</div>
								</div>
								<div>
									<div className={isDayOfWeekDropDown ? "max-w-lg block focus:outline-none w-full " : "hidden"}>
										<label htmlFor="name" className="ml-px block px-2 py-1  samplePlanTextColor ">
											Day Of Week
										</label>
										<div className="mt-1 sm:mt-0 sm:col-span-2">
											<Select
												ref={ref => {
													selectRefWeeks = ref;
												}}
												onChange={(event) => handleWeekChange(event)}
												maxMenuHeight={250}
												isMulti
												name="Week"
												options={weekDays}
												styles={Styles}
												className={"basic-multi-select text-fcinputtext border-2 rounded-2xl" + (!isWeekOrDaySelected ? " border-red-300 " : " border-gray-300 ")}
												classNamePrefix="select"
												escapeClearsValue={true}
											/>
										</div>
									</div>
									<div className={!isDayOfWeekDropDown ? "max-w-lg block focus:outline-none w-full " : "hidden"}>
										<label htmlFor="name" className="ml-px block px-2 py-1 ">
											Day of Month
										</label>
										<div className="mt-1 sm:mt-0 sm:col-span-2">
											<Select
												ref={ref => {
													selectRefDay = ref;
												}}
												onChange={(event) => handleDayChange(event)}
												maxMenuHeight={250}
												name="day"
												options={days}
												styles={Styles}
												className={"basic-multi-select text-fcinputtext border-2 rounded-2xl" + (!isWeekOrDaySelected ? " border-red-300 " : " border-gray-300 ")}
												classNamePrefix="select"
											/>
										</div>
								  </div>
								  <div className="max-w-lg block focus:outline-none w-full pt-3">
									  <div className="w-full flex-row">
										  <input
											  key="1"
											  id="1"
											  name="isLive"
											  type="checkbox"
											  checked={isLive}
											  onChange={() => handleIsLiveChange()}
											  className={"h-4 w-4 border-2 border-gray-300 rounded-2xl "}
										  />
										  <label htmlFor="isLive" className="h-4 pl-2 text-gray-500">Is Live</label>
									  </div>
								  </div>
								  <div className="mb-3 w-3/4 flex flex-row mx-auto">
									  
								  </div>
								</div>
								<div className="mb-3">
									<button
										onClick={showPointDialog}
										type="button"
										className="inline-flex  items-center py-2 border border-transparent 
											bg-transparent text-block focus:outline-none samplePlanTextColor"
									>
										<PlusCircleSvgIcon />&nbsp;&nbsp;Add Test Point
									</button>
								</div>
							</div>
							<div className="w-1/2">
								<label htmlFor="name" className="w-2/4 mx-auto block px-2 py-1  samplePlanTextColor">
									Sites
								</label>
								<FCCheckBox isError={isSiteSelected} options={allSites}
									onChange={(event) => onAllSiteChange(event)} width="w-2/4"
									selectedValues={addedSites}
									label="" />
							</div>
						</div>
						<div className="align-left">
							<ReactTable
								data={testPoints}
								minRows={4}
								noDataText={""}
								columns={columns}
								showPagination={false}
								resizable={false}
								style={{
									textAlign: "left",
									height: "250px" // This will force the table body to overflow and scroll, since there is not enough room
								}}
								className="-highlight border-0 mb-1 align-left samplePlanTextColor"
								pageSize={testPoints.length > 0 ? testPoints.length : 0}
							/>
							<div className="pb-1 sm:flex sm:items-center justify-end">
								{/*{!isWeekOrDaySelected && <label*/}
								{/*		className="inline-flex  pl-10 text-red-500">Please select the Day of Month or Week value.</label>}*/}
								{/*{!isPointsEntered && <label*/}
								{/*	className=" text-red-500">Please add test points to continue.</label>}*/}
								<div className="inline-flex  pl-10 text-red-500  text-center">
									{(isRequiredError || !isWeekOrDaySelected || !isPointsEntered) && <span>Please enter the required fields</span>}
								</div>
								<div className="mt-3 flex sm:mt-0 sm:ml-4">
									<FCButtonWithClick onHandleClick={() => changepage(false)} bgColor="bg-fcbutton px-10 mr-5" type="submit" icon="" value="Cancel" />
									<FCButtonWithClick onHandleClick={ showSaveDialog } bgColor="bg-primary px-10" type="submit" icon="" value="Save" />
								</div>
							</div>
						</div>
					</div>
        </div>
      </div>

      <Transition.Root show={pointDialog} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setPointDialog}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:px-10">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setPointDialog(false)}
                  >
                    <span className="sr-only">Close</span>
                    <CloseIcon />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center w-full mb-5">
										<Dialog.Title as="h3" className="text-lg leading-6  text-gray-500">
                      {addMode ? "Add Test Point" : "Edit Test Point"}
                    </Dialog.Title>
                  </div>
                </div>
                <form onSubmit={(event) => handlePointName(event)}>
                  <div>
                    <label htmlFor="textpointname" className="block   text-gray-700">
                      Test Point Name
                    </label>
                    <div className="mt-1">
											<FCInputField isBorder={true} className=" samplePlan " type="text" id="pointName"
												placeholder="" icon="" isError={isPointNameEntered} leftPadding=" "
                        onChange={handleChange} value={pointName} /> 
                    </div>
                  </div>
                  <div>
                    <label htmlFor="location" className="block   text-gray-700">
                      Location
                    </label>
                    <div className="mt-1">
											<FCInputField isBorder={true} className=" samplePlan " type="text" id="location"
												placeholder="" icon="" isError={isLocationEntered} leftPadding=" "
                        onChange={handleChange} value={location} />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="where" className="block   text-gray-700">
                      Where
                    </label>
                    <div className="mt-1">
											<FCInputField isBorder={true} className=" samplePlan " type="text" id="where"
												placeholder="" icon="" isError={isWhereEntered} leftPadding=" "
                        onChange={handleChange} value={where} />
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex w-1/2 float-right">
                    <FCButtonWithClick onHandleClick={(event) => handleCancelClick(event)}
                      bgColor="bg-fcbutton mr-3" type="button" icon="" value="Cancel" />
                    <FCButton bgColor="bg-primary" type="submit" icon="" value="Save" />
									</div>
									{isUniqueTestPoint && <label
										className="inline-flex text-red-500">Test Point already exists.</label>}
                </form>
              </div>
              
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={saveDialog} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setSaveDialog}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
							<div className="inline-block align-bottom bg-white font-large rounded-2xl px-16 pt-4 text-left overflow-hidden transform transition-all sm:align-middle">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setSaveDialog(false)}
                  >
                    <span className="sr-only">Close</span>
                    <CloseIcon />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center w-full mb-5">
                    <Dialog.Title as="h3" className="text-lg leading-6  text-gray-500">
                      Save Sample Plan
                    </Dialog.Title>
                  </div>
                </div>
                <form onSubmit={(event) => addorUpdateSamplePlan(event)}>
                  <div>
                    <div className="w-full flex flex-col">
                      <label htmlFor="templates" className="block  text-gray-700">
                        Sample Plan Name
                      </label>
                      <div className="mt-1 mb-5">
                        <FCInputField isBorder={true} className="" type="text" id="planName"
													placeholder="" icon="" isError={isPlanNameEntered} leftPadding=" "
                          onChange={handleChange} value={planName} />
											</div>
												{isPlanNameEntered && <label
													className="inline-flex text-red-500">Please enter the plan name.</label>}
                    </div>
                  </div>
                  <div className="flex flex-col pb-5 justify-center items-center">
                      <FCButton bgColor="bg-primary" type="submit" icon="" value="Save" />
									</div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
	const { sampleplanQuoteData, sampleplanData, testPointData } = state;
  return {
		sampleplanQuoteData, sampleplanData, testPointData
  };
};

export default connect(mapStateToProps)(CreatePlan);
