import { Http } from "../Http";
import { AxiosPromise } from "axios";

export class SampleplanApi {
	public static getSamplePlans(input: any) {
		return Http.axios().get('/SamplePlan/GetAll?userId=' + input.userId
			+ "&userToken=" + input.userToken)
			.catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

	public static getTestPoints(input: any) {
		return Http.axios().get('/TestPoint/GetAllTestPoint?userId=' + input.userId
			+ "&userToken=" + input.userToken)
			.catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

	public static saveSamplePlan(input: any) {
		var obj = JSON.stringify(input);
		return Http.axios().post('/SamplePlan/SaveSamplePlan', obj, { headers: { 'Content-Type': 'application/json' } })
			.then(response => {
				return response;
			}).catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

	public static deleteSamplePlan(input: any) {
		var obj = JSON.stringify(input);
		return Http.axios().post('/SamplePlan/DeleteSamplePlan', obj, { headers: { 'Content-Type': 'application/json' } })
			.then(response => {
				return response;
			}).catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

	public static importSamplePlan(input: any) {
		var obj = JSON.stringify(input);
		return Http.axios().post('/SamplePlan/ImportPlan', obj, { headers: { 'Content-Type': 'application/json' } })
			.then(response => {
				return response;
			}).catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}
}