import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from 'redux';
import { useState } from 'react'
import FCSiteInputField from "../../utils/library/sitetextbox";
import FCButtonWithClick from '../../utils/library/buttonwithclick';
import { TrashSvgIcon } from "../../utils/common/icon-hooks";
import { client } from '../../utils/models/model';
import "../../custom.css";
import Select from 'react-select';
import { Countries, getBase64, getUserData, addDefaultSrc, getCountryByCode } from '../../utils/common/functions';
import { getSingleClientWithImage, addorUpdateClient, deleteClient, updateAvatar } from '../../store/actions/clients';
import { Validators, validateInput } from '../../utils/library/Validator';
import FCConfirmAlert from '../../utils/library/confirmalert';

interface IClientDetailsProps {
  dispatch: Dispatch<any>;
  clientData: any;
  clientId: number,
  seeAlert: any
}

const ClientDetails: React.SFC<IClientDetailsProps> = ({
  dispatch, clientData, clientId, seeAlert }) => {

  const history = useHistory();
  const [fileSelector, setFileSelector] = useState(null);
  const [base64, setBase64] = useState('');
  const [isAvatarUpdated, setIsAvatarUpdated] = useState(false);
  const [selectedClient, setSelectedClient] = useState(client);
  const [clientdisplayed, setClientdisplayed] = useState(false);
  const [clientDeleteSuccess, isClientDeleteSuccess] = useState(false);
  const [saveLabel, setSaveLabel] = useState("Create");
  const [userData, setUserData] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(-1);
  const [isShowConfirmAlert, setIsShowConfirmAlert] = useState(false);
  const [isClientAdded, setIsClientAdded] = useState(false);
  const [isNameEntered, setIsNameEntered] = useState(false);
  const [isAddress1Entered, setIsAddress1Entered] = useState(false);
  const [isTownEntered, setIsTownEntered] = useState(false);
  const [isCityEntered, setIsCityEntered] = useState(false);
  const [isPostcodeEntered, setIsPostcodeEntered] = useState(false);
  const [isRequiredError, setIsRequiredError] = useState(false);
	const [isError, setIsError] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState(Countries[0]);

  const buildFileSelector = () => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    return fileSelector;
  }

  React.useEffect(() => {
    setFileSelector(buildFileSelector);
    var usrData = getUserData();
		setUserData(usrData);
		setSelectedCountry({ label: "United Kingdom", value: "GB" });
    if (clientId > 0) {
      dispatch(getSingleClientWithImage({ id: clientId }));
      setSaveLabel("Update");
    }
  }, []);

  if (clientData && clientData.status) {
    if (clientData.isClientLoaded && !clientdisplayed) {
			setSelectedClient(clientData.client);
			if (clientData.client) {
				var cntry = getCountryByCode(clientData.client.country);
				setSelectedCountry(cntry);
			}
      setClientdisplayed(true);
    }
    if (clientData.isClientAdded && !isClientAdded) {
      if (clientData.status.statusValue) {
        window.localStorage.setItem('alertMessage', "Successfully saved.");
		history.push('/clients');
      }
	  else {
		  setIsError(true);
      }
      setIsClientAdded(true);
    }
    if (clientData.isClientDeleted && !clientDeleteSuccess) {
      if (clientData.status.statusValue) {
		  window.localStorage.setItem('alertMessage', "Successfully deleted.");
		  history.push('/clients');
      } else {
		  setIsError(true);
      }
      isClientDeleteSuccess(true);
    }
    if (clientData.isAvatarUpdated && !isAvatarUpdated) {
      setFileSelector(buildFileSelector);
      seeAlert(true, clientData.status.statusValue, clientData.status.statusDisplay);
      setIsAvatarUpdated(true);
    }
  }

  const removeClient = (event) => {
    if (clientId > 0) {
      event.preventDefault();
      setSelectedClientId(clientId);
      setIsShowConfirmAlert(true);
    }
  }

  const validators =
    [{
      check: Validators.required,
      message: 'Company name is required.'
    }];

  const onInputChange = (event) => {
    if (event.name === 'name') {
      setIsNameEntered(validateInput(validators, event.value));
    }
    if (event.name === 'addressLine1') {
      setIsAddress1Entered(validateInput(validators, event.value));
    }
    if (event.name === 'town') {
      setIsTownEntered(validateInput(validators, event.value));
    }
    if (event.name === 'city') {
      setIsCityEntered(validateInput(validators, event.value));
    }
    if (event.name === 'postcode') {
      setIsPostcodeEntered(validateInput(validators, event.value));
    }

    setSelectedClient(prevState => ({
      ...prevState,
      [event.name]: event.value
    }));
  }

  const onCountryChange = (event) => {
    setSelectedClient(prevState => ({
      ...prevState,
      country: event.value
    }));
		setSelectedCountry(getCountryByCode(event.value));
  }

  const onCancel = (event) => {
	  history.push('/clients');
  }

  const saveClient = () => {
    seeAlert(false, false, "");
    setIsNameEntered(validateInput(validators, selectedClient.name));
    setIsAddress1Entered(validateInput(validators, selectedClient.addressLine1));
    setIsCityEntered(validateInput(validators, selectedClient.city));
    setIsTownEntered(validateInput(validators, selectedClient.town));
    setIsPostcodeEntered(validateInput(validators, selectedClient.postcode));

    if (validateNullOrUndefined()) {
      setIsClientAdded(false);
      setIsRequiredError(false);
      selectedClient.userId = userData.id;
			selectedClient.userToken = userData.userToken;
      dispatch(addorUpdateClient(selectedClient));
    }
    else {
      setIsRequiredError(true);
    }
  }

  const handleDeleteSite = (event) => {
    event.preventDefault();
    seeAlert(false, false, "");
		userData && dispatch(deleteClient({ userId: userData.id, userToken: userData.userToken, id: selectedClientId }));
    setIsShowConfirmAlert(false);
  }

  const handleConfirmCancel = (event) => {
    setIsShowConfirmAlert(false);
    setIsRequiredError(false);
  }

  const validateNullOrUndefined = () => {
    if ((typeof (selectedClient.name) !== "undefined" && selectedClient.name) &&
      (typeof (selectedClient.addressLine1) !== "undefined" && selectedClient.addressLine1) &&
      (typeof (selectedClient.city) !== "undefined" && selectedClient.city) &&
      (typeof (selectedClient.postcode) !== "undefined" && selectedClient.postcode) &&
      (typeof (selectedClient.town) !== "undefined" && selectedClient.town)) {
      return true;
    }
    else {
      return false;
    }
  }

  const handleFileSelect = (event) => {
    event.preventDefault();
    seeAlert(false, false, "");
    fileSelector.click();
    fileSelector.onchange = function (e) {
      getBase64(e.currentTarget.files[0], (result) => {
				setIsAvatarUpdated(false);
				var imagePlainText = result.replace(/^data:image\/[a-z]+;base64,/, "");
				if (clientId > 0) {
					//dispatch to save..
					dispatch(updateAvatar({ userId: userData.id, userToken: userData.userToken, id: clientId, imageUrl: imagePlainText }));
				} else {
					setSelectedClient(prevState => ({
						...prevState,
						imageUrl: imagePlainText
					}));
				}
        setBase64(result);
      });
    };
  }

  return (
    <React.Fragment>
      <FCConfirmAlert message="Are you sure you want to delete this client?" isOpen={isShowConfirmAlert} title="Delete Client"
        onOkClick={(event) => handleDeleteSite(event)} onCancelClick={(event) => handleConfirmCancel(event)} />

      <div className="py-3 ">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-1xl  text-gray-500 text-center">Clients</h1>
        </div>

        <div className="mt-1 w-1/2 text-lg  text-gray-600 text-center mx-auto">
          <img
            className="inline-block h-12 w-12 rounded-full"
						src={base64 == '' ?
							selectedClient.imageUrl
							: base64} alt=""
						onError={addDefaultSrc}
          />
        </div>
        <div className="mt-4 w-1/2 text-lg text-gray-600 text-center underline mx-auto">
          <a href='#' onClick={(event) => handleFileSelect(event)}>Change Profile</a>
        </div>

        <div className="py-4 w-1/2 overflow-hidden h-1/2 mx-auto">
          <div className="mb-3 w-3/4 mx-auto">
            <FCSiteInputField required={true} id="name" onChange={(event) => onInputChange(event.target)} labelText="Company"
              value={selectedClient.name} isError={isNameEntered} />
          </div>
          <div className="mb-3 w-3/4 mx-auto">
            <FCSiteInputField required={true} id="addressLine1" onChange={(event) => onInputChange(event.target)} labelText="Address 1"
              value={selectedClient.addressLine1} isError={isAddress1Entered} />
          </div>
          <div className="mb-3 w-3/4 mx-auto">
            <FCSiteInputField id="addressLine2" onChange={(event) => onInputChange(event.target)} labelText="Address 2"
              value={selectedClient.addressLine2} />
          </div>
          <div className="mb-3 w-3/4 mx-auto">
            <FCSiteInputField required={true} id="town" onChange={(event) => onInputChange(event.target)} labelText="Town"
              value={selectedClient.town} isError={isTownEntered} />
          </div>

          <div className="mb-3 w-3/4 mx-auto">
            <FCSiteInputField required={true} id="city" onChange={(event) => onInputChange(event.target)} labelText="City"
              value={selectedClient.city} isError={isCityEntered} />
          </div>
          <div className="mb-5 w-3/4 flex flex-row mx-auto">
            <div className="w-1/2 flex-row">
              <FCSiteInputField required={true} id="postcode" onChange={(event) => onInputChange(event.target)} labelText="Postcode"
                value={selectedClient.postcode} width="w-3/4" className="pr-1 flex-row" isError={isPostcodeEntered} />
            </div>
            <div className="w-1/2 flex-row">
              <Select
								value={selectedCountry}
                onChange={(event) => onCountryChange(event)}
                name="country"
                options={Countries}
								styles={{
									option: provided => ({
										...provided,
										color: '#002366'
									}),
									control: (provided, state) => ({
										...provided,
										boxShadow: "none",
										border: "none",
										backgroundColor: "none"
									}),
									singleValue: (provided) => ({
										...provided,
										color: '#002366'
									})
								}}
								className="basic-multi-select border-2 rounded-2xl border-gray-300 text-fcinputtext"
                classNamePrefix="select"
              />
              <label htmlFor="email" className="flex flex-row text-gray-500">Country</label>
            </div>
          </div>
          <div className="mt-1 w-3/4 flex mx-auto">
            <FCButtonWithClick onHandleClick={onCancel} bgColor="bg-fcbutton mr-3" type="button" icon="" value="Cancel" />
            <FCButtonWithClick onHandleClick={saveClient} bgColor="bg-primary" type="button" icon="" value={saveLabel} />
            {clientId > 0 &&
              <button
                type="button"
                onClick={(event) => removeClient(event)}
                className={"inline-flex items-center px-6 py-3 border-transparent text-base " +
                  "  rounded-full text-white  focus:outline-none " +
                  " w-1/3 justify-between "}>
                <TrashSvgIcon />
              </button>
            }
          </div>
          <div className="text-red-500  py-3 text-center">
			{isRequiredError && <span>Please enter the required fields</span>}
			{isError && <span>{clientData.status.statusDisplay}</span>}					  
          </div>

        </div>
      </div>

    </React.Fragment >
  );
};


const mapStateToProps = (state: any) => {
  const { clientData } = state;
  return {
    clientData
  };
};

export default connect(mapStateToProps)(ClientDetails);