import { Http } from "../Http";
import { AxiosPromise } from "axios";

export class UsersApi {

  public static getAllUsers(input: any) {
		return Http.axios().get('/UserManagement/GetAllUsersWithImg?userId=' + input.userId + "&userToken=" + input.userToken)
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static getSingleUser(input: any) {
    return Http.axios().get('/UserManagement/GetSingleUser?userId=' + input.userId + "&userToken=" + input.userToken + "&id=" + input.id)
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static getSingleUserWithImg(input: any) {
    return Http.axios().get('/UserManagement/GetSingleUserWithImg?userId=' + input.userId + "&userToken=" + input.userToken + "&id=" + input.id)
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static deleteUser(input: any) {
    return Http.axios().get('/UserManagement/DeleteUser?userId=' + input.userId + "&userToken=" + input.userToken + "&id=" + input.id)
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static addOrUpdateUser(input: any) {
    var obj = JSON.stringify(input);

    if (input.id > 0) {
      url = '/UserManagement/UpdateUser';
      return Http.axios().put(url, obj, { headers: { 'Content-Type': 'application/json' } })
        .then(response => {
          return response;
        }).catch((e) => {
          return e.response;
        }) as AxiosPromise<any>;
    }
    else {
      var url = '/UserManagement/AddUser';
      return Http.axios().post(url, obj, { headers: { 'Content-Type': 'application/json' } })
        .then(response => {
          return response;
        }).catch((e) => {
          return e.response;
        }) as AxiosPromise<any>;
    }
  }

  public static updateUserAvatar(input: any) {
    var obj = JSON.stringify(input);
    return Http.axios().post('/UserManagement/UpdateAvatar', obj, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        return response;
      }).catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

  public static getUserRoles(input: any) {
    return Http.axios().get('/UserManagement/GetUserRoles?userId=' + input.userId + "&userToken=" + input.userToken)
      .catch((e) => {
        return e.response;
      }) as AxiosPromise<any>;
  }

}
