import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useState } from 'react'
import ReactTable from "react-table-6";
import { Dispatch } from 'redux';
import { PlusCircleSvgIcon, EditSvgIcon, TrashSvgIcon } from "../../utils/common/icon-hooks";
import FCInputSearchField from '../../utils/library/textboxsearch';
import { getUsers, postUserDelete, getUserRoles } from '../../store/actions/user';
import FCConfirmAlert from '../../utils/library/confirmalert';
import { getUserData } from '../../utils/common/functions';
import { addDefaultSrc } from '../../utils/common/functions'

interface IClientProps {
  dispatch: Dispatch<any>;
  usersData: any;
  seeAlert: any;
}


const Users: React.SFC<IClientProps> = ({
  dispatch, usersData, seeAlert }) => {

  const history = useHistory();
  const [tableData, setTablesData] = useState([]);
  const [usersAdded, setUsersAdded] = useState(false);
  const [searchText, setSearchText] = useState(String);
  const [isShowConfirmAlert, setIsShowConfirmAlert] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isUserDeleted, setIsUserDeleted] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(-1);
	const [isUserRolesAdded, setIsUserRolesAdded] = useState(false);
	const [roleList, setRoleList] = useState([]);

  React.useEffect(() => {
	var usrData = getUserData();
    setUserData(usrData);
    setSearchText("");
	dispatch(getUsers({ userId: usrData.id, userToken: usrData.userToken}));
  }, []);

	React.useEffect(() => {
		if (usersData && usersData.status) {
			if (usersData.isUsersLoaded && !usersAdded) {
				if (usersData.usersResult && usersData.usersResult.length > 0) {
					let list = new Array<any>();
					usersData.usersResult.forEach((element) => {
						let item = usersData.userRolesResult.find(
							e => e.id === element.roleId
						);
						if (item) {
							element.roleName = item.name;
						}
						list.push(element);
					});
					setTablesData(list);
					if (userData && userData.id) {
						setUsersAdded(true);
						dispatch(getUserRoles({ userId: userData.id, userToken: userData.userToken }));
					}
				}
			}

			if (usersData.isUserDeleted && !isUserDeleted) {
				seeAlert(true, usersData.status.statusValue, "Deleted Successfully");
				if (userData && userData.id)
					dispatch(getUsers({ userId: userData.id, userToken: userData.userToken }));
				setUsersAdded(false);
				setIsUserDeleted(true);
			}

			if (usersData.isUserRolesLoaded && !isUserRolesAdded) {
				setIsUserRolesAdded(true);
				setRoleList(usersData.userRolesResult);
				let list = new Array<any>();
				tableData.forEach((element) => {
					let item = usersData.userRolesResult.find(
						e => e.id === element.roleId
					);
					if (item) {
						element.roleName = item.name;
					}
					list.push(element);
				});
				setTablesData([]);
				setTablesData(list);
			}
		}
	}, [usersData])

  const removeUser = (event, idToDelete, userName) => {
    event.preventDefault();
    setSelectedUserId(idToDelete);
    setIsShowConfirmAlert(true);
  }

  const handleDeleteSite = (event) => {
    event.preventDefault();
    seeAlert(false, false, "");
    setIsUserDeleted(false);
		userData && dispatch(postUserDelete({ userId: userData.id, userToken: userData.userToken, id: selectedUserId }));
    setIsUserDeleted(false);
    setIsShowConfirmAlert(false);
  }

  const handleConfirmCancel = (event) => {
    setIsShowConfirmAlert(false);
  }

  const handleUserSearch = (txtValue) => {
    setSearchText(txtValue)
    if (usersData.usersResult && usersData.usersResult.length > 0) {
      if (txtValue === "") {
        setTablesData(usersData.usersResult);
      } else {
        var filterResult = usersData.usersResult.filter(function (item) {
          return (((item.firstName.toLowerCase().indexOf((txtValue).toLowerCase())) >= 0) ||
            ((item.userName.toLowerCase().indexOf((txtValue).toLowerCase())) >= 0) ||
            ((item.lastName.toLowerCase().indexOf((txtValue).toLowerCase())) >= 0)
          );
        });
        setTablesData(filterResult);
      }
    }
  }

	const columns = [
		{
			Header: <div style={{ textAlign: "left" }}></div>,
			Cell: (obj) => (
				<div>
					<img
						className="inline-block object-cover h-12 w-12 rounded-full"
						src={obj.original.imageUrl}
						alt=""
						onError={addDefaultSrc}
					/>
				</div>
			),
			width: 80,
			accessor: "imageUrl"
		},
		{
			Header: <div style={{ textAlign: "left" }}>Username</div>,
			accessor: "userName"
		},
		{
			Header: <div style={{ textAlign: "left" }}>First Name</div>,
			accessor: "firstName"
		},
		{
			Header: <div style={{ textAlign: "left" }}>Last Name</div>,
			accessor: "lastName"
		},
		{
			Header: <div style={{ textAlign: "left" }}>Role</div>,
			accessor: "roleName"
		},
		{
			Header: <div style={{ textAlign: "left" }}>Email</div>,
			accessor: "email"
		},
		{
			Header: "", Cell: (obj) => (
			<div className="cursor-pointer" onClick={() => history.push("/users/" + obj.original.id)}>
			  <EditSvgIcon />
			</div>
      ), width: 70, accessor: "id"
    },
    {
      Header: "", Cell: (obj) => (
        <a href="#" onClick={(event) => removeUser(event, obj.original.id, obj.original.firstName)}>
          <TrashSvgIcon />
        </a>
      ), width: 70, accessor: "id"
    }];

  return (
    <React.Fragment>
      <FCConfirmAlert message="Are you sure you want to delete this user?" isOpen={isShowConfirmAlert} title="Delete User"
        onOkClick={(event) => handleDeleteSite(event)} onCancelClick={(event) => handleConfirmCancel(event)} />
      <div className="py-3">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-500 text-center">User Management</h1>
        </div>

        <div className="mt-9 w-full mb-4 text-gray-500">
          <div
			className="block cursor-pointer "
			onClick={() => history.push("/users/0")}
          >
            Add User
          </div>

          <div className="pl-3 inline-flex w-1/2 mb-1 mx-auto">
            <div
				className="pr-12 inline-flex  items-center border border-transparent bg-transparent text-block focus:outline-none cursor-pointer"
				onClick={() => history.push("/users/0")}
            >
              <PlusCircleSvgIcon />
            </div>
            <FCInputSearchField className="" placeholder="Search" id="search" value={searchText} onChange={(event) => handleUserSearch(event)} />

          </div>
        </div>

        <ReactTable
          data={tableData}
          minRows={3}
          columns={columns}
          showPagination={false}
          resizable={false}
          noDataText={<h2 className=" text-center align-middle">No users found</h2>}
					style={tableData.length < 10 ? {
						textAlign: "left",
					} : {
						textAlign: "left",
						height: "400px"
					}}
					className="mt-9 -highlight border-0 mb-1 align-left"
					pageSize={tableData.length > 0 ? tableData.length : 0}
        />

      </div>

    </React.Fragment>
  );
};


const mapStateToProps = (state: any) => {
  const { usersData } = state;
  return {
    usersData
  };
};

export default connect(mapStateToProps)(Users);