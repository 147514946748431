export const GET_TEST_POINT_STARTED = "GET_TEST_POINT_STARTED";
export const GET_TEST_POINT_COMPLETED = "GET_TEST_POINT_COMPLETED";
export const GET_TEST_POINT_FAILED = "GET_TEST_POINT_FAILED";

export const POST_SINGLE_TEST_POINT_DELETE_STARTED = "POST_SINGLE_TEST_POINT_DELETE_STARTED";
export const POST_SINGLE_TEST_POINT_DELETE_COMPLETED = "POST_SINGLE_TEST_POINT_DELETE_COMPLETED";
export const POST_SINGLE_TEST_POINT_DELETE_FAILED = "POST_SINGLE_TEST_POINT_DELETE_FAILED";

export const POST_ADD_TEST_POINT_STARTED = "POST_ADD_TEST_POINT_STARTED";
export const POST_ADD_TEST_POINT_COMPLETED = "POST_ADD_TEST_POINT_COMPLETED";
export const POST_ADD_TEST_POINT_FAILED = "POST_ADD_TEST_POINT_FAILED";

export const UNIQUE_TEST_POINT_STARTED = "UNIQUE_TEST_POINT_STARTED";
export const UNIQUE_TEST_POINT_COMPLETED = "UNIQUE_TEST_POINT_COMPLETED";
export const UNIQUE_TEST_POINT_FAILED = "UNIQUE_TEST_POINT_FAILED";

export const getAllTestPoint = (input: any) => {
	return {
		type: GET_TEST_POINT_STARTED,
		input: input
	};
};

export const deleteTestPoint = (input: any) => {
	return {
		type: POST_SINGLE_TEST_POINT_DELETE_STARTED,
		input: input
	};
};

export const addOrUpdateTestPoint = (input: any) => {
	return {
		type: POST_ADD_TEST_POINT_STARTED,
		input: input
	};
};

export const checkUniqueTestPointName = (input: any) => {
	return {
		type: UNIQUE_TEST_POINT_STARTED,
		input: input
	};
};