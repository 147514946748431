import { all, takeLatest } from 'redux-saga/effects';

import {
	login, logout, fetchCheckLoggedUser, forgetpwdChange, forgetpwd, forgetPasswordValidateToken, register
} from './account';
import {
	LOGOUT_STARTED, LOGIN_STARTED, CHECKLOGGEDUSER_STARTED, FORGET_PASSWORD_TOKEN_STARTED,
	FORGET_PASSWORD_STARTED, FORGET_PASSWORD_CHANGE_STARTED, ACCOUNT_REGISTER_STARTED
} from '../actions/account';
import {
	getTemplates, getTemplate, saveSamplePlanQuote, getTemplateList, getTemplateById
} from './samplePlanQuote';
import {
	GETTEMPLATES_STARTED, SAVESAMPLEPLANQUOTE_STARTED, GETTEMPLATE_STARTED,
	GETTEMPLATELIST_STARTED, GETTEMPLATEBYID_STARTED
} from '../actions/samplePlanQuote';
import { addCorrectiveAction, deleteCorrectiveAction, getCorrectiveActions }
	from './correctiveaction';
import { GETCORRECTIVEACTION_STARTED, DELETECORRECTIVEACTION_STARTED, ADDCORRECTIVEACTION_STARTED }
	from '../actions/correctiveaction';
import { getAllSites, getSitesByClient, getSite, saveSite, deleteSite }
	from './site';
import {
	getClients, deleteClient, getSingleClientWithImg, addorUpdateClient, updateClientAvatar
} from './clients';
import { GETALLSITES_STARTED, GETSITES_STARTED, SAVESITE_STARTED, GETSITE_STARTED, DELETESITE_STARTED }
	from '../actions/site';
import {
	GET_CLIENTS_STARTED, POST_DELETE_STARTED, GET_SINGLE_CLIENT_IMG_STARTED,
	POST_ADD_STARTED, CHANGE_CLIENT_AVATAR_STARTED
} from '../actions/clients';

import { GET_USERPROFILE_STARTED, UPDATE_USERPROFILE_STARTED, CHANGEPASSWORD_STARTED, CHANGEAVATAR_STARTED }
	from '../actions/userProfile';
import {
	GET_USERS_STARTED,
	POST_SINGLE_USER_DELETE_STARTED,
	GET_SINGLE_USER_STARTED,
	POST_ADD_USER_STARTED,
	POST_UPDATE_USER_AVATAR_STARTED,
	GET_USERS_ROLES_STARTED
} from '../actions/user';
import { getUsers, deleteUser, addorUpdateUser, getSingleUserWithImg, updateUserAvatar, getUserRoles } from './user';
import { getUserProfile, updateUser, changePassword, updateAvatar } from './userProfile';
import { GET_SETTINGS_STARTED, UPDATE_SETTINGS_STARTED } from '../actions/settings';
import { getSettings, saveSettings } from './settings';
import {
	GET_TEST_POINT_STARTED, POST_SINGLE_TEST_POINT_DELETE_STARTED,
	POST_ADD_TEST_POINT_STARTED, UNIQUE_TEST_POINT_STARTED
} from '../actions/testpoint';
import { getgetAllTestPoint, deleteTestPoint, addOrUpdateTestPoint, checkUniqueTestPointName } from './testpoint';

import {
	GETALLSAMPLEPLANS_SARTED, GETALLTESTPOINTS_SARTED, SAVESAMPLEPLAN_STARTED,
	DELETESAMPLEPLAN_STARTED, IMPORT_SAMPLEPLAN_STARTED
} from '../actions/samplePlan';
import { getSamplePlans, getTestPoints, saveSamplePlan, deleteSamplePlan, importSamplePlan } from './samplePlan';

import {
	GET_ALL_REPORT_STARTED,
	GET_GLOBAL_COMPARISON_STARTED,
	GET_DASHBOARD_DATA_STARTED,
	GET_DOWNLOAD_REPORT_STARTED,
	GET_DOWNLOAD_DATA_STARTED,
	GET_TESTHISTORY_STARTED
} from '../actions/dashboard';
import { getAllReport, getGlobalComparisonData, getDashboardData, getDownloadReport, getDownloadData, getTestHistoryData } from './dashboard';

export default function* rootSaga() {
	yield all([
		//Login, forgot password
		takeLatest(LOGIN_STARTED, login),
		takeLatest(LOGOUT_STARTED, logout),
		takeLatest(CHECKLOGGEDUSER_STARTED, fetchCheckLoggedUser),
		takeLatest(FORGET_PASSWORD_TOKEN_STARTED, forgetPasswordValidateToken),
		takeLatest(FORGET_PASSWORD_CHANGE_STARTED, forgetpwdChange),
		takeLatest(FORGET_PASSWORD_STARTED, forgetpwd),
		takeLatest(ACCOUNT_REGISTER_STARTED, register),

		//Sites
		takeLatest(GETALLSITES_STARTED, getAllSites),
		takeLatest(GETSITES_STARTED, getSitesByClient),
		takeLatest(GETSITE_STARTED, getSite),
		takeLatest(SAVESITE_STARTED, saveSite),
		takeLatest(DELETESITE_STARTED, deleteSite),

		//Sample plan and quote
		takeLatest(SAVESAMPLEPLANQUOTE_STARTED, saveSamplePlanQuote),
		takeLatest(GETTEMPLATES_STARTED, getTemplates),
		takeLatest(GETTEMPLATE_STARTED, getTemplate),
		takeLatest(GETTEMPLATELIST_STARTED, getTemplateList),
		takeLatest(GETTEMPLATEBYID_STARTED, getTemplateById),
		takeLatest(GETALLSAMPLEPLANS_SARTED, getSamplePlans),
		takeLatest(GETALLTESTPOINTS_SARTED, getTestPoints),
		takeLatest(SAVESAMPLEPLAN_STARTED, saveSamplePlan),
		takeLatest(DELETESAMPLEPLAN_STARTED, deleteSamplePlan),
		takeLatest(IMPORT_SAMPLEPLAN_STARTED, importSamplePlan),

		//Clients
		takeLatest(GET_CLIENTS_STARTED, getClients),
		takeLatest(POST_DELETE_STARTED, deleteClient),
		takeLatest(GET_SINGLE_CLIENT_IMG_STARTED, getSingleClientWithImg),
		takeLatest(POST_ADD_STARTED, addorUpdateClient),
		takeLatest(CHANGE_CLIENT_AVATAR_STARTED, updateClientAvatar),

		//userManagement
		takeLatest(GET_USERS_STARTED, getUsers),
		takeLatest(POST_SINGLE_USER_DELETE_STARTED, deleteUser),
		takeLatest(GET_SINGLE_USER_STARTED, getSingleUserWithImg),
		takeLatest(POST_ADD_USER_STARTED, addorUpdateUser),
		takeLatest(POST_UPDATE_USER_AVATAR_STARTED, updateUserAvatar),
		takeLatest(GET_USERS_ROLES_STARTED, getUserRoles),

		//My Profile
		takeLatest(GET_USERPROFILE_STARTED, getUserProfile),
		takeLatest(UPDATE_USERPROFILE_STARTED, updateUser),
		takeLatest(CHANGEPASSWORD_STARTED, changePassword),
		takeLatest(CHANGEAVATAR_STARTED, updateAvatar),

		//CorrectiveActions
		takeLatest(GETCORRECTIVEACTION_STARTED, getCorrectiveActions),
		takeLatest(ADDCORRECTIVEACTION_STARTED, addCorrectiveAction),
		takeLatest(DELETECORRECTIVEACTION_STARTED, deleteCorrectiveAction),

		//settings
		takeLatest(GET_SETTINGS_STARTED, getSettings),
		takeLatest(UPDATE_SETTINGS_STARTED, saveSettings),

		//Test Point
		takeLatest(GET_TEST_POINT_STARTED, getgetAllTestPoint),
		takeLatest(POST_SINGLE_TEST_POINT_DELETE_STARTED, deleteTestPoint),
		takeLatest(POST_ADD_TEST_POINT_STARTED, addOrUpdateTestPoint),
		takeLatest(UNIQUE_TEST_POINT_STARTED, checkUniqueTestPointName),

		// Dashboard
		takeLatest(GET_ALL_REPORT_STARTED, getAllReport),
		takeLatest(GET_GLOBAL_COMPARISON_STARTED, getGlobalComparisonData),
		takeLatest(GET_DASHBOARD_DATA_STARTED, getDashboardData),
		takeLatest(GET_DOWNLOAD_REPORT_STARTED, getDownloadReport),
		takeLatest(GET_DOWNLOAD_DATA_STARTED, getDownloadData),
		takeLatest(GET_TESTHISTORY_STARTED, getTestHistoryData)
	]);
}
