import { put, call } from "redux-saga/effects";
import { TestPointApi } from "../../utils/api/testpointApi";

import {

	GET_TEST_POINT_COMPLETED,
	GET_TEST_POINT_FAILED,

	POST_SINGLE_TEST_POINT_DELETE_COMPLETED,
	POST_SINGLE_TEST_POINT_DELETE_FAILED,

	POST_ADD_TEST_POINT_COMPLETED,
	POST_ADD_TEST_POINT_FAILED,

	UNIQUE_TEST_POINT_COMPLETED,
	UNIQUE_TEST_POINT_FAILED

} from '../actions/testpoint';

export function* getgetAllTestPoint(request: any) {
	try {
		const result = yield call(TestPointApi.getAllTestPoint, request.input);
		yield put({
			type: GET_TEST_POINT_COMPLETED,
			payload: result.data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: GET_TEST_POINT_FAILED, payload: e.message });
	}
}

export function* deleteTestPoint(request: any) {
	try {
		const result = yield call(TestPointApi.deleteTestpoint, request.input);
		yield put({
			type: POST_SINGLE_TEST_POINT_DELETE_COMPLETED,
			payload: result.data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: POST_SINGLE_TEST_POINT_DELETE_FAILED, payload: e.message });
	}
}

export function* addOrUpdateTestPoint(request: any) {
	try {
		const result = yield call(TestPointApi.addOrUpdateTestPoint, request.input);
		console.log(result.data);
		yield put({
			type: POST_ADD_TEST_POINT_COMPLETED,
			payload: result.data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: POST_ADD_TEST_POINT_FAILED, payload: e.message });
	}
}

export function* checkUniqueTestPointName(request: any) {
	try {
		const result = yield call(TestPointApi.checkUniqueTestPointName, request.input);
		yield put({
			type: UNIQUE_TEST_POINT_COMPLETED,
			payload: result.data,
			input: request.input
		});
	} catch (e) {
		yield put({ type: UNIQUE_TEST_POINT_FAILED, payload: e.message });
	}
}