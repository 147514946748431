import * as React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const FCSelectSiteFieldWithNoValue = ({ value, onChange, id, isValueEntered, options, className }) => {
	const handleChange = (event) => {
		onChange(event);
	};

  return (
    <>
			<Select
				id={id}
				name={id}
				onChange={(event) => handleChange(event)}
				options={options} 
				isClearable={true}
				escapeClearsValue={true}
				styles={{
					option: provided => ({
						...provided,
						color: '#002366'
					}),
					control: (provided, state) => ({
						...provided,
						boxShadow: "none",
						border: "none",
						backgroundColor: "none"
					}),
					singleValue: (provided) => ({
						...provided,
						color: '#002366'
					})
				}}
				className={"basic-multi-select border-2 rounded-2xl text-fcinputtext " + (!isValueEntered ? " border-red-300 " : " border-gray-300 ") + className}
				classNamePrefix="select"
			/>
    </>
  )
};

FCSelectSiteFieldWithNoValue.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  isError: PropTypes.bool,
  className: PropTypes.string,
	isValueEntered: PropTypes.bool,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.any.isRequired,
			label: PropTypes.string.isRequired
		})
	)
};

FCSelectSiteFieldWithNoValue.defaultProps = {
	value: '',
	isValueEntered: true,
	className: ''
};

export default FCSelectSiteFieldWithNoValue;