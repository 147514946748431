import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import FCInputField from '../../utils/library/textbox';
import { Validators, validateInput } from '../../utils/library/Validator';
import FCButton from '../../utils/library/button';
import FCButtonWithClick from '../../utils/library/buttonwithclick';
import FCMessageDialog from '../../utils/library/dialog';
import Select, { StylesConfig } from 'react-select';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  PlusCircleSvgIcon, TrashSvgIcon, EditSvgIcon, CloseIcon, PoundIcon
} from '../../utils/common/icon-hooks.js';
import { getTemplates, getTemplateList, getTemplateById, getTemplate, saveSamplePlanQuote } from '../../store/actions/samplePlanQuote';
import { getUserData, getTestPointCost } from '../../utils/common/functions';
import FCConfirmAlert from '../../utils/library/confirmalert';

interface ILoginProps {
  dispatch: Dispatch<any>;
  sampleplanQuoteData: any;
}

const SampePlanQuoteTool: React.SFC<ILoginProps> = ({
  sampleplanQuoteData, dispatch }) => {
  const validators =
    [{
      check: Validators.required,
      message: 'All fields are required.'
    }];
  const [planName, setPlanName] = useState("");
  const [isPlanNameEntered, setIsPlanNameEntered] = useState(false);
  const [pointName, setPointName] = useState("");
  const [location, setLocation] = useState("");
  const [where, setWhere] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [pointId, setPontId] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [testPoinstCost, setTestPoinstCost] = useState(0);
  const [isDayOfWeekDropDown, setIsDayOfWeekDropDown] = useState(true);
  const [isPointNameEntered, setIsPointNameEntered] = useState(false);
  const [isLocationEntered, setIsLocationEntered] = useState(false);
  const [isWhereEntered, setIsWhereEntered] = useState(false);
  const [isSamplePlansEntered, setIsSamplePlansEntered] = useState(true);
  const [isSamplePlanSaved, setIsSamplePlanSaved] = useState(false);
  const [samplePlans, setSamplePlans] = useState([{ name: '', location: '', where: '', id: '0' }]);
  const [pointDialog, setPointDialog] = useState(false);
  const [templateDialog, setTemplateDialog] = useState(false);
  const [saveDialog, setSaveDialog] = useState(false);
  const [days, setDays] = useState([{ label: '', value: '-1' }]);
  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedWeekDays, setSelectedWeekDays] = useState([0]);
  const [isWeekOrDaySelected, setIsWeekOrDaySelected] = useState(true);
  const [templateList, setTemplateList] = useState([{ label: '', value: 0 }]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(-1);
  const [templatesAdded, setTemplatesAdded] = useState(false);
  const [templateReceived, setTemplateRecevied] = useState(false);
  const [frequency, setFrequency] = useState(1);
  const [frequencyText, setFrequencyText] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isSuccessAlert, SetIsSuccessAlert] = useState(false);
  const [isShowedTemplateModal, setIsShowedTemplateModal] = useState(false);
  const [isShowConfirmAlert, setIsShowConfirmAlert] = useState(false);
  const [addMode, setAddMode] = useState(false);
	const [isShowConfirmAlertForClearTable, setIsShowConfirmAlertForClearTable] = useState(false);
	const [userData, setUserData] = useState(null);
	const [showNav, setShowNav] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [costByValue, setCostByValue] = useState(4);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [selectedWeekDaysOption, setSelectedWeekDaysOption] = useState([{ label: '', value: 0 }]);
	const [selectedDayOption, setSelectedDayOption] = useState([{ label: '', value: 0 }]);

  const weekDays = [
    { label: 'Mon', value: 1 },
    { label: 'Tue', value: 2 },
    { label: 'Wed', value: 3 },
    { label: 'Thu', value: 4 },
    { label: 'Fri', value: 5 },
		{ label: 'Sat', value: 6 },
		{ label: 'Sun', value: 7 }];

  const cancelButtonRef = useRef(null);
	let selectRefDay = null;
	let selectRefWeeks = null;

	const handleClearPoints = () => {
		setSamplePlans([]);
		if (selectRefDay !== null) {
			selectRefDay.clearValue();
		}
		if (selectRefWeeks !== null) {
			selectRefWeeks.clearValue();
		}
		setIsShowConfirmAlertForClearTable(false);
		setPlanName("");
		window.localStorage.removeItem('QUOTEDATA');
	}

	const setDataFromLocalStorage = () => {
		const quoteDataStore = localStorage.getItem("QUOTEDATA");
		if (quoteDataStore != null) {
			const quoteData = JSON.parse(quoteDataStore);
			setSamplePlans(quoteData.testPoints);
			setFrequency(quoteData.frequency);
			setFrequencyText(quoteData.frequencyText);

			if (quoteData.frequencyText != "Weekly") {
				setCostByValue(1);
				setIsDayOfWeekDropDown(false);
				setSelectedDay(quoteData.dayOfMonth);
				setIsWeekOrDaySelected(true);
				var opt = [];
				//setSelectedDayOption({ label: "" + quoteData.dayOfMonth + "", value: quoteData.dayOfMonth});
			}
			else {
				setSelectedDay(-1);
				setSelectedWeekDays([]);
				setSelectedWeekDays(quoteData.dayOfWeek);
				setIsDayOfWeekDropDown(true);
				setIsWeekOrDaySelected(true);
				var opt = []; var values = [];
				quoteData.dayOfWeek.split(',').forEach((item) => {
					var result = weekDays.find(o => o.value === parseInt(item));
					if (result) {
						opt.push({ label: result.label, value: result.value });
						values.push(result.value)
					}
				});
				setSelectedWeekDaysOption(opt);
				setCostByValue(4 * opt.length);
			}
			setTotalCost(quoteData.testPoints.length * testPoinstCost);
			setPlanName(quoteData.name);
		}
		else {
			setTemplateDialog(true);
		}
	}

  if (templatesAdded && !isShowedTemplateModal) {
    //if (userData.roleId == -1) {
    //  setTemplateDialog(true);
    //  setIsShowedTemplateModal(true);
    //}
  }

  if (sampleplanQuoteData && sampleplanQuoteData.status) {
    //Template List for drop down
    if (sampleplanQuoteData.isTemplatesLoaded && !templatesAdded) {
      let items = [{ label: '', value: 0 }];
      items.splice(0, 1);
			sampleplanQuoteData.templatesResult && sampleplanQuoteData.templatesResult.forEach(function (item) {
        var obj = { label: item.name, value: item.id };
        items.push(obj);
      });
      setTemplateList([]);
      setTemplateList(items);
			sampleplanQuoteData.templatesResult && setSelectedTemplateId(items[0].value);
			setTemplatesAdded(true);
			setDataFromLocalStorage();
    }
    
    //Testpoints from the selected Template
    if (sampleplanQuoteData.isTemplateLoaded && !templateReceived) {
      let items = [{ name: '', location: '', where: '', id: '0' }];
      items.splice(0, 1);
      sampleplanQuoteData.templateResult.testPoints.forEach(function (item) {
        var obj = { name: item.name, location: item.location, where: item.where, id: item.id };
        items.push(obj);
      });
      setSamplePlans([]);
      setSamplePlans(items);
      setTemplateRecevied(true);
      setTotalCost(items.length * testPoinstCost);
    }
    //Save
    if (sampleplanQuoteData.isPlanSaved && !isSamplePlanSaved) {
      //Show the success dialog
			setIsSamplePlanSaved(true);
			setSaveDialog(false);
			SetIsSuccessAlert(sampleplanQuoteData.status.statusValue);
			setShowAlert(true);
			setIsWeekOrDaySelected(true);
			if (sampleplanQuoteData.status.statusValue) {
				setAlertMessage("Saved successfully.");
			}
			else {
				setAlertMessage("Error occured...");
			}
    }
  }

	React.useEffect(() => {
		var usrData = getUserData();
		if (usrData == null) {
			setIsLoggedIn(false);
			window.localStorage.removeItem('QUOTEDATA');
			setShowNav(false);
		}
		else if (usrData.id) {
			setIsLoggedIn(true);
			setShowNav(true);
		}
		setSelectedWeekDaysOption([]);
		setUserData(usrData);
		//if (!usrData.id) { window.location.href = "./"; }

    setFrequencyText("Weekly");
    setSamplePlans([]);
    setSelectedWeekDays([]);
		var costValue = getTestPointCost();
    setTestPoinstCost(costValue);
    //Set the days and weeks,
    days.shift();
    days.push({ label: "Select day", value: "-1" });
    for (var i = 1; i <= 31; i++) {
      days.push({ label: i.toString(), value: i.toString() });
    }
		setDays(days);
		//if (usrData.roleId !== -1) {
		//	setShowNav(false);
		//}
		//Get the templates
		dispatch(getTemplateList());

  }, []);

  const handleFrequencyChange = (event) => {
    if (selectRefDay !== null) {
      selectRefDay.clearValue();
    }
    if (selectRefWeeks !== null) {
      selectRefWeeks.clearValue();
    }
    setFrequency(event.value);
    setFrequencyText(event.label);
		if (event.value <= 3) {
			setCostByValue(4);
      setIsDayOfWeekDropDown(true);
    }
		else {
			setCostByValue(1);
      setIsDayOfWeekDropDown(false);
    }
  }

  const handleDayChange = (event) => {
    if (event === null) { return; }
    setSelectedWeekDays([]);
    setSelectedDay(event.value);
    setIsWeekOrDaySelected(true);
  }

  const handleWeekChange = (event) => {
    if (event === null) { return; }
		setSelectedDay(-1);
		setCostByValue(4 * event.length);
    if (event.length > 0) {
			var items = new Array(0);
			var options = [];
      items.splice(0, 1);
      event.forEach(function (item) {
				items.push(item.value);
				options.push(item);
      });
      setSelectedWeekDays([]);
			setSelectedWeekDays(items);
			setSelectedWeekDaysOption(options);
    }
    if (event.length === 0) {
      setSelectedWeekDays([]);
      setIsWeekOrDaySelected(false);
    }
    else {
      setIsWeekOrDaySelected(true);
    }
  }

  const handleTemplateChange = (event) => {
    setSelectedTemplateId(event.value);
  }

  const handleChange = (event) => {
    if (event.target.name === 'pointName') {
      setPointName(event.target.value);
      setIsPointNameEntered(validateInput(validators, event.target.value));
    }
    if (event.target.name === 'location') {
      setLocation(event.target.value);
      setIsLocationEntered(validateInput(validators, event.target.value));
    }
    if (event.target.name === 'where') {
      setWhere(event.target.value);
      setIsWhereEntered(validateInput(validators, event.target.value));
    }
    if (event.target.name === 'planName') {
      setPlanName(event.target.value);
      setIsPlanNameEntered(validateInput(validators, event.target.value));
    }
  };

  const showPointDialog = (event) => {
    //if (selectedWeekDays.length > 0 || selectedDay !== -1) {
    //  setIsWeekOrDaySelected(true);
    //  setPointDialog(true);
    //  setAddMode(true);
    //}
    //else {
    //  setIsWeekOrDaySelected(false);
    //}
		setPointDialog(true);
  };

	const handleDeleteTestPoint = (event) => {
    event.preventDefault();
    const index = samplePlans.findIndex(({ id }) => id === pointId);
    if (index > -1) {
      samplePlans.splice(index, 1)
      setSamplePlans([...samplePlans]);
      setTotalCost(samplePlans.length * testPoinstCost);
    }
    setIsShowConfirmAlert(false);
  }

  const handleConfirmCancel = (event) => {
    setIsShowConfirmAlert(false);
  }

  const removePlan = (event, idToRemove) => {
    event.preventDefault();
    setPontId(idToRemove);
    setIsShowConfirmAlert(true);
  }

  const editPlan = (event, idToRemove) => {
    event.preventDefault();
    const index = samplePlans.find(({ id }) => id === idToRemove);
    if (index) {
      setLocation(index.location);
      setWhere(index.where);
      setPointName(index.name);
      setPontId(idToRemove);
    }
    setIsEditMode(true);
    setAddMode(false);
    setPointDialog(true);
  }

  const handlePointName = (event) => {
    event.preventDefault();
    setIsPointNameEntered(validateInput(validators, pointName));
    setIsLocationEntered(validateInput(validators, location));
    setIsWhereEntered(validateInput(validators, where));

    if (pointName !== '' && location !== '' && where !== '') {
      var newPointName = {
        name: pointName,
        location: location,
        where: where,
        id: isEditMode? pointId : "0"
      }
      if (isEditMode) {
        const index = samplePlans.findIndex(({ id }) => id === pointId);
        samplePlans[index] = newPointName;
        setSamplePlans(samplePlans);
      }
      else {
        samplePlans.push(newPointName);
        setSamplePlans([...samplePlans]);
      }

      setIsSamplePlansEntered(true);
      //Clear the inputs
      setLocation('');
      setWhere('');
      setPointName('');
      //Close the point dialog
      setPointDialog(false);
      //TotalCost
      setTotalCost(samplePlans.length * testPoinstCost);
    }
  };

  const getSelectedTemplate = (event) => {
    //Dispatch to get the templates
    event.preventDefault();
    if (selectedTemplateId !== -1) {
			dispatch(getTemplateById(selectedTemplateId));
      setTemplateDialog(false);
    }
  };

  const clearPlans = () => {
		setIsShowConfirmAlertForClearTable(true);
  }

  const showSaveDialog = (event) => {
    event.preventDefault();
    if (selectedWeekDays.length > 0 || selectedDay !== -1) {
      setIsWeekOrDaySelected(true);
      //Show the save dialog
      setSaveDialog(true);
    }
    else {
      setIsWeekOrDaySelected(false);
    }
    if (samplePlans.length <= 0) {
      setIsSamplePlansEntered(false);
    }
  }

  const savePlans = (event) => {
    event.preventDefault();
    setShowAlert(false);

    var userData = getUserData();

		setIsPlanNameEntered(validateInput(validators, planName));
		
    if (planName !== '')
    {
      //Save the data
      dispatch(saveSamplePlanQuote({
        userId: userData.id,
				userToken: userData.userToken,
        id: 0,
        name: planName,
        frequency: frequencyText,
        testpointIds: [0],
				siteIds: [0],
				testPoints: samplePlans,
        dayOfWeek: (frequency <= 3 ? selectedWeekDays.toString() : ""),
        dayOfMonth: (frequency === 4 ? selectedDay : 0),
			}));
			handleClearPoints();
    }
  }

	const handleLogin = () => {
		setShowAlert(false);
		var userData = getUserData();
		setIsPlanNameEntered(validateInput(validators, planName));
		if (planName !== '') {
			//Save the data
			window.localStorage.setItem('QUOTEDATA',
				JSON.stringify({
					userId: -1,
					userToken: "",
					id: 0,
					name: planName,
					frequency: frequency,
					frequencyText: frequencyText,
					testpointIds: [0],
					siteIds: [0],
					testPoints: samplePlans,
					dayOfWeek: (frequency <= 3 ? selectedWeekDays.toString() : ""),
					dayOfMonth: (frequency === 4 ? selectedDay : 0),
				}));
		}
		window.location.href = "./"
	}

  const hanldCancelTemplate = () => {
    setTemplateDialog(false);
  }

  const hanldCreateBlank = () => {
    setTemplateDialog(false);
  }

  const Styles: StylesConfig = {
		control: (styles, state) => ({
			...styles, backgroundColor: 'white', borderRadius: '1rem',
			boxShadow: '0 !important',
			border: '2px solid lightgray',
			'&:hover': { borderColor: 'lightgray' }
		}),
  };

  const columns = [
    {
			Header: <div style={{
				textAlign: "left", color: "#6b7280" }}>Name</div>,
      accessor: "name"
    },
    {
			Header: <div style={{ textAlign: "left", color: "#6b7280" }}>Location</div>,
      accessor: "location"
    },
    {
			Header: <div style={{ textAlign: "left", color: "#6b7280" }}>Where</div>,
      accessor: "where"
    },
    {
      Header: "", Cell: (obj)  => (
        <a href="#" onClick={(event) => editPlan(event, obj.original.id)}>
          <EditSvgIcon />
        </a>
      ), width: 70, accessor: "id" },
    {
      Header: "", Cell: (obj) => (
        <a href="#" onClick={(event) => removePlan(event, obj.original.id)}>
          <TrashSvgIcon />
        </a>
      ), width: 70, accessor: "id"
    }];

  const handleCancelClick = (event) => {
    event.preventDefault();
    setPointDialog(false);
  }

	const handleConfirmClearCancel = (event) => {
		setIsShowConfirmAlertForClearTable(false);
	}

	const closeDialog = () => {
		setShowAlert(false);
	}

  return (
    <React.Fragment>
      <FCConfirmAlert message="Are you sure you want to delete this test point?" isOpen={isShowConfirmAlert} title="Test Point"
				onOkClick={(event) => handleDeleteTestPoint(event)} onCancelClick={(event) => handleConfirmCancel(event)} />
			<FCConfirmAlert message="Are you sure you wish to clear all sample plan data?" isOpen={isShowConfirmAlertForClearTable} title="Test Point"
				onOkClick={() => handleClearPoints()} onCancelClick={(event) => handleConfirmClearCancel(event)} />
			<div className={"flex flex-col h-screen samplePlan"  + (showNav ? "  " : " mx-40 my-10")}>
				{showAlert && <FCMessageDialog isSuccess={isSuccessAlert} modalCallBack={() => closeDialog()} message={alertMessage } />}
				<div className={"pb-5 pt-5 " + (showNav ? " " : " p-10 border-4 border-gray-300 sm:rounded-lg ")}>
          <div className="w-full text-lg  text-gray-600 text-center">Sample Plan Quote Tool</div>
          {/*<button onClick={showDialog}> Show dialog </button>*/}
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 ">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="max-w-lg block focus:outline-none w-full">
								<label htmlFor="name" className="ml-px block px-2 py-1  samplePlanTextColor">
                  Frequency
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2 ">
                  <Select
										value={{ label: frequencyText, value: frequency }}
                    onChange={(event) => handleFrequencyChange(event)}
                    name="frequency"
                    options={[{ label: 'Weekly', value: 1 },
                      { label: 'Fortnightly', value: 2 },
                      { label: 'Every 4 Weeks', value: 3 },
                      { label: 'Monthly', value: 4 }]}
                    styles={Styles}
										className="basic-multi-select text-fcinputtext"
                    classNamePrefix="select"
                  />
                </div>
              </div>
              <div>
                <div className={isDayOfWeekDropDown ? "max-w-lg block focus:outline-none w-full " : "hidden"}>
									<label htmlFor="name" className="ml-px block px-2 py-1  samplePlanTextColor ">
                    Day Of Week
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Select
                      ref={ref => {
                        selectRefWeeks = ref;
											}}
											value={selectedWeekDaysOption}
                      onChange={(event) => handleWeekChange(event)}
                      maxMenuHeight={250}
                      isMulti
                      name="Week"
                      options={weekDays}
                      styles={Styles}
											className="basic-multi-select text-fcinputtext"
                      classNamePrefix="select"
                      escapeClearsValue={true}
                    />
                  </div>
                </div>
                <div className={!isDayOfWeekDropDown ? "max-w-lg block focus:outline-none w-full " : "hidden"}>
                  <label htmlFor="name" className="ml-px block px-2 py-1 ">
                    Day of Month
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
										<Select
											value={{ label: selectedDay === -1 ? "Select day" : selectedDay, value: selectedDay.toString() }}
                      ref={ref => {
                        selectRefDay = ref;
                      }}
                      onChange={(event) => handleDayChange(event)}
                      maxMenuHeight={250}
                      name="day"
                      options={days}
                      styles={Styles}
											className="basic-multi-select .selectBorder text-fcinputtext"
                      classNamePrefix="select"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <button
                  onClick={showPointDialog}
                  type="button"
									className="inline-flex  items-center py-2 border border-transparent 
											bg-transparent text-block focus:outline-none samplePlanTextColor"
                >
                  <PlusCircleSvgIcon />&nbsp;&nbsp;Add Test Point
                </button>
              </div>
              <div className="align-left">
                <ReactTable
                  data={samplePlans}
                  minRows={4}
                  noDataText={""}
                  columns={columns}
                  showPagination={false}
                  resizable={false}
                  style={{
                    textAlign: "left",
                    height: "250px" // This will force the table body to overflow and scroll, since there is not enough room
                  }}
									className="-highlight border-0 mb-1 align-left samplePlanTextColor"
									pageSize={samplePlans.length > 0 ? samplePlans.length : 0}
                />
                <div className="pb-2 pt-5 flex justify-end" style={{ borderTop:  "3px solid lightgray"}}>
                  <div>
                    <label htmlFor="email" className="block  text-gray-700">
                      Cost (per month)
                    </label>

                    <div className="mt-1 border-2 border-gray-300 p-2 rounded-2xl text-gray-500">
                                          <PoundIcon />
                                          <label htmlFor="email" className="inline-block align-middle px-5  ">
												{(totalCost * costByValue).toFixed(2)}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="pb-1 sm:flex sm:items-center justify-end">
                  {!isWeekOrDaySelected && <label
                      className="inline-flex  pl-10 text-red-500">Please select the Day of Month or Week value.</label>}
                  {!isSamplePlansEntered && <label
                    className="inline-flex  pl-10 text-red-500">Please add test points to continue.</label>}
                  <div className="mt-3 flex sm:mt-0 sm:ml-4">
                    <FCButtonWithClick onHandleClick={ clearPlans } bgColor="bg-fcbutton px-10 mr-5" type="submit" icon="" value="Clear" />
                    <FCButtonWithClick onHandleClick={ showSaveDialog } bgColor="bg-primary px-10" type="submit" icon="" value="Save" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Transition.Root show={pointDialog} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setPointDialog}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:px-10">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setPointDialog(false)}
                  >
                    <span className="sr-only">Close</span>
                    <CloseIcon />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center w-full mb-5">
										<Dialog.Title as="h3" className="text-lg leading-6  text-gray-500">
                      {addMode ? "Add Test Point" : "Edit Test Point"}
                    </Dialog.Title>
                  </div>
                </div>
                <form onSubmit={(event) => handlePointName(event)}>
                  <div>
                    <label htmlFor="textpointname" className="block   text-gray-700">
                      Test Point Name
                    </label>
                    <div className="mt-1">
											<FCInputField isBorder={true} className=" samplePlan " type="text" id="pointName"
												placeholder="" icon="" isError={isPointNameEntered} leftPadding=" "
                        onChange={handleChange} value={pointName} />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="location" className="block   text-gray-700">
                      Location
                    </label>
                    <div className="mt-1">
											<FCInputField isBorder={true} className=" samplePlan " type="text" id="location"
												placeholder="" icon="" isError={isLocationEntered} leftPadding=" "
                        onChange={handleChange} value={location} />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="where" className="block   text-gray-700">
                      Where
                    </label>
                    <div className="mt-1">
											<FCInputField isBorder={true} className=" samplePlan " type="text" id="where"
												placeholder="" icon="" isError={isWhereEntered} leftPadding=" "
                        onChange={handleChange} value={where} />
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex w-1/2 float-right">
                    <FCButtonWithClick onHandleClick={(event) => handleCancelClick(event)}
                      bgColor="bg-fcbutton mr-3" type="button" icon="" value="Cancel" />
                    <FCButton bgColor="bg-primary" type="submit" icon="" value="Save" />
                  </div>
                </form>
              </div>
              
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={templateDialog} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setTemplateDialog}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-1/2 align-bottom bg-white font-large rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:align-middle sm:px-10">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setTemplateDialog(false)}
                  >
                    <span className="sr-only">Close</span>
                    <CloseIcon />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center w-full mb-5">
                    <Dialog.Title as="h3" className="text-lg leading-6  text-gray-500">
                      Sample Plan Quote Tool
                    </Dialog.Title>
                  </div>
                </div>
                <form onSubmit={(event) => getSelectedTemplate(event)}>
                  <div className="text-center">
                    <label htmlFor="textpointname" className="block pb-10  text-gray-700">
                      Do you want to create a sample plan using a template?
                    </label>
                    <div className="w-1/2 w-full flex flex-col justify-center items-center">
                      <label htmlFor="templates" className="block  text-gray-700">
                        Select A Template
                      </label>
                      <div className="mt-1 w-1/2 mb-20">
                        <Select
                          defaultValue={templateList[0]}
                          options={templateList}
                          onChange={(event) => handleTemplateChange(event)}
                          name="templates"
                          styles={Styles}
													className="basic-multi-select text-fcinputtext"
                          classNamePrefix="select"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pb-5 sm:items-center">
                    <div className="mt-3 flex sm:mt-0">
                      <FCButtonWithClick onHandleClick={hanldCancelTemplate} bgColor="bg-fcbutton mr-3" type="button" icon="" value="Cancel" />
                      <FCButton bgColor="bg-primary mr-3" type="submit" icon="" value="Create Using Template" />
                      <FCButtonWithClick onHandleClick={hanldCreateBlank} bgColor="bg-primary" type="button" icon="" value="Create Blank Plan" />
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={saveDialog} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setSaveDialog}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
							<div className="inline-block align-bottom bg-white font-large rounded-2xl px-16 pt-4 text-left overflow-hidden transform transition-all sm:align-middle">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setSaveDialog(false)}
                  >
                    <span className="sr-only">Close</span>
                    <CloseIcon />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center w-full mb-5">
                    <Dialog.Title as="h3" className="text-lg leading-6  text-gray-500">
                      Save Sample Plan
                    </Dialog.Title>
                  </div>
								</div>
								
									<form onSubmit={(event) => savePlans(event)}>
										<div>
											<div className="w-full flex flex-col">
												<label htmlFor="templates" className="block  text-gray-700">
													Sample Plan Name
												</label>
												<div className="mt-1 mb-5">
													<FCInputField isBorder={true} className="" type="text" id="planName"
														placeholder="" icon="" isError={isPlanNameEntered} leftPadding=" "
														onChange={handleChange} value={planName} />
												</div>
											</div>
									</div>
									{isLoggedIn &&
										<div className="flex flex-col pb-5 justify-center items-center">
											<FCButton bgColor="bg-primary" type="submit" icon="" value="Save" />
										</div>
									}
									{!isLoggedIn &&
										<div className="flex flex-col pb-5 justify-center items-center">
											<p>Please login to continue.</p>
											<FCButtonWithClick onHandleClick={handleLogin} bgColor="bg-fcbutton px-10 mr-5" type="button" icon="" value="Login" />
										</div>
									}
									</form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const { sampleplanQuoteData } = state;
  return {
    sampleplanQuoteData
  };
};

export default connect(mapStateToProps)(SampePlanQuoteTool);
