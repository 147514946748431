import * as React from 'react';
import { connect } from 'react-redux';
import SampePlanQuoteTool from "../../sampleplanquote/sampleplanquote";
import "./sampleplanquote.css";

class SamplePlanQuoteToolComponent extends React.Component {
  state = {
  }
  constructor(props: any) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {
    const {  } = this.state;
    return (
      <div className="px-20">
				<SampePlanQuoteTool />
      </div>
    );
  }
}

export default connect()(SamplePlanQuoteToolComponent);