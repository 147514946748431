import {
	GET_ALL_REPORT_STARTED,
	GET_ALL_REPORT_COMPLETED,
	GET_ALL_REPORT_FAILED,

	GET_GLOBAL_COMPARISON_STARTED,
	GET_GLOBAL_COMPARISON_COMPLETED,
	GET_GLOBAL_COMPARISON_FAILED,

	GET_DASHBOARD_DATA_STARTED,
	GET_DASHBOARD_DATA_COMPLETED,
	GET_DASHBOARD_DATA_FAILED,

	GET_DOWNLOAD_REPORT_STARTED,
	GET_DOWNLOAD_REPORT_COMPLETED,
	GET_DOWNLOAD_REPORT_FAILED,

	GET_DOWNLOAD_DATA_STARTED,
	GET_DOWNLOAD_DATA_COMPLETED,
	GET_DOWNLOAD_DATA_FAILED,

	GET_TESTHISTORY_STARTED,
	GET_TESTHISTORY_COMPLETED,
	GET_TESTHISTORY_FAILED
} from '../actions/dashboard';
import { GET_TEST_POINT_FAILED } from '../actions/testpoint';

const initialState = {
	isLoading: false,
	isError: false,
	isAllReportLoaded: false,
	isTestHistoryLoaded: false,
	allReportData: [],
	dashboardInput: {},
	isGlobalComparisonLoaded: false,
	globalComparisonData: [],
	isDashboardDataLoaded: false,
	graphData: {},
	isDownloadReportLoaded: false,
	downloadReportData: [],
	isDownloadDataLoaded: false,
	downloadData: [],
	testHistoryData: [],
}

const dashboardData = (state = initialState, action: any) => {
	switch (action.type) {
		case GET_ALL_REPORT_STARTED:
			return {
				...state,
				isLoading: true,
				dashboardInput: action.input,
				isAllReportLoaded: false
			};
		case GET_ALL_REPORT_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				reportData: action.payload.result,
				isSuccess: true,
				isAllReportLoaded: true
			};
		case GET_ALL_REPORT_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true
			};

		case GET_GLOBAL_COMPARISON_STARTED:
			return {
				...state,
				isLoading: true,
				dashboardInput: action.input,
				isGlobalComparisonLoaded: false
			};
		case GET_GLOBAL_COMPARISON_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				globalComparisonData: action.payload.result,
				isSuccess: true,
				isGlobalComparisonLoaded: true
			};
		case GET_GLOBAL_COMPARISON_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true
			};

		case GET_DASHBOARD_DATA_STARTED:
			return {
				...state,
				isLoading: true,
				dashboardInput: action.input,
				isDashboardDataLoaded: false
			};
		case GET_DASHBOARD_DATA_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				graphData: action.payload.result,
				isSuccess: true,
				isDashboardDataLoaded: true
			};
		case GET_DASHBOARD_DATA_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true
			};

		case GET_DOWNLOAD_REPORT_STARTED:
			return {
				...state,
				isLoading: true,
				dashboardInput: action.input,
				isDownloadReportLoaded: false
			};
		case GET_DOWNLOAD_REPORT_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				downloadReportData: action.payload.result,
				isSuccess: true,
				isDownloadReportLoaded: true
			};
		case GET_DOWNLOAD_REPORT_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true
			};

		case GET_DOWNLOAD_DATA_STARTED:
			return {
				...state,
				isLoading: true,
				dashboardInput: action.input,
				isDownloadDataLoaded: false
			};
		case GET_DOWNLOAD_DATA_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				downloadData: action.payload.result,
				isSuccess: true,
				isDownloadDataLoaded: true
			};
		case GET_DOWNLOAD_DATA_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true
			};

		case GET_TESTHISTORY_STARTED:
			return {
				...state,
				isLoading: true,
				dashboardInput: action.input,
				isTestHistoryLoaded: false
			};
		case GET_TESTHISTORY_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				testHistoryData: action.payload.result,
				isSuccess: true,
				isTestHistoryLoaded: true
			};
		case GET_TESTHISTORY_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true
			};

		default:
			return state;
	}
}

export default dashboardData;