import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useState, useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { usePopper } from "react-popper";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "../../custom.css";
import { getUserData } from '../../utils/common/functions';
import { getGlobalComparisonData } from '../../store/actions/dashboard';

import {
	PlusCircleSvgIconTransparent, PlusCircleSvgIconOrange
} from '../../utils/common/icon-hooks.js';

interface IDashboard {
	dispatch: Dispatch<any>;
	dashboardData: any;
}

const GlobalReport: React.SFC<IDashboard> = ({
	dashboardData, dispatch }) => {
	const [dateRange1, setDateRange1] = useState([null, null]);
	const [startDate1, endDate1] = dateRange1;
	const [dateRange2, setDateRange2] = useState([null, null]);
	const [startDate2, endDate2] = dateRange2;
	const [userData, setUserData] = useState(null);
	const [isGlobalComparisonLoaded, setIsGlobalComparisonLoaded] = useState(false);
	const [pieOptions1, setPieOptions1] = useState(null);
	const [pieOptions2, setPieOptions2] = useState(null);
	const [graphNo, setGraphNo] = useState(0);
	//const [graphData1, setGraphData1] = useState(null);
	//const [graphData2, setGraphData2] = useState(null);
	const pieChartComponentRef1 = useRef<HighchartsReact.RefObject>(null);
	const pieChartComponentRef2 = useRef<HighchartsReact.RefObject>(null);
	
	const refreshGraph1 = (data) => {
		//Pie Chart
		setPieOptions1({
			title: {
				text: ''
			},
			dataLabels: {
				enabled: true
			},
			colors: ['#26a4ca', '#ffcc00', '#f70000'],
			series: [{
				type: 'pie',
				dataLabels: {
					enabled: false
				},
				showInLegend: true,
				data: [{ name: 'Pass', y: data?.pass }, { name: 'Warning', y: data?.warning }, { name: 'Fail', y: data?.fail }]
			}]
		});
	}
	const refreshGraph2 = (data) => {
		//Pie Chart
		setPieOptions2({
			title: {
				text: ''
			},
			dataLabels: {
				enabled: true
			},
			colors: ['#26a4ca', '#ffcc00', '#f70000'],
			series: [{
				type: 'pie',
				dataLabels: {
					enabled: false
				},
				showInLegend: true,
				data: [{ name: 'Pass', y: data?.pass }, { name: 'Warning', y: data?.warning }, { name: 'Fail', y: data?.fail }]
			}]
		});
	}

	React.useEffect(() => {
		var usrData = getUserData();
		setUserData(usrData);

		setDateRange1([
			new Date(`${moment().format('LL')} 00:00:00`),
			new Date(`${moment().add(7, 'days').format('LL')} 23:59:59`)
		]);
		setDateRange2([
			new Date(`${moment().format('LL')} 00:00:00`),
			new Date(`${moment().add(7, 'days').format('LL')} 23:59:59`)
		]);

		if (usrData.clientId !== 1 && usrData.roleId !== 1)
		{
			//GetSitesByClient
			//dispatch(getGlobalComparisonData({ userId: usrData.id, userToken: usrData.userToken, clientId: usrData.clientId }));
		}

		refreshGraph1({ pass: 0, warning: 0, fail: 0 });
		refreshGraph2({ pass: 0, warning: 0, fail: 0 });
	}, []);

	const getGraphData = (graphNo) => {
		setGraphNo(graphNo);
		setIsGlobalComparisonLoaded(false);
		dispatch(getGlobalComparisonData({
			userId: userData.id,
			userToken: userData.userToken,
			dateFrom: graphNo === 1 ? moment(startDate1, 'yyyy-mm-dd') : moment(startDate2, 'yyyy-mm-dd'),
			dateTo: graphNo === 1 ? moment(endDate1, 'yyyy-mm-dd'): moment(endDate2, 'yyyy-mm-dd')
		}));
	}

	//Graph Data
	if (dashboardData) {
		if (dashboardData.isGlobalComparisonLoaded && !isGlobalComparisonLoaded) {
			dashboardData.isDashboardDataLoaded = false;
			setIsGlobalComparisonLoaded(true);
			//graphNo === 1 ? setGraphData1(dashboardData.graphData) : setGraphData2(dashboardData.graphData);
			graphNo === 1 ? refreshGraph1(dashboardData.globalComparisonData) : refreshGraph2(dashboardData.globalComparisonData);
		}
	}

	const onDateChange1 = (dates) => {
		setDateRange1([
			dates[0] ? new Date(`${moment(dates[0]).format('LL')} 00:00:00`) : dates[0],
			dates[1] ? new Date(`${moment(dates[1]).format('LL')} 23:59:59`) : dates[1]
		]);
	}

	const onDateChange2 = (dates) => {
		setDateRange2([
			dates[0] ? new Date(`${moment(dates[0]).format('LL')} 00:00:00`) : dates[0],
			dates[1] ? new Date(`${moment(dates[1]).format('LL')} 23:59:59`) : dates[1]
		]);
	}

	const handleCalendarClose = (value) => {
		getGraphData(value);
	}
	const handlePopup = (event) => {
		event.preventDefault();
		alert("popup");
	}

	return (
		<>
			<div className="max-w-7xl mt-10">
				<div className="grid mt-10">
					<div className="grid grid-cols-2 h-80 mt-10">
						<div className="flex flex-inline justify-center">
							<div className="w-3/4">
								<div className="w-full">
									<div className="pl-1 text-fclabel">
										Select Date Range
									</div>
									<DatePicker
										dateFormat={'dd/MM/yyyy'}
										selectsRange={true}
										startDate={startDate1}
										endDate={endDate1}
										onChange={(value) => onDateChange1(value)}
										onCalendarClose={() => handleCalendarClose(1)}
										className="border-2 border-gray-300 p-2 rounded-2xl ml-1 blueText w-4/5"
									/>
								</div>
								<div className="w-full">
									<HighchartsReact
										containerProps={{ style: { height: "100%" } }}
										highcharts={Highcharts}
										options={pieOptions1}
										ref={pieChartComponentRef1}
									/>
								</div>
							</div>
						</div>
						<div className="flex flex-inline justify-center">
							<div className="w-3/4">
								<div className="w-full">
									<div className="pl-1 text-fclabel">
										Select Date Range
									</div>
									<DatePicker
										dateFormat={'dd/MM/yyyy'}
										selectsRange={true}
										startDate={startDate2}
										endDate={endDate2}
										onCalendarClose={() => handleCalendarClose(2)}
										onChange={(value) => onDateChange2(value)}
										className="border-2 border-gray-300 p-2 rounded-2xl ml-1 blueText w-4/5"
									/>
								</div>
								<div className="w-full">
									<HighchartsReact
										containerProps={{ style: { height: "100%" } }}
										highcharts={Highcharts}
										options={pieOptions2}
										ref={pieChartComponentRef2}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state: any) => {
	const { dashboardData, siteData, usersData, sampleplanData } = state;
	return {
		dashboardData, siteData, usersData, sampleplanData
	};
};

export default connect(mapStateToProps)(GlobalReport);

