export const GET_USERPROFILE_STARTED = "GET_USERPROFILE_STARTED";
export const GET_USERPROFILE_COMPLETED = "GET_USERPROFILE_COMPLETED";
export const GET_USERPROFILE_FAILED = "GET_USERPROFILE_FAILED";

export const UPDATE_USERPROFILE_STARTED = "UPDATE_USERPROFILE_STARTED";
export const UPDATE_USERPROFILE_COMPLETED = "UPDATE_USERPROFILE_COMPLETED";
export const UPDATE_USERPROFILE_FAILED = "UPDATE_USERPROFILE_FAILED";

export const CHANGEPASSWORD_STARTED = "CHANGEPASSWORD_STARTED";
export const CHANGEPASSWORD_COMPLETED = "CHANGEPASSWORD_COMPLETED";
export const CHANGEPASSWORD_FAILED = "CHANGEPASSWORD_FAILED";

export const CHANGEAVATAR_STARTED = "CHANGEAVATAR_STARTED";
export const CHANGEAVATAR_COMPLETED = "CHANGEAVATAR_COMPLETED";
export const CHANGEAVATAR_FAILED = "CHANGEAVATAR_FAILED";

export const getUserProfile = (input: any) => {
    return {
        type: GET_USERPROFILE_STARTED,
        input: input
    };
};

export const updateUserProfile = (input: any) => {
  return {
    type: UPDATE_USERPROFILE_STARTED,
    input: input
  };
};

export const changePassword = (input: any) => {
  return {
    type: CHANGEPASSWORD_STARTED,
    input: input
  };
};

export const updateAvatar = (input: any) => {
  return {
    type: CHANGEAVATAR_STARTED,
    input: input
  };
};
