import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  SearchSvgIcon, CloseSearchIcon
} from '../../utils/common/icon-hooks.js';

const FCInputSearchField = ({ value, placeholder, onChange, id, className, isClearSearch=false}) => {

  const [inputValue, setInputValue] = useState(value);

	const handleChange = (event, isClear) => {
		event.preventDefault();
   var txtValue = isClear ? '' : event.target.value;
	  setInputValue(txtValue);
	  onChange(txtValue);
  };

  const clearSearch = (event) => {
    event.preventDefault();
    setInputValue('');
    handleChange(event, true);
  }
	return (
		<>
			<form onSubmit={e => { e.preventDefault(); }} role="search" className="relative inline-flex items-center  w-full">
        <input
					aria-label="Search"
					name={id}
					id={id}
					className={"flex-auto -mr-9 samplePlan  appearance-none border-2 rounded-2xl border-gray-300 bg-transparent pl-4 pr-12 py-2 "
						+ " text-fcinputtext text-base placeholder-gray-500 focus:outline-none "
						+ (className !== '' ? className : " ")}
					placeholder={placeholder}
					onChange={(event) => handleChange(event, false)}
					value={((isClearSearch && isClearSearch == true) ? '' :  inputValue)}
				/>
        {value !== '' && <a href='#' onClick={(event) => clearSearch(event)}> <CloseSearchIcon /></a>}
        {value === '' && <SearchSvgIcon />}
        
      </form>
    </>
  )
};

FCInputSearchField.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  className: PropTypes.string
};

FCInputSearchField.defaultProps = {
  value: '',
  placeholder: 'Search'
};

export default FCInputSearchField;