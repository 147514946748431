import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  UserSvgIcon,
  LockSvgIcon,
  CheveronCircleRightSvgIcon,
  ClientsSvgIconLogin,
  EnvelopSvgIcon
} from '../../utils/common/icon-hooks.js';

const FCInputField = ({ value, placeholder, onChange, id, type, icon, isError,isBorder, className, leftPadding }) => {

  const handleChange = (event) => {
    onChange(event);
	};

  return (
    <>
        <div className={"relative rounded-full p-3 mb-5"
          + (isError ? " border-2 border-red-300 " : (isBorder ? " border-2 gray-white " : " border-2 border-white "))
          + (className !== '' ? className : " ")
          + (isBorder ? "" : "")}>
          {icon !== '' && <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {(icon == "lock") && <ClientsSvgIconLogin />}
            {(icon == "user") && <UserSvgIcon />}
            {(icon == "pwd") && <LockSvgIcon />}
            {(icon == "email") && <EnvelopSvgIcon />}
          </div>}
          <input
            type={type}
            name={id}
            id={id}
					className={"block w-full text-fcinputtext placeholder-gray-300 outline-none "
						+ (className !== '' ? className : " ")
						+ (leftPadding === '' ? " pl-10 " : " ")}
            placeholder={placeholder}
            onChange={handleChange}
            value={value}
          />
        </div>
    </>
  )
};

FCInputField.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.any,
  isError: PropTypes.bool,
  className: PropTypes.string,
  isBorder: PropTypes.bool
};

FCInputField.defaultProps = {
  value: '',
  placeholder: 'text',
	type: 'text',
	leftPadding: ''
};

export default FCInputField;