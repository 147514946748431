import * as React from 'react';
import { Fragment, useState } from 'react'
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react'
import {
	TickIcon, CloseIcon, CACloseIcon
} from '../../utils/common/icon-hooks.js';

const FCMessageDialog = ({ isSuccess, modalCallBack, message }) => {
  const [show, setShow] = useState(true);

  const handleClick = (event) => {
    event.preventDefault();
    setShow(false);
    modalCallBack(false);
  }

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={"max-w-sm w-full bg-white border-2 rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
						  + (isSuccess ? " border-green-400 " : " border-red-500 ")}>
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
						{isSuccess ? <TickIcon /> : <CACloseIcon />}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    {isSuccess && <p className=" text-gray-900 text-primary">
                      {message}
                    </p>}
                    {!isSuccess && <p className=" text-gray-900 text-red-500">
                      {message !== '' && message}
                      {message === '' && <> Error occured </>}
                    </p>}
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={(event) => handleClick(event)}
                    >
                      <span className="sr-only">Close</span>
                      <CloseIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

FCMessageDialog.propTypes = {
  isSuccess: PropTypes.bool,
  modalCallBack: PropTypes.func,
  message: PropTypes.string
};

FCMessageDialog.defaultProps = {
  isSuccess: true,
  message: ""
};

export default FCMessageDialog;