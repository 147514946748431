export const GETALLSAMPLEPLANS_SARTED = "GETALLSAMPLEPLANS_STARTED";
export const GETALLSAMPLEPLANS_COMPLETED = "GETALLSAMPLEPLANS_COMPLETED";
export const GETALLSAMPLEPLANS_FAILED = "GETALLSAMPLEPLANS_FAILED";

export const GETALLTESTPOINTS_SARTED = "GETALLTESTPOINTS_STARTED";
export const GETALLTESTPOINTS_COMPLETED = "GETALLTESTPOINTS__COMPLETED";
export const GETALLTESTPOINTS_FAILED = "GETALLTESTPOINTS_FAILED";

export const SAVESAMPLEPLAN_STARTED = "SAVESAMPLEPLAN_STARTED";
export const SAVESAMPLEPLAN_COMPLETED = "SAVESAMPLEPLAN_COMPLETED";
export const SAVESAMPLEPLAN_FAILED = "SAVESAMPLEPLAN_FAILED";

export const DELETESAMPLEPLAN_STARTED = "DELETESAMPLEPLAN_STARTED";
export const DELETESAMPLEPLAN_COMPLETED = "DELETESAMPLEPLAN_COMPLETED";
export const DELETESAMPLEPLAN_FAILED = "DELETESAMPLEPLAN_FAILED";

export const IMPORT_SAMPLEPLAN_STARTED = "IMPORT_SAMPLEPLAN_STARTED";
export const IMPORT_SAMPLEPLAN_COMPLETED = "IMPORT_SAMPLEPLAN_COMPLETED";
export const IMPORT_SAMPLEPLAN_FAILED = "IMPORT_SAMPLEPLAN_FAILED";

export const SELECT_SAMPLEPLAN = "SELECT_SAMPLEPLAN";

export const getAllSamplePlans = (input: any) => {
	return {
		type: GETALLSAMPLEPLANS_SARTED,
		input: input
	};
};

export const getAllTestPoints = (input: any) => {
	return {
		type: GETALLTESTPOINTS_SARTED,
		input: input
	};
};

export const saveSamplePlan = (input: any) => {
	return {
		type: SAVESAMPLEPLAN_STARTED,
		input: input
	};
};

export const deleteSamplePlan = (input: any) => {
	return {
		type: DELETESAMPLEPLAN_STARTED,
		input: input
	};
};

export const importSamplePlan = (input: any) => {
	return {
		type: IMPORT_SAMPLEPLAN_STARTED,
		input: input
	};
};

export const selectSamplePlan = (input: any) => {
	return {
		type: SELECT_SAMPLEPLAN,
		input: input
	};
};
