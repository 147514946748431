import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useState } from 'react'
import { Dispatch } from 'redux';
import { user } from '../../utils/models/model';
import FCSiteInputField from "../../utils/library/sitetextbox";
import FCButtonWithClick from '../../utils/library/buttonwithclick';
import { Validators, validateInput } from '../../utils/library/Validator';
import FCConfirmAlert from '../../utils/library/confirmalert';
import Select, { StylesConfig } from 'react-select';
import { TrashSvgIcon } from "../../utils/common/icon-hooks";
import { getUserData, getBase64 } from '../../utils/common/functions';
import { getSingleUserWithImg, postUserDelete, addOrUpdateUser, updateUserAvatar, getUserRoles } from '../../store/actions/user';
import { getClients } from '../../store/actions/clients';
import { getSitesByClient } from '../../store/actions/site';
import { addDefaultSrc } from '../../utils/common/functions'

interface IUserDetailsProps {
	dispatch: Dispatch<any>;
	usersData: any;
	userId: number;
	seeAlert: any;
	clientData: any;
	siteData: any;
}

const UserDetails: React.SFC<IUserDetailsProps> = ({
	dispatch, usersData, userId, seeAlert, clientData, siteData }) => {

	const history = useHistory();
	const [selectedUser, setSelectedUser] = useState(user);
	const [isShowConfirmAlert, setIsShowConfirmAlert] = useState(false);
	const [userData, setUserData] = useState(null);
	const [selectedUserId, setSelectedUserId] = useState(-1);
	const [userDisplayed, setUserDisplayed] = useState(false);
	const [isUserAdded, setIsUserAdded] = useState(false);
	const [userDeleteSuccess, isUserDeleteSuccess] = useState(false);
	const [usernameReadonly, setUsernameReadonly] = useState(false);
	const [clientList, setClientList] = useState([{ label: '', value: 0 }]);
	const [clientsAdded, setClientsAdded] = useState(false);
	const [selectedClient, setSelectedClient] = useState({ label: '', value: 0 });
	const [siteList, setSiteList] = useState([{ label: '', value: 0 }]);
	const [isSitesLoaded, setIsSitesLoaded] = useState(false);
	const [selectedSite, setSelectedSite] = useState({ label: '', value: 0 });
	const [isUserNameEntered, setIsUserNameEntered] = useState(false);
	const [isEmailEntered, setIsEmailEntered] = useState(false);
	const [isPasswordEntered, setIsPasswordEntered] = useState(false);
	const [isValidUpdatedPassword, setIsValidUpdatedPassword] = useState(false);
	const [isFirstNameEntered, setIsFirstNameEntered] = useState(false);
	const [isClientEntered, setIsClientEntered] = useState(false);
	const [isSitesEntered, setIsSitesEntered] = useState(false);
	const [isRoleEntered, setIsRoleEntered] = useState(false);
	const [isRequiredError, setIsRequiredError] = useState(false);
	const [fileSelector, setFileSelector] = useState(null);
	const [base64, setBase64] = useState('');
	const [isAvatarUpdated, setIsAvatarUpdated] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [isUserRolesAdded, setIsUserRolesAdded] = useState(false);
	const [userRolesList, setUserRolesList] = useState([{ label: '', value: 0 }]);
	const [selectedRole, setSelectedRole] = useState({ label: '', value: 0 });
	const [isValidPassword, setIsValidPassword] = useState(false);

	const buildFileSelector = () => {
		const fileSelector = document.createElement('input');
		fileSelector.setAttribute('type', 'file');
		return fileSelector;
	}

	React.useEffect(() => {
		var usrData = getUserData();
		setUserData(usrData);
		setFileSelector(buildFileSelector);
		if (userId > 0) {
			dispatch(getSingleUserWithImg({ userId: usrData.id, userToken: usrData.userToken, id: userId }));
			setUsernameReadonly(true);
		}
		else {
			setUserDisplayed(true);
		}
		dispatch(getClients({ userId: usrData.id, userToken: usrData.userToken }));

		if (usrData && usrData.clientId == 1) {
			setIsAdmin(true);
		}

	}, []);

	React.useEffect(() => {
		if (usersData.user && usersData.userRolesResult.length) {
			let items = [{ label: '', value: 0 }];
			items.splice(0, 1);
			var selectedRoleName = "";
			usersData.userRolesResult.forEach(function (item) {
				if (item.id == selectedUser.roleId) {
					selectedRoleName = item.name;
				}
				var obj = { label: item.name, value: item.id };
				items.push(obj);
			});
			setUserRolesList([]);
			setUserRolesList(items);
			setSelectedRole({ label: selectedRoleName, value: selectedUser.roleId });
		}
	}, [usersData.user, usersData.userRolesResult])

	const removeClient = (event) => {
		if (userId > 0) {
			event.preventDefault();
			setSelectedUserId(userId);
			setIsShowConfirmAlert(true);
		}
	}

	React.useEffect(() => {
		if (clientData && clientData.status) {
			if (clientData.isClientsLoaded && userDisplayed && !clientsAdded) {
				setClientsAdded(true);
				let items = [{ label: '', value: 0 }];
				items.splice(0, 1);
				var selectedClientName = "";
				clientData.clientsResult.forEach(function (item) {

					if (item.id == selectedUser.clientId) {
						selectedClientName = item.name;
					}

					var obj = { label: item.name, value: item.id };
					items.push(obj);
				});
				setClientList([]);
				setClientList(items);
				setSelectedClient({ label: selectedClientName, value: selectedUser.clientId });
				if (userData) {
					if (!isAdmin && userId == 0) {
						getSite(userData.clientId);
					} else {
						getSite(selectedUser.clientId);
					}
					dispatch(getUserRoles({ userId: userData.id, userToken: userData.userToken }));
				}
			}
		}
	}, [userData, clientData])

	React.useEffect(() => {
		if (userId > 0 && usersData.isUserLoaded) {
			setSelectedUser(usersData.user);
			setUserDisplayed(true);
		}
	}, [usersData.user])

	function getSite(usrClientId) {
		/*if (isAdmin) {
			usrClientId = userData.clientId;
		}*/
		dispatch(getSitesByClient({ userId: userData.id, userToken: userData.userToken, clientId: usrClientId }));

	}

	if (siteData) {
		if (siteData.isSitesLoaded && !isSitesLoaded) {
			let items = [{ label: '', value: 0 }];
			items.splice(0, 1);
			var selectedSiteName = '';
			siteData.sites.forEach(function (item) {
				var obj = { label: item.name, value: item.id };
				items.push(obj);
				if (item.id == selectedUser.siteId) {
					selectedSiteName = item.name;
				}
			});

			setSiteList([]);
			setSiteList(items);
			setSelectedSite({ label: selectedSiteName, value: selectedUser.siteId });
			setIsSitesLoaded(true);
			siteData.isSitesLoaded = false;
		}
	}

	if (usersData && usersData.status) {
		if (usersData.isUserAdded && !isUserAdded) {
			if (usersData.status.statusValue) {
				window.localStorage.setItem('alertMessage', "Successfully saved.");
				history.push('/users');
			}
			else {
				seeAlert(true, false, usersData.status.statusDisplay);
			}
			setIsUserAdded(true);
		}
		if (usersData.isUserDeleted && !userDeleteSuccess) {
			if (usersData.status.statusValue) {
				window.localStorage.setItem('alertMessage', "Successfully deleted.");
				history.push('/users');
			} else {
				seeAlert(true, false, "Error occured..");
			}
			isUserDeleteSuccess(true);
		}

		if (usersData.isAvatarUpdated && !isAvatarUpdated) {
			setFileSelector(buildFileSelector);
			seeAlert(true, usersData.status.statusValue, usersData.status.statusDisplay);
			setIsAvatarUpdated(true);
		}
		if (usersData.isUserRolesLoaded && !isUserRolesAdded) {
			setIsUserRolesAdded(true);
			let items = [{ label: '', value: 0 }];
			items.splice(0, 1);
			var selectedRoleName = "";
			usersData.userRolesResult.forEach(function (item) {
				if (item.id == selectedUser.roleId) {
					selectedRoleName = item.name;
				}
				var obj = { label: item.name, value: item.id };
				items.push(obj);
			});
			setUserRolesList([]);
			setUserRolesList(items);
			setSelectedRole({ label: selectedRoleName, value: selectedUser.roleId });
		}
	}

	const validators =
		[{
			check: Validators.required,
			message: 'Company name is required.'
		}];

	const pwdValidators =
		[{
			check: Validators.password,
			message: 'Please enter valid password.'
		}];

	const onInputChange = (event) => {
		if (event.name === 'userName') {
			setIsUserNameEntered(validateInput(validators, event.value));
		}
		if (event.name === 'email') {
			setIsEmailEntered(validateInput(validators, event.value));
		}
		if (event.name === 'password') {
			setIsPasswordEntered(validateInput(validators, event.value));
			setIsValidPassword(validateInput(pwdValidators, event.value));
		}
		if (event.name === 'updatedPassword') {
			setIsValidUpdatedPassword(validateInput(pwdValidators, event.value));
		}
		if (event.name === 'firstName') {
			setIsFirstNameEntered(validateInput(validators, event.value));
		}
		setSelectedUser(prevState => ({
			...prevState,
			[event.name]: event.value
		}));
	}

	const handleRoleChange = (event) => {
		setSelectedUser(prevState => ({
			...prevState,
			roleId: event.value
		}));
		setSelectedRole(event);
	}

	const handleClientChange = (event) => {
		setSelectedUser(prevState => ({
			...prevState,
			clientId: event.value
		}));
		setSelectedClient(event);
		dispatch(getSitesByClient({ userId: userData.id, userToken: userData.userToken, clientId: event.value }));
		setIsSitesLoaded(false);
		if (event.name === 'client') {
			setIsClientEntered(false);
		}
	}

	const handleSiteChange = (event) => {
		setSelectedUser(prevState => ({
			...prevState,
			siteId: event.value
		}));
		setSelectedSite(event);
		if (event.name === 'sites') {
			setIsSitesEntered(false);
		}
	}

	const onCancel = (event) => {
		history.push('/users');
	}

	const handleConfirmCancel = (event) => {
		setIsShowConfirmAlert(false);
		setIsRequiredError(false);
	}

	const handleDeleteUser = (event) => {
		event.preventDefault();
		seeAlert(false, false, "");
		userData && dispatch(postUserDelete({ userId: userData.id, userToken: userData.userToken, id: selectedUserId }));
		setIsShowConfirmAlert(false);
	}

	const saveUser = () => {
		seeAlert(false, false, "");
		setIsUserNameEntered(validateInput(validators, selectedUser.userName));
		setIsEmailEntered(validateInput(validators, selectedUser.email));
		setIsFirstNameEntered(validateInput(validators, selectedUser.firstName));
		setIsRoleEntered(validateInput(validators, selectedUser.roleId));
		setIsPasswordEntered(validateInput(validators, selectedUser.password));
		setIsSitesEntered(validateInput(validators, selectedUser.siteId));
		if (selectedUser.userId === 0) {
			setIsValidPassword(validateInput(pwdValidators, selectedUser.password));
			if (isValidPassword) { return; }
		}
		if (selectedUser.updatedPassword && selectedUser.updatedPassword !== '') {
			setIsValidUpdatedPassword(validateInput(pwdValidators, selectedUser.siteId));
			if (isValidUpdatedPassword) { return; }
		}

		setIsValidUpdatedPassword(false);
		if (isAdmin) {
			setIsClientEntered(validateInput(validators, selectedUser.clientId));
		}

		if (validateNullOrUndefined()) {
			setIsUserAdded(false);
			setIsRequiredError(false);
			if (!isAdmin && userId == 0) {
				selectedUser.clientId = userData.clientId;
			}

			selectedUser.userId = userData.id;
			selectedUser.userToken = userData.userToken;
			dispatch(addOrUpdateUser(selectedUser));
		}
		else {
			setIsRequiredError(true);
		}
	}

	const validateNullOrUndefined = () => {
		if ((typeof (selectedUser.userName) !== "undefined" && selectedUser.userName) &&
			(typeof (selectedUser.password) !== "undefined" && selectedUser.password) &&
			(typeof (selectedUser.email) !== "undefined" && selectedUser.email) &&
			(typeof (selectedUser.firstName) !== "undefined" && selectedUser.firstName) &&
			((selectedUser.roleId) != 0)) {
			if (isAdmin) {
				if ((selectedUser.clientId == 0) || (selectedUser.siteId == 0)) {
					return false;
				}
			}
			return true;
		}
		else {
			return false;
		}
	}

	const handleFileSelect = (event) => {
		event.preventDefault();
		seeAlert(false, false, "");
		fileSelector.click();
		fileSelector.onchange = function (e) {
			if (e.currentTarget.files) {
				getBase64(e.currentTarget.files[0], (result) => {
					setIsAvatarUpdated(false);
					var imagePlainText = result.replace(/^data:image\/[a-z]+;base64,/, "");
					if (userId > 0) {
						//dispatch to save..
						dispatch(updateUserAvatar({ userId: userData.id, userToken: userData.userToken, imageUrl: imagePlainText, updateUserId: userId }));
					} else {
						setSelectedUser(prevState => ({
							...prevState,
							imageUrl: imagePlainText
						}));
					}
					setBase64(result);
				});
			}
		};
	}

	return (
		<React.Fragment>
			<FCConfirmAlert message="Are you sure you want to delete this user?" isOpen={isShowConfirmAlert} title="Delete User"
				onOkClick={(event) => handleDeleteUser(event)} onCancelClick={(event) => handleConfirmCancel(event)} />
			<div className="py-3 ">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
					<h1 className="text-lg font-bold text-gray-500 text-center">User Management</h1>
				</div>

				<div className="mt-12 w-1/2 text-lg  text-gray-600 text-center mx-auto">
					<img
						className="inline-block object-cover h-12 w-12 rounded-full"
						src={base64 == '' ?
							selectedUser.imageUrl
							: base64}
						onError={addDefaultSrc}
						alt=""
					/>
				</div>
				<div className="mt-4 w-1/2 text-lg text-gray-600 text-center underline mx-auto">
					<a href='#' onClick={(event) => handleFileSelect(event)}>Change Profile</a>
				</div>

				<div className="mt-4 py-4 w-1/2 overflow-hidden h-1/2 mx-auto">
					<div className="mb-5 w-3/4 flex flex-row mx-auto">
						<div className="w-1/2 flex-row mr-3">
							<FCSiteInputField required={true} id="userName" onChange={(event) => onInputChange(event.target)} labelText="Username"
								value={selectedUser.userName} isError={isUserNameEntered} readOnly={usernameReadonly} />
						</div>
						<div className="w-1/2 flex-row">
							<Select
								name="role"
								options={userRolesList}
								onChange={(event) => handleRoleChange(event)}
								value={selectedRole}
								styles={{
									option: provided => ({
										...provided,
										color: '#002366'
									}),
									control: (provided, state) => ({
										...provided,
										boxShadow: "none",
										border: "none",
										backgroundColor: "none"
									}),
									singleValue: (provided) => ({
										...provided,
										color: '#002366'
									})
								}}
								className={"basic-multi-select border-2 rounded-2xl text-fcinputtext " + (isRoleEntered ? " border-red-300 " : " border-gray-300 ")}
								classNamePrefix="select"
							/>
							<label htmlFor="client" className="flex flex-row text-gray-500">Role</label>
						</div>
					</div>

					<div className="mb-3 w-3/4 mx-auto">
						{userId == 0 && <FCSiteInputField id="password" type="password" onChange={(event) => onInputChange(event.target)} labelText="Password"
							isError={isPasswordEntered || isValidPassword} value={selectedUser.password} />}
						{userId > 0 && <FCSiteInputField id="updatedPassword" type="password" onChange={(event) => onInputChange(event.target)} labelText="Password"
							isError={isValidUpdatedPassword} value={selectedUser.updatedPassword} placeholder="*****" />}
					</div>

					<div className="mb-3 w-3/4 mx-auto">
						<FCSiteInputField required={true} id="email" onChange={(event) => onInputChange(event.target)} labelText="Email"
							value={selectedUser.email} isError={isEmailEntered} />
					</div>

					<div className="mb-5 w-3/4 flex flex-row mx-auto">
						<div className="w-1/2 flex-row mr-3">
							<FCSiteInputField required={true} id="firstName" onChange={(event) => onInputChange(event.target)} labelText="First Name"
								isError={isFirstNameEntered} value={selectedUser.firstName} />
						</div>
						<div className="w-1/2 flex-row ">
							<FCSiteInputField required={true} id="lastName" onChange={(event) => onInputChange(event.target)} labelText="Last Name"
								value={selectedUser.lastName} />
						</div>
					</div>

					<div className="mb-3 w-3/4 mx-auto">
						{isAdmin && <Select
							name="client"
							options={clientList}
							onChange={(event) => handleClientChange(event)}
							value={selectedClient}
							styles={{
								option: provided => ({
									...provided,
									color: '#002366'
								}),
								control: (provided, state) => ({
									...provided,
									boxShadow: "none",
									border: "none",
									backgroundColor: "none"
								}),
								singleValue: (provided) => ({
									...provided,
									color: '#002366'
								})
							}}
							className={"basic-multi-select border-2 rounded-2xl text-fcinputtext " + (isClientEntered ? " border-red-300 " : " border-gray-300 ")}
							classNamePrefix="select"
						/>
						}
						{isAdmin && <label htmlFor="client" className="flex flex-row text-gray-500">Client</label>}
					</div>

					<div className="mb-3 w-3/4 mx-auto">
						<Select
							defaultValue={selectedSite}
							name="sites"
							options={siteList}
							onChange={(event) => handleSiteChange(event)}
							value={selectedSite}
							styles={{
								option: provided => ({
									...provided,
									color: '#002366'
								}),
								control: (provided, state) => ({
									...provided,
									boxShadow: "none",
									border: "none",
									backgroundColor: "none"
								}),
								singleValue: (provided) => ({
									...provided,
									color: '#002366'
								})
							}}
							className={"basic-multi-select border-2 rounded-2xl text-fcinputtext " + (isSitesEntered ? " border-red-300 " : " border-gray-300 ")}
							classNamePrefix="select"
						/>
						<label htmlFor="sites" className="flex flex-row text-gray-500">Sites</label>

					</div>


					<div className="mt-1 w-3/4 flex mx-auto">
						<FCButtonWithClick onHandleClick={onCancel} bgColor="bg-fcbutton mr-3" type="button" icon="" value="Cancel" />
						<FCButtonWithClick onHandleClick={saveUser} bgColor="bg-primary" type="button" icon="" value="Save" />
						{userId > 0 &&
							<button
								type="button"
								onClick={(event) => removeClient(event)}
								className={"inline-flex items-center px-6 py-3 border-transparent text-base " +
									"  rounded-full text-white  focus:outline-none " +
									" w-1/3 justify-between "}>
								<TrashSvgIcon />
							</button>
						}
					</div>
					<div className="text-red-500  text-center">
						{isRequiredError && <p>Please enter the required fields</p>}
						{((selectedUser.updatedPassword != '' && isValidUpdatedPassword) || (isValidPassword))
							&& <p>Password should contain 8 characters with, 1 upper, 1 lower and 1 number.</p>}
					</div>
				</div>
			</div>
		</React.Fragment >
	);
};

const mapStateToProps = (state: any) => {
	const { usersData, clientData, siteData } = state;
	return {
		usersData, clientData, siteData
	};
};

export default connect(mapStateToProps)(UserDetails);
