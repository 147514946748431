import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Fragment, useState, useRef } from 'react';
import moment from 'moment';
import ReactTable from "react-table-6";
import { Dialog, Transition } from '@headlessui/react'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "../../custom.css";
import ToggleSwitch from '../../utils/library/toggleSwitch';
import { usePopper } from "react-popper";
import { getUserData } from '../../utils/common/functions';
import { getSitesByClient } from '../../store/actions/site';
import { getTestHistory } from '../../store/actions/dashboard';
import { getAllTestPoint } from '../../store/actions/testpoint';
import { getDashboardData, getAllReport, getDownloadData, getDownloadReport } from '../../store/actions/dashboard';
import FCInputSearchField from '../../utils/library/textboxsearch';
import FCButtonWithClick from '../../utils/library/buttonwithclick';
import FCButton from '../../utils/library/button';
import FCSelectSiteField from '../../utils/library/selectsitefield';
import FCConfirmAlert from '../../utils/library/confirmalert';

import {
	CameraSvgIcon, CACloseIcon, CheckIcon, CloseIcon, PassIcon, WarningIcon, FailIcon,
} from '../../utils/common/icon-hooks.js';
import {
	PlusCircleSvgIconTransparent, DotVerticalSvgSvgIcon
} from '../../utils/common/icon-hooks.js';

interface IDashboard {
	dispatch: Dispatch<any>;
	dashboardData: any;
	siteData: any;
	usersData: any;
	sampleplanData: any;
	testPointData: any;
}

const TestHistory: React.SFC<IDashboard> = ({
	dashboardData, usersData, dispatch, siteData, sampleplanData, testPointData }) => {

	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;

	const [open, setOpen] = useState(false);
	const cancelButtonRef = useRef(null);

	const [userData, setUserData] = useState(null);
	const [isSitesLoaded, setIsSitesLoaded] = useState(false);
	const [siteOptions, setSiteOptions] = useState([]);
	const [selectedSiteId, setSelectedSiteId] = useState(0);
	const [selectedSite, setSelectedSite] = useState({ label: '', value: 0 });
	const [testResultImageUrl, setTestResultImageUrl] = useState(String);
	const [isTestHistoryLoaded, setIsTestHistoryLoaded] = useState(false);
	const [testHistoryData, setTestHistoryData] = useState([]);
	const [filteredTestHistory, setFilteredTestHitory] = useState([]);
	const [searchText, setSearchText] = useState(String);
	const [isClearSearch, setIsClearSearch] = useState(false);

	const [testResultToggle, setTestResultToggle] = useState(true);
	const [FilterDialog, setFilterDialog] = useState(false);
	const [userOptions, setUserOptions] = useState([]);
	const [isAllUsersLoaded, setIsAllUsersLoaded] = useState(false);
	const [allUsers, setAllUsers] = useState([]);
	const [samplePlanOptions, setSamplePlanOptions] = useState([]);
	const [isSamplePlansLoaded, setIsSamplePlansLoaded] = useState(false);
	const [isTestPointLoaded, setIsTestPointLoaded] = useState(false);
	const [testPointOptions, setTestPointOptions] = useState([]);
	const [selectedUser, setSelectedUser] = useState({ label: '', value: 0 });
	const [selectedUserId, setSelectedUserId] = useState(0);
	const [selectedSamplePlanId, setSelectedSamplePlanId] = useState(0);
	const [selectedSamplePlan, setSelectedSamplePlan] = useState({ label: '', value: 0 });
	const [selectedTestPointId, setSelectedTestPointId] = useState(0);
	const [selectedTestPoint, setSelectedTestPoint] = useState({ label: '', value: 0 });
	const [isShowConfirmAlertCancelFilter, setIsShowConfirmAlertCancelFilter] = useState(false);
	const [isShowConfirmAlertClearFilter, setIsShowConfirmAlertClearFilter] = useState(false);

	const [beforeCancelSamplePlanId, setBeforeCancelSamplePlanId] = useState(0);
	const [beforeCancelUserId, setBeforeCancelUserId] = useState(0);
	const [beforeCancelTestPointId, setBeforeCancelTestPointId] = useState(0);
	const [beforeCancelSiteId, setBeforeCancelSiteId] = useState(0);

	const [beforeCancelSamplePlan, setBeforeCancelSamplePlan] = useState({ label: '', value: 0 });
	const [beforeCancelUser, setBeforeCancelUser] = useState({ label: '', value: 0 });
	const [beforeCancelTestPoint, setBeforeCancelTestPoint] = useState({ label: '', value: 0 });
	const [beforeCancelSite, setBeforeCancelSite] = useState({ label: '', value: 0 });

	const [beforeCancelDateRange, setBeforeCancelDateRange] = useState([null, null]);
	const [beforeCancelResultToggle, setBeforeCancelResultToggle] = useState(true);

	const [popVisible, setPopVisibility] = useState(false);
	const [referenceRef, setReferenceRef] = useState(null);
	const [popperRef, setPopperRef] = useState(null);

	const [isDownloadReportLoaded, setIsDownloadReportLoaded] = useState(false);
	const [isDownloadDataLoaded, setIsDownloadDataLoaded] = useState(false);

	const { styles, attributes } = usePopper(referenceRef, popperRef, {
		placement: "bottom-end",
		modifiers: [
			{
				name: "offset",
				enabled: true,
				options: {
					offset: [0, 10]
				}
			}
		]
	});

	var defaultUserId = 0;
	var defaultTestPointId = 0;
	var defaultSiteId = 0;
	var defaultSamplePlanId = 0;

	var defaultSamplePlan = { label: '', value: 0 };
	var defaultTestPoint = { label: '', value: 0 };
	var defaultSite = { label: '', value: 0 };
	var defaultUser = { label: '', value: 0 };

	var defaultDateRange = [null, null];
	var defaultResultToggle = true;


	const handlePopup = (event, action) => {
		event.preventDefault();

		if (action === 'filter') {
			setSelectedSamplePlanId(beforeCancelSamplePlanId);
			setSelectedTestPointId(beforeCancelTestPointId);
			setSelectedSiteId(beforeCancelSiteId);
			setSelectedUserId(beforeCancelUserId);

			setSelectedSamplePlan(beforeCancelSamplePlan);
			setSelectedSite(beforeCancelSite);
			setSelectedUser(beforeCancelUser);
			setSelectedTestPoint(beforeCancelTestPoint);

			setTestResultToggle(beforeCancelResultToggle);
			setDateRange(beforeCancelDateRange);

			setFilterDialog(true);
		}
		var input = {
			siteId: selectedSiteId,
			userId: userData.id,
			userToken: userData.userToken,
			clientId: userData.clientId,
			samplePlanId: selectedSamplePlanId,
			userIdFliter: selectedUserId,
			dateFrom: moment(startDate, 'yyyy-mm-dd'),
			dateTo: moment(endDate, 'yyyy-mm-dd')
		};
		if (action === "Report") {
			setIsDownloadReportLoaded(false);
			dispatch(getDownloadReport(input));
		}
		if (action === "Data") {
			setIsDownloadDataLoaded(false);
			dispatch(getDownloadData(input));
		}
	}

	const handleResultToggle = (value) => {
		setTestResultToggle(value);
	}

	React.useEffect(() => {
		var usrData = getUserData();
		setUserData(usrData);
		getTestHistoryData(usrData);
		getTestPointData(usrData);
		getSiteData(usrData);
	}, []);

	//Graph Data
	if (dashboardData) {
		if (dashboardData.isTestHistoryLoaded && !isTestHistoryLoaded) {
			dashboardData.isTestHistoryLoaded = false;
			setIsTestHistoryLoaded(true);
			setTestHistoryData([]);
			setTestHistoryData(dashboardData.testHistoryData);
			console.log(dashboardData.testHistoryData);
		}
	}

	//UsersData
	if (usersData && usersData.status) {
		if (usersData.isUsersLoaded && !isAllUsersLoaded) {
			if (usersData.usersResult && usersData.usersResult.length > 0) {
				setAllUsers(usersData.usersResult);
				setIsAllUsersLoaded(true);
				var uOptions = [];
				usersData.usersResult.forEach(item => { uOptions.push({ label: item.userName, value: item.id }) });
				setUserOptions(uOptions);
			}
		}
	}

	////SamplePlanData
	if (sampleplanData) {
		if (sampleplanData.isSamplePlansLoaded && !isSamplePlansLoaded) {
			var pOptions = [];
			sampleplanData.samplePlans.forEach(item => { pOptions.push({ label: item.name, value: item.id }) });
			setSamplePlanOptions(pOptions);
			setIsSamplePlansLoaded(true);
		}
	}

	////TestPointData
	if (testPointData && testPointData.testPointResult) {
		if (testPointData.isTestPointLoaded && !isTestPointLoaded) {
			var tOptions = [];
			testPointData.testPointResult.forEach(item => { tOptions.push({ label: item.name, value: item.id }) });
			setTestPointOptions(tOptions);
			setIsTestPointLoaded(true);
		}
	}

	////commented...already used? Site Data
	if (siteData && siteData.sites) {
		if (siteData.isSitesLoaded && !isSitesLoaded) {
			var options = [];
			siteData.sites.forEach(item => { options.push({ label: item.name, value: item.id }) });
			setSiteOptions(options);
			setIsSitesLoaded(true);
			siteData.isSitesLoaded = false;
		}
	}

	const onSiteChange = (event) => {
		if (event.value === null) { return; }
		setSelectedSiteId(event.value);
		if (siteOptions) {
			setSelectedSite(siteOptions.find(o => o.value === event.value));
		}
	}

	const onUserChange = (event) => {
		if (event.value === null) { return; }
		setSelectedUserId(event.value);
		if (userOptions) {
			setSelectedUser(userOptions.find(o => o.value === event.value));
		}
	}

	const onPlanChange = (event) => {
		if (event.value === null) { return; }
		setSelectedSamplePlanId(event.value);
		if (samplePlanOptions) {
			setSelectedSamplePlan(samplePlanOptions.find(o => o.value === event.value));
		}
		//getGraphData();
	}

	const onTestPointChange = (event) => {
		if (event.value === null) { return; }
		setSelectedTestPointId(event.value);
		if (testPointOptions) {
			setSelectedTestPoint(testPointOptions.find(o => o.value === event.value));
		}
	}

	const onDateChange = (dates) => {
		setDateRange(dates);
	}

	const getTestHistoryData = (usrData) => {
		setIsTestHistoryLoaded(false);
		dispatch(getTestHistory({ userId: usrData.id, userToken: usrData.userToken }));
	}

	const getTestPointData = (usrData) => {
		setIsTestPointLoaded(false);
		dispatch(getAllTestPoint({ userId: usrData.id, userToken: usrData.userToken }));
	}

	const getSiteData = (usrData) => {
		setIsSitesLoaded(false);
		dispatch(getSitesByClient({ userId: usrData.id, userToken: usrData.userToken, clientId: usrData.clientId }));
	}

	const handleUserSearch = (txtValue) => {
		var searchResult;

		setIsClearSearch(false);
		setSearchText(txtValue);

		if (filteredTestHistory && filteredTestHistory.length > 0) {
			searchResult = filteredTestHistory;
		}
		else {
			if (dashboardData.testHistoryData && dashboardData.testHistoryData.length > 0) {
				searchResult = dashboardData.testHistoryData;
			}

		}

		if (searchResult && searchResult.length > 0) {
			if (txtValue === "") {
				setTestHistoryData(searchResult);
			}
			else {
				var applySearchResult = searchResult.filter(function (item) {
					return ((item.testPoint.toLowerCase().indexOf((txtValue).toLowerCase())) >= 0
						|| (item.completedBy.toLowerCase().indexOf((txtValue).toLowerCase())) >= 0
						|| (item.samplePlan.toLowerCase().indexOf((txtValue).toLowerCase())) >= 0
						|| (item.site.toLowerCase().indexOf((txtValue).toLowerCase())) >= 0
						|| (item.timeStamp.toLowerCase().indexOf((txtValue).toLowerCase())) >= 0
					);
				});
				setTestHistoryData(applySearchResult);
			}
		}
	}


	function convert(str) {
		var date = new Date(str),
			mnth = ("0" + (date.getMonth() + 1)).slice(-2),
			day = ("0" + date.getDate()).slice(-2),
			hours = ("0" + date.getHours()).slice(-2),
			minutes = ("0" + date.getMinutes()).slice(-2);
		return [date.getFullYear(), mnth, day, hours, minutes].join("-");
	}

	const getFilteredTestHistory = (event) => {
		var filterResult;

		setIsClearSearch(true);
		setSearchText('');
		setBeforeCancelSamplePlanId(selectedSamplePlanId);
		setBeforeCancelTestPointId(selectedTestPointId);
		setBeforeCancelSiteId(selectedSiteId);
		setBeforeCancelUserId(selectedUserId);

		setBeforeCancelSamplePlan(selectedSamplePlan);
		setBeforeCancelTestPoint(selectedTestPoint);
		setBeforeCancelSite(selectedSite);
		setBeforeCancelUser(selectedUser);

		setBeforeCancelDateRange(dateRange);
		setBeforeCancelResultToggle(testResultToggle);

		if (dashboardData.testHistoryData && dashboardData.testHistoryData.length > 0) {
			filterResult = dashboardData.testHistoryData;

			if (selectedTestPointId > 0) {
				filterResult = filterResult.filter(function (item) {
					return (item.testPointId == selectedTestPointId);
				});
			}

			if (selectedUserId > 0) {
				filterResult = filterResult.filter(function (item) {
					return (item.completedById == selectedUserId)
				});
			}

			if (selectedSiteId > 0) {
				filterResult = filterResult.filter(function (item) {
					return (item.siteId == selectedSiteId)
				});
			}

			if (selectedSamplePlanId > 0) {
				filterResult = filterResult.filter(function (item) {
					return (item.samplePlanId == selectedSamplePlanId)
				});
			}

			if (startDate) {
				filterResult = filterResult.filter(function (item) {
					return (convert(item.timeStamp) >= convert(startDate));
				});
			}

			if (endDate) {
				filterResult = filterResult.filter(function (item) {
					return (convert(item.timeStamp) <= convert(endDate));
				});
			}


			if (!testResultToggle) {
				filterResult = filterResult.filter(function (item) {
					return (item.pass == false);
				});
			}

			setTestHistoryData(filterResult);
			setFilteredTestHitory(filterResult);
		}
		setFilterDialog(false);
	}


	const showImage = (event, url, isShowImage) => {
		event.preventDefault();
		if (isShowImage) {
			setTestResultImageUrl(url);
			setOpen(true);
		}
	}

	const columns = [
		{
			Header: <div style={{ textAlign: "left" }}>Test Point</div>,
			accessor: "testPoint"
		},
		{
			Header: <div style={{ textAlign: "left" }}>Site</div>,
			accessor: "site"
		},
		{
			Header: <div style={{ textAlign: "left" }}>Sample Plan</div>,
			accessor: "samplePlan"
		},
		{
			Header: <div style={{ textAlign: "left" }}>Completed By</div>,
			accessor: "completedBy"
		},
		{
			Header: <div style={{ textAlign: "left" }}>Date</div>,
			accessor: "timeStamp"
		},
		{
			Header: <div style={{ textAlign: "left" }}>Colour</div>,
			accessor: "colour",
			width: 70,
			Cell: (obj) => (
				<a href="#" onClick={e => { e.preventDefault(); }}>
					<div style={{ background: `#${obj.original.colour}`, width: "40px", height: "22px", borderRadius: "3px" }} />
				</a>
			)
		},
		{
			Header: <div style={{ textAlign: "left" }}>Result</div>,
			Cell: (obj) => (
				<a href="#" onClick={e => { e.preventDefault(); }}>
					{obj.original.resultType === "Pass" ? <PassIcon /> : obj.original.resultType === "Warning" ? <WarningIcon /> : obj.original.resultType === "Fail" ? <FailIcon /> : ""}
				</a>
			), width: 70, accessor: "result"
		},
		{
			Header: "", Cell: (obj) => (
				obj.original.image ? <a href="#" onClick={(event) => showImage(event, obj.original.image, obj.original.image ? true : false)}>
					<CameraSvgIcon selected={obj.original.image ? true : false} />
				</a> :
					<CameraSvgIcon selected={obj.original.image ? true : false} />
			), width: 70, accessor: "image"
		}];

	const handleCancelClick = (event) => {
		setIsShowConfirmAlertCancelFilter(true);
	}

	const handleOkCancelFilter = (event) => {
		event.preventDefault();
		setIsShowConfirmAlertCancelFilter(false);
		setFilterDialog(false);
	}

	const handleNoCancelFilter = (event) => {
		setFilterDialog(true); //needed? will reload page? selected values will go off?
		setIsShowConfirmAlertCancelFilter(false);
	}

	const handleClearClick = (event) => {
		setIsShowConfirmAlertClearFilter(true);
	}

	const handleOkClearFilter = (event) => {
		setFilterDialog(true);   //need to set? will go off??
		setIsShowConfirmAlertClearFilter(false);

		setSelectedSamplePlanId(defaultSamplePlanId);
		setSelectedSamplePlan(defaultSamplePlan);

		setSelectedSiteId(defaultSiteId);
		setSelectedSite(defaultSite);

		setSelectedTestPointId(defaultTestPointId);
		setSelectedTestPoint(defaultTestPoint);

		setSelectedUser(defaultUser);
		setSelectedUserId(defaultUserId);
		setDateRange(defaultDateRange);

		//Change it to read default result toggle
		setTestResultToggle(defaultResultToggle);
	}

	const handleNoClearFilter = (event) => {
		setFilterDialog(true); //needed? will reload page? selected values will go off?
		setIsShowConfirmAlertClearFilter(false);
	}

	const handleDropdownClick = (event) => {
		setPopVisibility(!popVisible);
	}

	const handlePrint = (event) => {
		event.preventDefault();
		setPopVisibility(!popVisible);
		setTimeout(function () {
			window.print();
		}.bind(this), 1000)
	}

	return (
		<>
			<FCConfirmAlert message="Are you sure you wish to cancel?" isOpen={isShowConfirmAlertCancelFilter}
				title="Filter" onOkClick={(event) => handleOkCancelFilter(event)} onCancelClick={(event) => handleNoCancelFilter(event)} />
			<FCConfirmAlert message="Are you sure you wish to clear all filter data?" isOpen={isShowConfirmAlertClearFilter} title="Test Point"
				onOkClick={(event) => handleOkClearFilter(event)} onCancelClick={(event) => handleNoClearFilter(event)} />
			<div className="max-w-7xl mt-10">
				<div className="w-full flex pb-3">
					<div className="w-1/2 flex">
						<div className="w-1/2">
							<FCInputSearchField className="" placeholder="Search" id="search"
								value={searchText} isClearSearch={isClearSearch}
								onChange={(event) => handleUserSearch(event)} />
						</div>
						<div className="w-1/2 flex">
							<span className="text-1xl w-1/3 text-center">
								<a href="#" className="text-gray-500" onClick={(event) => handlePopup(event, 'filter')}>Filter</a>
							</span>
						</div>
					</div>
					<div className="w-1/2">
						<div className="w-full pr-5 text-right">
							<button ref={setReferenceRef} onClick={handleDropdownClick}>
								<DotVerticalSvgSvgIcon />
							</button>
							<div ref={setPopperRef} style={styles.popper} {...attributes.popper}
								className={(!popVisible ? "hidden" : "w-26 rounded-2xl border-2 border-gray z-10")}>
								<div className="rounded-2xl border-2 border-white" style={{ backgroundColor: "#fff" }}>
									<a href="#" className="block flex flex-inline w-full py-1" onClick={(event) => handlePopup(event, 'Data')}>
										<PlusCircleSvgIconTransparent /> Download Data
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="mt-5">
					<ReactTable
						data={testHistoryData}
						minRows={3}
						columns={columns}
						showPagination={false}
						resizable={false}
						noDataText={<h2 className=" text-center align-middle">No results found</h2>}
						style={testHistoryData.length < 10 ? {
							textAlign: "left",
						} : {
							textAlign: "left",
							height: "400px"
						}}
						className="mt-9 -highlight border-0 mb-1 align-left"
						pageSize={testHistoryData.length > 0 ? testHistoryData.length : 0}
					/>
				</div>
			</div>
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
								<div>
									{testResultImageUrl && testResultImageUrl.length > 0 ? < img src={testResultImageUrl} alt="Test Result Image" /> : "No Image"}
								</div>
								<div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense text-center">
									<FCButtonWithClick onHandleClick={() => setOpen(false)} bgColor="bg-fcbutton mr-3" type="button" icon="" value="Close" />
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>

			<Transition.Root show={FilterDialog} as={Fragment}>
				<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setFilterDialog}>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="inline-block w-1/2 align-bottom bg-white font-large rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:align-middle sm:px-10">
								<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
									<button
										type="button"
										className="bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
										onClick={() => setFilterDialog(false)}
									>
										<span className="sr-only">Close</span>
										<CloseIcon />
									</button>
								</div>
								<div className="sm:flex sm:items-start">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center w-full mb-5">
										<Dialog.Title as="h3" className="text-lg leading-6  text-gray-500">
											Filter
										</Dialog.Title>
									</div>
								</div>
								<form>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5 align-middle">
											Site
										</div>
										<div className="w-2/5 align-top">
											<FCSelectSiteField id="sites" onChange={(event) => onSiteChange(event)}
												options={siteOptions} value={selectedSite} className=" ml-1 " />
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5">
											Sample Plan
										</div>
										<div className="w-2/5">
											<FCSelectSiteField id="plans" onChange={(event) => onPlanChange(event)}
												options={samplePlanOptions} value={selectedSamplePlan} className=" ml-1 " />
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5">
											Test Point
										</div>
										<div className="w-2/5">
											<FCSelectSiteField id="testpoints" onChange={(event) => onTestPointChange(event)} options={testPointOptions}
												value={selectedTestPoint} className=" ml-1 " />
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5">
											User
										</div>
										<div className="w-2/5">
											<FCSelectSiteField id="users" onChange={(event) => onUserChange(event)}
												options={userOptions} value={selectedUser} className=" ml-1 " />
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="flex">
										<div className="block pl-1 text-fclabel w-1/5">
											Date
										</div>
										<div className="w-2/5">
											<DatePicker
												dateFormat={moment().format('DD/MM/YYYY')}
												selectsRange={true}
												startDate={startDate}
												endDate={endDate}
												onChange={(value) => onDateChange(value)}
												className="border-2 border-gray-300 p-2 rounded-2xl ml-1 blueText w-full"
												wrapperClassName="w-full"
												width="100%"
											/>
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="w-3/5 flex">
										<div className="w-1/5">&nbsp;</div>
										<div className="w-4/5">
											<ToggleSwitch checked={testResultToggle} onChange={(value) => handleResultToggle(value)}
												enableText="All Results" disableText="Fail Only" enableColor="bg-primary"
												disableColor="bg-primary" name="testResult" />
										</div>
									</div>
									<div>&nbsp;</div>
									<div className="pb-5 sm:items-center">
										<div className="mt-3 flex sm:mt-0">
											<div className="w-3/5 align-middle flex">
												<div className="w-1/3">&nbsp;</div>
												<div className="w-1/3 align-middle">
													<FCButtonWithClick onHandleClick={(event) => handleClearClick(event)} bgColor="bg-fcbutton mr-3" type="button" icon="" value="Clear" />
												</div>
												<div className="w-1/3">&nbsp;</div>
											</div>
											<div className="w-1/5 align-top">
												<FCButtonWithClick onHandleClick={(event) => handleCancelClick(event)}
													bgColor="bg-fcbutton mr-3" type="button" icon="" value="Cancel" />
											</div>
											<div className="w-1/5 align-top">
												<FCButtonWithClick bgColor="bg-primary mr-3" type="button"
													onHandleClick={(event) => getFilteredTestHistory(event)} icon="" value="Save" />
											</div>
										</div>
									</div>
								</form>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
};

const mapStateToProps = (state: any) => {
	const { dashboardData, usersData, siteData, sampleplanData, testPointData } = state;
	return {
		dashboardData, usersData, siteData, sampleplanData, testPointData
	};
};

export default connect(mapStateToProps)(TestHistory);

