import * as React from 'react';
import { createStore } from 'redux';
import reducer from '../../store/reducers/index';
import SideBar from './sidebar';
import Login from "../../components/account/login";
import SampePlanQuoteTool from "../sampleplanquote/sampleplanquote";
import "../../custom.css";
import { Children } from 'react';

interface IAppFrameProps {
  children: React.ReactNode;
  isLoggedIn: boolean;
	noLogInPage: boolean;
	samplePlanPage: boolean;
}

interface IAppFrameState {
  isLoggedIn: boolean;
  userName: string;
  isSideBarOpen: boolean;
	noLogInPage: boolean;
	samplePlanPage: boolean;
}

const store = createStore(reducer);

class AppFrame extends React.Component<IAppFrameProps, IAppFrameState> {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      isLoggedIn: false,
      isSideBarOpen: false,
			noLogInPage: false,
			samplePlanPage: false
    };
    this.callback = this.callback.bind(this);
  }

  componentDidMount() {
    //alert(this.props.isLoggedIn + "==" + this.props.noLogInPage);
  }

  componentWillMount() {
		this.setState({
			isLoggedIn: this.props.isLoggedIn,
			noLogInPage: this.props.noLogInPage,
			samplePlanPage: this.props.samplePlanPage
		});
  }

  callback(value) {
    if (!value) {
			window.localStorage.removeItem('AUTHDATA');
			window.localStorage.removeItem('QUOTEDATA');
      this.setState({
        isLoggedIn: false,
      });
      //TODO : logout
      window.location.href = "/";
    }
  }

  public render() {
    const { children, isLoggedIn } = this.props;

    return (
      <React.Fragment>
				{!this.state.isLoggedIn && (
          <div className="bg-white">
						<main>
							{children}
            </main>
          </div>
				)}
				{this.state.isLoggedIn && this.state.samplePlanPage && (
          <SideBar isLogged={this.state.isLoggedIn}
            callback={this.callback}
            isOpenSideBar={this.state.isSideBarOpen}
						children={children} />
				)}
				{this.state.isLoggedIn && !this.state.samplePlanPage && (
          <SideBar isLogged={this.state.isLoggedIn}
            callback={this.callback}
            isOpenSideBar={this.state.isSideBarOpen}
            children={children} />
        )}
      </React.Fragment>
    );
  }
}

export default AppFrame;
