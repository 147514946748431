import { Http } from "../Http";
import { AxiosPromise } from "axios";
export class DashboardAPI {

	public static getAllReport(input: any) {
		var obj = JSON.stringify(input);
		return Http.axios().post('/Reporting/GetAllReports', obj, { headers: { 'Content-Type': 'application/json' } })
			.then(response => {
				return response;
			}).catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

	public static getGlobalComparisonData(input: any) {
		var obj = JSON.stringify(input);
		return Http.axios().post('/Reporting/GetGlobalComparison', obj, { headers: { 'Content-Type': 'application/json' } })
			.then(response => {
				return response;
			}).catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

	public static getDashboardData(input: any) {
		var obj = JSON.stringify(input);
		return Http.axios().post('/Reporting/GetDashboardData', obj, { headers: { 'Content-Type': 'application/json' } })
			.then(response => {
				return response;
			}).catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

	public static getDownloadReport(input: any) {
		var obj = JSON.stringify(input);
		return Http.axios().post('/Reporting/DownloadReport', obj, { headers: { 'Content-Type': 'application/json' } })
			.then(response => {
				return response;
			}).catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

	public static getDownloadData(input: any) {
		var obj = JSON.stringify(input);
		return Http.axios().post('/Reporting/DownloadData', obj, { headers: { 'Content-Type': 'application/json' } })
			.then(response => {
				return response;
			}).catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

	public static getTestHistoryData(input: any) {
		var obj = JSON.stringify(input);
		return Http.axios().post('/Reporting/GetTestHistory', obj, { headers: { 'Content-Type': 'application/json' } })
			.then(response => {
				return response;
			}).catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}
}