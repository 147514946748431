export const GET_ALL_REPORT_STARTED = "GET_ALL_REPORT_STARTED";
export const GET_ALL_REPORT_COMPLETED = "GET_ALL_REPORT_COMPLETED";
export const GET_ALL_REPORT_FAILED = "GET_ALL_REPORT_FAILED";

export const GET_GLOBAL_COMPARISON_STARTED = "GET_GLOBAL_COMPARISON_STARTED";
export const GET_GLOBAL_COMPARISON_COMPLETED = "GET_GLOBAL_COMPARISON_COMPLETED";
export const GET_GLOBAL_COMPARISON_FAILED = "GET_GLOBAL_COMPARISON_FAILED";

export const GET_DASHBOARD_DATA_STARTED = "GET_DASHBOARD_DATA_STARTED";
export const GET_DASHBOARD_DATA_COMPLETED = "GET_DASHBOARD_DATA_COMPLETED";
export const GET_DASHBOARD_DATA_FAILED = "GET_DASHBOARD_DATA_FAILED";

export const GET_DOWNLOAD_REPORT_STARTED = "GET_DOWNLOAD_REPORT_STARTED";
export const GET_DOWNLOAD_REPORT_COMPLETED = "GET_DOWNLOAD_REPORT_COMPLETED";
export const GET_DOWNLOAD_REPORT_FAILED = "GET_DOWNLOAD_REPORT_FAILED";

export const GET_DOWNLOAD_DATA_STARTED = "GET_DOWNLOAD_DATA_STARTED";
export const GET_DOWNLOAD_DATA_COMPLETED = "GET_DOWNLOAD_DATA_COMPLETED";
export const GET_DOWNLOAD_DATA_FAILED = "GET_DOWNLOAD_DATA_FAILED";

export const GET_TESTHISTORY_STARTED = "GET_TESTHISTORY_STARTED";
export const GET_TESTHISTORY_COMPLETED = "GET_TESTHISTORY_COMPLETED";
export const GET_TESTHISTORY_FAILED = "GET_TESTHISTORY_FAILED";

export const getAllReport = (input: any) => {
	return {
		type: GET_ALL_REPORT_STARTED,
		input: input
	};
};

export const getGlobalComparisonData = (input: any) => {
	return {
		type: GET_GLOBAL_COMPARISON_STARTED,
		input: input
	};
};

export const getDashboardData = (input: any) => {
	return {
		type: GET_DASHBOARD_DATA_STARTED,
		input: input
	};
};

export const getDownloadReport = (input: any) => {
	return {
		type: GET_DOWNLOAD_REPORT_STARTED,
		input: input
	};
};

export const getDownloadData = (input: any) => {
	return {
		type: GET_DOWNLOAD_DATA_STARTED,
		input: input
	};
};

export const getTestHistory = (input: any) => {
	return {
		type: GET_TESTHISTORY_STARTED,
		input: input
	};
};