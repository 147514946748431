import * as React from 'react';
import { connect } from 'react-redux';
import Register from "../../../components/account/register";
import "./login.css";

class RegisterComponent extends React.Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Register />
    );
  }
}

export default connect()(RegisterComponent);