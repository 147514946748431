import {
	GETALLSAMPLEPLANS_SARTED,
	GETALLSAMPLEPLANS_COMPLETED,
	GETALLSAMPLEPLANS_FAILED,
	GETALLTESTPOINTS_SARTED,
	GETALLTESTPOINTS_COMPLETED,
	GETALLTESTPOINTS_FAILED,
	SAVESAMPLEPLAN_STARTED,
	SAVESAMPLEPLAN_COMPLETED,
	SAVESAMPLEPLAN_FAILED,
	DELETESAMPLEPLAN_STARTED,
	DELETESAMPLEPLAN_COMPLETED,
	DELETESAMPLEPLAN_FAILED,
	IMPORT_SAMPLEPLAN_STARTED,
	IMPORT_SAMPLEPLAN_COMPLETED,
	IMPORT_SAMPLEPLAN_FAILED,
	SELECT_SAMPLEPLAN
} from '../actions/samplePlan';
import { samplePlanModel, testpoint } from '../../utils/models/model';

const initialState = {
	userId: 0,
	usertoken: "",
	isSaved: false,
	isLive: false,
	isError: false,
	isSamplePlansLoaded: false,
	isTestPointsLoaded: false,
	isPlanSaved: false,
	isPlanDeleted: false,
	samplePlanInput: samplePlanModel,
	samplePlans: [samplePlanModel],
	testPoints: [testpoint],
	selectedSamplePlanId: 0,
	selectedSamplePlan: null
};

const sampleplanData = (state = initialState, action: any) => {
	switch (action.type) {
		case GETALLSAMPLEPLANS_SARTED:
			return {
				...state,
				samplePlanInput: action.input,
				isSamplePlansLoaded: false,
				isError: false,
			};
		case GETALLSAMPLEPLANS_COMPLETED:
			let plan = null;
			if (state.selectedSamplePlan) {
				let result = action.payload.filter((item) => {
					return item.id === state.selectedSamplePlan.id;
				});
				if (result.length > 0)
					plan = result[0];
			}

			return {
				...state,
				status: action.payload.status,
				samplePlans: action.payload.result,
				selectedSamplePlan: plan,
				isError: false,
				isSamplePlansLoaded: true
			};
		case GETALLSAMPLEPLANS_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true,
				isSamplePlansLoaded: false
			};

		case GETALLTESTPOINTS_SARTED:
			return {
				...state,
				input: action.input,
				isTestPointsLoaded: false,
				isError: false,
			};
		case GETALLTESTPOINTS_COMPLETED:
			var result = [];
			if (action.payload.result && action.payload.result.length > 0) {
				action.payload.result.map((item) => {
					result.push({
						id: item.id,
						name: item.name,
						location: item.location,
						where: item.where,
						selected: false
					})
				});
			}

			return {
				...state,
				status: action.payload.status,
				testPoints: result,
				isError: false,
				isTestPointsLoaded: true
			};
		case GETALLTESTPOINTS_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true,
				isTestPointsLoaded: false
			};

		case SAVESAMPLEPLAN_STARTED:
			return {
				...state,
				sampleplaninput: action.input,
				isPlanSaved: false
			};
		case SAVESAMPLEPLAN_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				isSuccess: true,
				isPlanSaved: true
			};
		case SAVESAMPLEPLAN_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true,
				isPlanSaved: false
			};

		case DELETESAMPLEPLAN_STARTED:
			return {
				...state,
				sampleplaninput: action.input,
				isPlanDeleted: false
			};
		case DELETESAMPLEPLAN_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				isSuccess: true,
				isPlanDeleted: true
			};
		case DELETESAMPLEPLAN_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true,
				isPlanDeleted: false
			};

		case IMPORT_SAMPLEPLAN_STARTED:
			return {
				...state,
				sampleplaninput: action.input,
				isImportPlanSaved: false
			};
		case IMPORT_SAMPLEPLAN_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				isSuccess: true,
				isImportPlanSaved: true
			};
		case IMPORT_SAMPLEPLAN_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true,
				isImportPlanSaved: false
			};
		case SELECT_SAMPLEPLAN:
			return {
				...state,
				selectedSamplePlanId: action.input ? action.input.id : 0,
				selectedSamplePlan: action.input,
			};

		default:
			return state;
	}
};

export default sampleplanData;
