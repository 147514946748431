import { Http } from "../Http";
import { AxiosPromise } from "axios";

export class ClientPreferencesApi {

	public static getTestResultTypes(input: any) {
		return Http.axios().get('/ClientPreferences/GetTestResultTypes?userId=' + input.userId + "&userToken=" + input.userToken)
			.catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

	public static getClientPreferences(input: any) {
		return Http.axios().get('/ClientPreferences/GetClientPreferences?userId=' + input.userId + "&userToken=" + input.userToken)
			.catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

	public static saveClientPreferences(input: any) {
		var obj = JSON.stringify(input);
		return Http.axios().put('/ClientPreferences/UpdateClientPreferences', obj, { headers: { 'Content-Type': 'application/json' } })
			.then(response => {
				return response;
			}).catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}
}