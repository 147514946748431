import * as React from 'react';
import { connect } from 'react-redux';
import Login from "../../../components/account/login";
import "./login.css";

class LoginComponent extends React.Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Login />
    );
  }
}

export default connect()(LoginComponent);