import { combineReducers } from 'redux';
import authData from './account';
import sampleplanQuoteData from './samplePlanQuote';
import sampleplanData from './samplePlan';
import correctiveActionData from './correctiveaction';
import siteData from './site';
import clientData from './clients';
import usersData from './user';
import userProfileData from './userProfile';
import settingsData from './settings';
import testPointData from './testpoint';
import dashboardData from './dashboard';

const reducers = combineReducers({
	authData, sampleplanQuoteData, correctiveActionData,
	siteData, clientData, usersData, userProfileData,
	settingsData, sampleplanData, testPointData, dashboardData
})

export default reducers;