import React from 'react';
import PropTypes from 'prop-types';
import {
  CheveronCircleRightSvgIcon, TrashSvgIcon, EditSvgIcon
} from '../../utils/common/icon-hooks.js';

const FCButtonWithClick = ({type, icon, value, bgColor, onHandleClick}) => (
  <button
    type={type}
    onClick={onHandleClick}
    className={"inline-flex items-center px-6 py-2 border-2 border-gray-200 text-base "+ 
        "  rounded-full text-white  focus:outline-none " +
        " w-full justify-between " + bgColor}>
    {value && <span className="text-white w-full ">{value}</span>}
    {icon != '' && <>
      {(icon == "rightarrow") && <CheveronCircleRightSvgIcon />}
			{(icon == "trash") && <TrashSvgIcon />}
			{(icon == "edit") && <EditSvgIcon	 />}
      </>
    }
  </button>
);

FCButtonWithClick.propTypes = {
  styleClass: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.string.isRequired,
  bgcolor: PropTypes.string,
  onHandleClick: PropTypes.func
};

FCButtonWithClick.defaultProps = {
  value: 'Click to continue'
};

export default FCButtonWithClick;