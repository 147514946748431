export const UPDATE_SETTINGS_STARTED = "UPDATE_SETTINGS_STARTED ";
export const UPDATE_SETTINGS_COMPLETED = "UPDATE_SETTINGS_COMPLETED";
export const UPDATE_SETTINGS_FAILED = "UPDATE_SETTINGS_FAILED";

export const GET_SETTINGS_STARTED = "GET_SETTINGS_STARTED ";
export const GET_SETTINGS_COMPLETED = "GET_SETTINGS_COMPLETED";
export const GET_SETTINGS_FAILED = "GET_SETTINGS_FAILED";


export const updateSettings = (settingsInput: any) => {
  return {
      type: UPDATE_SETTINGS_STARTED,
      input: settingsInput
  };
};

export const getSettings = (input: any) => {
    return {
        type: GET_SETTINGS_STARTED,
        input: input
    };
};