import { put, call } from "redux-saga/effects";
import {
	GETALLSITES_COMPLETED,
	GETALLSITES_FAILED,
  GETSITE_COMPLETED,
  GETSITE_FAILED,
  GETSITES_COMPLETED,
  GETSITES_FAILED,
  DELETESITE_COMPLETED,
  DELETESITE_FAILED,
  SAVESITE_COMPLETED,
  SAVESITE_FAILED} from "../actions/site";

import { SiteApi } from "../../utils/api/siteApi";

export function* getAllSites(request: any) {
	try {
		const result = yield call(SiteApi.getAllSites, request.input);
		console.log(result.data);
		yield put({
			type: GETALLSITES_COMPLETED,
			payload: result.data
		});
	} catch (e) {
		yield put({ type: GETALLSITES_FAILED, payload: e.message });
	}
}

export function* getSitesByClient(request: any) {
  try {
		const result = yield call(SiteApi.getSitesByClient, request.input);
    console.log(result.data);
    yield put({
			type: GETSITES_COMPLETED,
      payload: result.data
    });
  } catch (e) {
    yield put({ type: GETSITES_FAILED, payload: e.message });
  }
}

export function* getSite(request: any) {
  try {
    const result = yield call(SiteApi.getSite, request.input);
    console.log(result.data);
    yield put({
      type: GETSITE_COMPLETED,
      payload: result.data
    });
  } catch (e) {
		yield put({ type: GETSITE_FAILED, payload: e.message });
  }
}

export function* deleteSite(request: any) {
  try {
    const result = yield call(SiteApi.deleteSite, request.input);
    console.log(result.data);
    yield put({
      type: DELETESITE_COMPLETED,
      payload: result.data
    });
  } catch (e) {
    yield put({ type: DELETESITE_FAILED, payload: e.message });
  }
}

export function* saveSite(request: any) {
  try {
    const result = yield call(SiteApi.saveSite, request.input);
    yield put({
      type: SAVESITE_COMPLETED,
      payload: result.data
    });
  } catch (e) {
    yield put({ type: SAVESITE_FAILED, payload: e.message });
  }
}
