import {
  LOGIN_STARTED,
  LOGIN_COMPLETED,
  LOGIN_FAILED,
  LOGOUT_STARTED,
  LOGOUT_COMPLETED,
  LOGOUT_FAILED,
  CHECKLOGGEDUSER_COMPLETED,
  CHECKLOGGEDUSER_FAILED,
  CHECKLOGGEDUSER_STARTED,
  FORGET_PASSWORD_STARTED,
  FORGET_PASSWORD_COMPLETED,
  FORGET_PASSWORD_FAILED,
  FORGET_PASSWORD_TOKEN_STARTED,
  FORGET_PASSWORD_TOKEN_COMPLETED,
  FORGET_PASSWORD_TOKEN_FAILED,
  FORGET_PASSWORD_CHANGE_COMPLETED,
  FORGET_PASSWORD_CHANGE_FAILED,
  FORGET_PASSWORD_CHANGE_STARTED,
  ACCOUNT_REGISTER_STARTED,
  ACCOUNT_REGISTER_COMPLETED,
  ACCOUNT_REGISTER_FAILED
} from '../actions/account';

const initialAccounts = {
  status: {
    statusCode: 200,
    statusDisplay: "",
    statusValue: false,
  },
  loginInput: {
    username: "",
    password: "",
    companyName: "",
    pushNotificationToken: "",
    deviceId: "123", //TODO:
    isAndroid: false //TODO:
  },
  loginResult: {
    id: -1,
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    roleId: -1,
    userToken: "",
    siteId: -1,
		clientId: -1,
		clientName: ""
  },
  sessionTimeout: 10,
  isFormSubmit: false,
  isSuccess: false,
  isLoading: false,
  isLoggedIn: false,
  isPwdChanged: false,
  isTokenValidated: false,
  isPasswordChanged: false,
  isAccountRegistered: false
};

const authData = (state = initialAccounts, action: any) => {
  switch (action.type) {
    case LOGIN_STARTED:
      return {
        ...state,
        isLoading: true,
        isFormSubmit: true,
        loginInput: action.input,
        isError: false,
        isSuccess:false
      };
    case LOGIN_COMPLETED:
      console.log("Login Payload=" + action.payload);
      return {
        ...state,
        isLoading: false,
        isFormSubmit: true,
        status: action.payload.status,
        loginInput: action.input,
        loginResult: action.payload.user,
        sessionTimeout: action.payload.sessionTimeout,
        isError: false,
        isSuccess: true
      };
    case LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        isFormSubmit: false,
        error: action.payload,
        isError:true
      };

    case LOGOUT_STARTED:
      return {
        ...state,
      };
    case LOGOUT_COMPLETED:
      return {
        ...state,
      };
    case LOGOUT_FAILED:
      return {
        ...state,
      };

    case CHECKLOGGEDUSER_FAILED:
      return {
        ...state,
        isLoading: true,
      };
    case CHECKLOGGEDUSER_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case CHECKLOGGEDUSER_COMPLETED:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        loginInput: action.input,
        sessionTimeout: action.sessionTimeout,
        info: action.payload.info,
      };

    case FORGET_PASSWORD_STARTED:
      return {
        ...state,
        isLoading: true,
        isFormSubmit: true,
        input: action.input,
        isError: false,
        isSuccess: false,
        status:initialAccounts.status
      };
    case FORGET_PASSWORD_COMPLETED:
      return {
        ...state,
        isLoading: false,
        isFormSubmit: false,
        status: action.payload.status,
        isError: false,
        isSuccess: true
      };
    case FORGET_PASSWORD_FAILED:
      return {
        ...state,
        isLoading: false,
        isFormSubmit: false,
        error: action.payload,
        status: initialAccounts.status,
        isError: true
      };

    case FORGET_PASSWORD_TOKEN_STARTED:
      return {
        ...state,
        isLoading: true,
        isFormSubmit: true,
        input: action.input,
        isError: false,
        isSuccess: false,
        status: initialAccounts.status,
        isTokenValidated: false
      };
    case FORGET_PASSWORD_TOKEN_COMPLETED:
      return {
        ...state,
        isLoading: false,
        isFormSubmit: true,
        status: action.payload.status,
        isError: false,
        isSuccess: true,
        isTokenValidated: true
      };
    case FORGET_PASSWORD_TOKEN_FAILED:
      return {
        ...state,
        isLoading: false,
        isFormSubmit: false,
        status: initialAccounts.status,
        error: action.payload,
        isError: true,
        isTokenValidated: false
      };

    case FORGET_PASSWORD_CHANGE_STARTED:
      return {
        ...state,
        isLoading: true,
        isFormSubmit: true,
        status: initialAccounts.status,
        input: action.input,
        isError: false,
        isSuccess: false,
        isPasswordChanged: false,
        isTokenValidated: true
      };
    case FORGET_PASSWORD_CHANGE_COMPLETED:
      return {
        ...state,
        isLoading: false,
        isFormSubmit: true,
        status: action.payload.status,
        isError: false,
        isSuccess: true,
        isPasswordChanged: true,
        isTokenValidated: true
      };
    case FORGET_PASSWORD_CHANGE_FAILED:
      return {
        ...state,
        isLoading: false,
        isFormSubmit: false,
        status: initialAccounts.status,
        error: action.payload,
        isError: true,
        isPasswordChanged: false,
        isTokenValidated: true
      };

    case ACCOUNT_REGISTER_STARTED:
      return {
        ...state,
        isLoading: true,
        isFormSubmit: true,
        loginInput: action.input,
        isSuccess: false,
        isAccountRegistered: false
      };
    case ACCOUNT_REGISTER_COMPLETED:
      return {
        ...state,
        isLoading: false,
        isFormSubmit: true,
        status: action.payload.status,
        isSuccess: true,
        isAccountRegistered: true
      };
    case ACCOUNT_REGISTER_FAILED:
      return {
        ...state,
        isLoading: false,
        isFormSubmit: false,
        error: action.payload,
        isError: true,
        isAccountRegistered: false
      };
    default:
      return state;
  }
};

export default authData;
