import { put, call } from "redux-saga/effects";
import {
  LOGIN_FAILED, LOGIN_COMPLETED, LOGOUT_FAILED, LOGOUT_COMPLETED,
  CHECKLOGGEDUSER_COMPLETED, CHECKLOGGEDUSER_FAILED, FORGET_PASSWORD_COMPLETED,
  FORGET_PASSWORD_FAILED, FORGET_PASSWORD_TOKEN_COMPLETED, FORGET_PASSWORD_TOKEN_FAILED,
  FORGET_PASSWORD_CHANGE_COMPLETED, FORGET_PASSWORD_CHANGE_FAILED,
  ACCOUNT_REGISTER_COMPLETED, ACCOUNT_REGISTER_FAILED
} from "../actions/account";
import { AccountAPI } from "../../utils/api/accountApi";

export function* login(request: any) {
  try {
    const result = yield call(AccountAPI.login, request.input);
    console.log(result.data);
    if (result.data.result!==null) {
      yield put({
        type: LOGIN_COMPLETED,
        payload: result.data.result,
        input: request.input,
        sessionTimeout: result.data.result.sessionTimeout
      });
    }
    else {
      yield put({ type: LOGIN_FAILED, payload: "Login Failed! please check username and password." });
    }
  } catch (e) {
    yield put({ type: LOGIN_FAILED, payload: e.message });
  }
}

export function* logout() {
  try {
    const result = yield call(AccountAPI.logout);
    yield put({
      type: LOGOUT_COMPLETED,
      payload: result.data
    });
  } catch (e) {
    yield put({ type: LOGOUT_FAILED, payload: e.message });
  }
}

export function* forgetpwd(input: any) {
  try {
    const result = yield call(AccountAPI.forgotPassord, input.input);
    yield put({
      type: FORGET_PASSWORD_COMPLETED,
      payload: result.data
    });
  } catch (e) {
    yield put({ type: FORGET_PASSWORD_FAILED, payload: e.message });
  }
}

export function* forgetPasswordValidateToken(input: any) {
  try {
    const result = yield call(AccountAPI.forgetPasswordValidateToken, input.input.email, input.input.resetToken);
    var status = result.data;
    yield put({
      type: FORGET_PASSWORD_TOKEN_COMPLETED,
      payload: result.data
    });
  } catch (e) {
    yield put({ type: FORGET_PASSWORD_TOKEN_FAILED, payload: e.message });
  }
}

export function* forgetpwdChange(request: any) {
  try {
    const result = yield call(AccountAPI.forgotPassordChange, request.input);
    var status = result.data;
    yield put({
      type: FORGET_PASSWORD_CHANGE_COMPLETED,
      payload: result.data,
    });
  } catch (e) {
    yield put({ type: FORGET_PASSWORD_CHANGE_FAILED, payload: e.message });
  }
}

export function* fetchCheckLoggedUser(userName: any) {
  try {
    const result = yield call(AccountAPI.fetchCheckLoggedUser, userName);
    var status = result.data;
    yield put({
      type: CHECKLOGGEDUSER_COMPLETED,
      payload: result.data.status,
      sessionTimeout: result.data.sessionTimeout
    });
  } catch (e) {
    yield put({ type: CHECKLOGGEDUSER_FAILED, payload: e.message });
  }
}

export function* register(request: any) {
  try {
    const result = yield call(AccountAPI.register, request.input);
    if (result.data.result !== null) {
      yield put({
        type: ACCOUNT_REGISTER_COMPLETED,
        payload: result.data,
        input: request.input,
      });
    }
    else {
      yield put({ type: ACCOUNT_REGISTER_FAILED, payload: "Login Failed! please check username and password." });
    }
  } catch (e) {
    yield put({ type: ACCOUNT_REGISTER_FAILED, payload: e.message });
  }
}