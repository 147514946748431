import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useState } from 'react'
import ReactTable from "react-table-6";
import { Dispatch } from 'redux';
import { PlusCircleSvgIcon, EditSvgIcon, TrashSvgIcon } from "../../utils/common/icon-hooks";
import { getClients, deleteClient } from '../../store/actions/clients';
import FCInputSearchField from '../../utils/library/textboxsearch';
import FCConfirmAlert from '../../utils/library/confirmalert';
import { getUserData,addDefaultSrc } from '../../utils/common/functions';

interface IClientProps {
  dispatch: Dispatch<any>;
  clientData: any,
  seeAlert: any
}

const Clients: React.SFC<IClientProps> = ({
  dispatch, clientData, seeAlert }) => {

  const history = useHistory();
  const [tableData, setTablesData] = useState([]);
  const [clientsAdded, setClientsAdded] = useState(false);
  const [searchText, setSearchText] = useState(String);
  const [isShowConfirmAlert, setIsShowConfirmAlert] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isClientDeleted, setIsClientDeleted] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(-1);

  React.useEffect(() => {
    var usrData = getUserData();
    setUserData(usrData);

    setSearchText("");
		dispatch(getClients({ userId: usrData.id, userToken: usrData.userToken }));
  }, []);

  if (clientData && clientData.status) {
    if (clientData.isClientsLoaded && !clientsAdded) {
      if (clientData.clientsResult && clientData.clientsResult.length > 0) {
        setTablesData(clientData.clientsResult);
        setClientsAdded(true);
      }
    }

    if (clientData.isClientDeleted && !isClientDeleted) {
      seeAlert(true, clientData.status.statusValue, "Deleted Successfully");
			dispatch(getClients({ userId: userData.id, userToken: userData.userToken }));
      setClientsAdded(false);
      setIsClientDeleted(true);
    }
  }

  const removeClient = (event, idToDelete, clientName) => {
    event.preventDefault();
    setSelectedClientId(idToDelete);
    setIsShowConfirmAlert(true);
  }

  const handleClientSearch = (txtValue) => {
    setSearchText(txtValue)
    if (clientData.clientsResult && clientData.clientsResult.length > 0) {
      if (txtValue === "") {
        setTablesData(clientData.clientsResult);
      } else {
        var filterResult = clientData.clientsResult.filter(function (item) {
          return item.name.toLowerCase().indexOf((txtValue).toLowerCase()) >= 0;
        });
        setTablesData(filterResult);
      }
    }
  }

	const columns = [
		{
			Header: <div style={{ textAlign: "left" }}>Company</div>,
			Cell: (obj) => (
				<img
					className="inline-block h-12 w-12 rounded-full"
					src={obj.original.imageUrl}
					alt=""
					onError={addDefaultSrc}
				/>
			),
			width: 80,
			accessor: "imageUrl"
		},
		{
			Header: <div style={{ textAlign: "left" }}></div>,
			accessor: "name"
		},
		{
			Header: <div style={{ textAlign: "left" }}>Address</div>,
			accessor: "addressLine1",
			Cell: props => <span>
				{props.original.addressLine1} {props.original.addressLine2}
			</span>
		},
		{
			Header: "", Cell: (obj) => (
			<div className="cursor-pointer" onClick={() => history.push("/clients/" + obj.original.id)}>
			  <EditSvgIcon />
			</div>
      ), width: 70, accessor: "id"
    },
    {
      Header: "", Cell: (obj) => (
        <a href="#" onClick={(event) => removeClient(event, obj.original.id, obj.original.name)}>
          <TrashSvgIcon />
        </a>
      ), width: 70, accessor: "id"
    }];

  const handleDeleteSite = (event) => {
    event.preventDefault();
    seeAlert(false, false, "");
    setIsClientDeleted(false);
		userData && dispatch(deleteClient({ userId: userData.id, userToken: userData.userToken, id: selectedClientId }));
    setIsClientDeleted(false);
    setIsShowConfirmAlert(false);
  }

  const handleConfirmCancel = (event) => {
    setIsShowConfirmAlert(false);
  }

  return (
    <React.Fragment>
      <FCConfirmAlert message="Are you sure you want to delete this client?" isOpen={isShowConfirmAlert} title="Delete Client"
        onOkClick={(event) => handleDeleteSite(event)} onCancelClick={(event) => handleConfirmCancel(event)} />
      <div className="py-3">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
					<h1 className="text-2xl  text-gray-500 text-center">Clients</h1>
        </div>
        <div className="mt-9 w-full mb-4 text-gray-500">
            <div
				className="block cursor-pointer"
				onClick={() => history.push("/clients/0")}
            >
              Add Client
            </div>
					<div className="pl-3 inline-flex w-1/2 mb-1 mx-auto">
            <div
				className="pr-12 inline-flex  items-center border border-transparent bg-transparent text-block focus:outline-none cursor-pointer"
				onClick={() => history.push("/clients/0")}
            >
              <PlusCircleSvgIcon />
            </div>

            <FCInputSearchField className="" placeholder="Search" id="search" value={searchText} onChange={(event) => handleClientSearch(event)} />
          </div>
        </div>
        <ReactTable
          data={tableData}
          minRows={3}
          columns={columns}
          showPagination={false}
          resizable={false}
          noDataText={<h2 className=" text-center align-middle">No clients found</h2>}
          style={tableData.length < 10 ? {
            textAlign: "left",
					} : {
							textAlign: "left",
							height: "400px"
					}}
					className="mt-9 -highlight border-0 mb-1 align-left"
					pageSize={tableData.length > 0 ? tableData.length : 0}
        />
      </div>
    </React.Fragment>
  );
};


const mapStateToProps = (state: any) => {
  const { clientData } = state;
  return {
    clientData
  };
};

export default connect(mapStateToProps)(Clients);