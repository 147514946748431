import { put, call } from "redux-saga/effects";
import { ClientsApi } from "../../utils/api/clientsApi";
import {
  GET_CLIENTS_COMPLETED,
  GET_CLIENTS_FAILED,

  POST_DELETE_COMPLETED,
  POST_DELETE_FAILED,

  POST_ADD_COMPLETED,
  POST_ADD_FAILED,

  GET_SINGLE_CLIENT_IMG_COMPLETED,
  GET_SINGLE_CLIENT_IMG_FAILED,

  GET_UNIQUE_COMPANY_COMPLETED,
  GET_UNIQUE_COMPANY_FAILED,

  CHANGE_CLIENT_AVATAR_COMPLETED,
  CHANGE_CLIENT_AVATAR_FAILED
} from '../actions/clients';


export function* getClients(request: any) {
  try {
    const result = yield call(ClientsApi.getClients, request.input);
    yield put({
      type: GET_CLIENTS_COMPLETED,
      payload: result.data,
      input: request.input
    });
  } catch (e) {
    yield put({ type: GET_CLIENTS_FAILED, payload: e.message });
  }
}


export function* deleteClient(request: any) {
  try {
    const result = yield call(ClientsApi.deleteClient, request.input);
    yield put({
      type: POST_DELETE_COMPLETED,
      payload: result.data,
      input: request.input
    });
  } catch (e) {
    yield put({ type: POST_DELETE_FAILED, payload: e.message });
  }
}

export function* getSingleClientWithImg(request: any) {
  try {
    const result = yield call(ClientsApi.getSingleClientWithImg, request.input);
    console.log(result.data);
    yield put({
      type: GET_SINGLE_CLIENT_IMG_COMPLETED,
      payload: result.data,
      input: request.input
    });
  } catch (e) {
    yield put({ type: GET_SINGLE_CLIENT_IMG_FAILED, payload: e.message });
  }
}

export function* addorUpdateClient(request: any) {
  try {
    const result = yield call(ClientsApi.addOrUpdateClient, request.input);
    console.log(result.data);
    yield put({
      type: POST_ADD_COMPLETED,
      payload: result.data,
      input: request.input
    });
  } catch (e) {
    yield put({ type: POST_ADD_FAILED, payload: e.message });
  }
}

//May have to remove this function
//export function* getUniqueCompanyName(request: any) {
//  try {
//    const result = yield call(AccountAPI.validateCompanyExist, request.input);
//    yield put({
//      type: GET_UNIQUE_COMPANY_COMPLETED,
//      payload: result.data,
//      input: request.input
//    });
//  } catch (e) {
//    yield put({ type: GET_UNIQUE_COMPANY_FAILED, payload: e.message });
//  }
//}

export function* updateClientAvatar(request: any) {
  try {
    const result = yield call(ClientsApi.updateAvatar, request.input);
    yield put({
			type: CHANGE_CLIENT_AVATAR_COMPLETED,
      payload: result.data
    });
  } catch (e) {
		yield put({ type: CHANGE_CLIENT_AVATAR_FAILED, payload: e.message });
  }
}
