import React from 'react';
import PropTypes from 'prop-types';
import {
    CheveronCircleRightSvgIcon
} from '../../utils/common/icon-hooks.js';

const FCButton = ({ type, icon, value, bgColor }) => (
    <button
        type={type}
        className={"inline-flex w-full items-center px-6 py-3 border-gray-200 border-2 text-base " +
            "  rounded-full text-white  focus:outline-none " +
            " justify-between " + bgColor}>
        <span className="text-white w-full">{value}</span>
        {icon != '' && <>
            {(icon == "rightarrow") && <CheveronCircleRightSvgIcon />}
        </>
        }
    </button>
);

FCButton.propTypes = {
    styleClass: PropTypes.string,
    icon: PropTypes.string,
    value: PropTypes.string.isRequired,
    bgcolor: PropTypes.string
};

FCButton.defaultProps = {
    value: 'Click to continue'
};

export default FCButton;