export const GET_USERS_STARTED = "GET_USERS_STARTED";
export const GET_USERS_COMPLETED = "GET_USERS_COMPLETED";
export const GET_USERS_FAILED = "GET_USERS_FAILED";

export const GET_SINGLE_USER_STARTED = "GET_SINGLE_USER_STARTED";
export const GET_SINGLE_USER_COMPLETED = "GET_SINGLE_USER_COMPLETED";
export const GET_SINGLE_USER_FAILED = "GET_SINGLE_USER_FAILED";

export const POST_SINGLE_USER_DELETE_STARTED = "POST_SINGLE_USER_DELETE_STARTED";
export const POST_SINGLE_USER_DELETE_COMPLETED = "POST_SINGLE_USER_DELETE_COMPLETED";
export const POST_SINGLE_USER_DELETE_FAILED = "POST_SINGLE_USER_DELETE_FAILED";

export const POST_ADD_USER_STARTED = "POST_ADD_USER_STARTED";
export const POST_ADD_USER_COMPLETED = "POST_ADD_USER_COMPLETED";
export const POST_ADD_USER_FAILED = "POST_ADD_USER_FAILED";

export const GET_UNIQUE_USER_STARTED = "GET_UNIQUE_USER_STARTED";
export const GET_UNIQUE_USER_COMPLETED = "GET_UNIQUE_USER_COMPLETED";
export const GET_UNIQUE_USER_FAILED = "GET_UNIQUE_USER_FAILED";

export const POST_UPDATE_USER_AVATAR_STARTED = "POST_UPDATE_USER_AVATAR_STARTED";
export const POST_UPDATE_USER_AVATAR_COMPLETED = "POST_UPDATE_USER_AVATAR_COMPLETED";
export const POST_UPDATE_USER_AVATAR_FAILED = "POST_UPDATE_USER_AVATAR_FAILED";

export const GET_USERS_ROLES_STARTED = "GET_USERS_ROLES_STARTED";
export const GET_USERS_ROLES_COMPLETED = "GET_USERS_ROLES_COMPLETED";
export const GET_USERS_ROLES_FAILED = "GET_USERS_ROLES_FAILED";

export const getUsers = (input: any) => {
    return {
        type: GET_USERS_STARTED,
        input: input
    };
};

export const getSingleUserWithImg = (input: any) => {
  return {
    type: GET_SINGLE_USER_STARTED,
    input: input
  };
};

export const postUserDelete = (input: any) => {
  return {
    type: POST_SINGLE_USER_DELETE_STARTED,
    input: input
  };
};

export const addOrUpdateUser = (input: any) => {
  return {
    type: POST_ADD_USER_STARTED,
    input: input
  };
};

export const checkUniqueUsername = (input: any) => {
  return {
    type: GET_UNIQUE_USER_STARTED,
    input: input
  };
};

export const updateUserAvatar = (input: any) => {
  return {
    type: POST_UPDATE_USER_AVATAR_STARTED,
    input: input
  };
};

export const getUserRoles = (input: any) => {
  return {
    type: GET_USERS_ROLES_STARTED,
    input: input
  };
};
