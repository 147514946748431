import {
  GET_CLIENTS_STARTED,
  GET_CLIENTS_COMPLETED,
  GET_CLIENTS_FAILED,

  POST_DELETE_STARTED,
  POST_DELETE_COMPLETED,
  POST_DELETE_FAILED,

  POST_ADD_STARTED,
  POST_ADD_COMPLETED,
  POST_ADD_FAILED,

  GET_SINGLE_CLIENT_IMG_STARTED,
  GET_SINGLE_CLIENT_IMG_COMPLETED,
  GET_SINGLE_CLIENT_IMG_FAILED,

  GET_UNIQUE_COMPANY_STARTED,
  GET_UNIQUE_COMPANY_COMPLETED,
  GET_UNIQUE_COMPANY_FAILED,

	CHANGE_CLIENT_AVATAR_STARTED,
  CHANGE_CLIENT_AVATAR_COMPLETED,
  CHANGE_CLIENT_AVATAR_FAILED
} from '../actions/clients';

import { client } from '../../utils/models/model';

const initialState = {
  isLoading: false,
  isError: false,
  isClientsLoaded: false,
  isClientDeleted: false,
  deleteResult: false,
  clientinput: {
    userId: -1,
    userToken: "",
    clientId: -1
  },
  clientsResult: [{
    id: -1,
    name: ""
  }],
  client: client,
  isClientLoaded: false,
  isClientAdded: false,
  clientAddResult: false,
  companyName: "",
  isCompanyChecked: false,
  isCompanyExist: false
}

const clientData = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CLIENTS_STARTED:
      return {
        ...state,
        isLoading: true,
        clientinput: action.input,
        isClientsLoaded: false,
        isClientDeleted: false
      };
    case GET_CLIENTS_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        clientsResult: action.payload.result,
        isSuccess: true,
        isClientsLoaded: true
      };
    case GET_CLIENTS_FAILED:
      return {
        ...state,
        error: action.payload,
				isError: true,
				isClientsLoaded: false
      };

    case POST_DELETE_STARTED:
      return {
        ...state,
        isLoading: true,
        clientinput: action.input,
        isClientsLoaded: false,
        isClientDeleted: false
      };
    case POST_DELETE_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        deleteResult: action.payload.result,
        isClientDeleted: true
      };
    case POST_DELETE_FAILED:
      return {
        ...state,
        error: action.payload,
        isError: true
      };

    case GET_SINGLE_CLIENT_IMG_STARTED:
      return {
        ...state,
        isLoading: true,
        clientinput: action.input,
        isClientLoaded: false
      };
    case GET_SINGLE_CLIENT_IMG_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        client: action.payload.result,
        isClientLoaded: true
      };
    case GET_SINGLE_CLIENT_IMG_FAILED:
      return {
        ...state,
        error: action.payload,
        isError: true
      };

    case POST_ADD_STARTED:
      return {
        ...state,
        isLoading: true,
        client: action.input,
        isClientAdded: false
      };
    case POST_ADD_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        clientAddResult: action.payload.result,
        isClientAdded: true
      };
    case POST_ADD_FAILED:
      return {
        ...state,
        error: action.payload,
        isError: true
      };

    case GET_UNIQUE_COMPANY_STARTED:
      return {
        ...state,
        isLoading: true,
        companyName: action.input,
        isCompanyChecked: false,
        isCompanyExist: false
      };
    case GET_UNIQUE_COMPANY_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        isCompanyExist: action.payload.result,
        isCompanyChecked: true
      };
    case GET_UNIQUE_COMPANY_FAILED:
      return {
        ...state,
        error: action.payload,
        isError: true
      };

    case CHANGE_CLIENT_AVATAR_STARTED:
      return {
        ...state,
        userInput: action.input
      };
    case CHANGE_CLIENT_AVATAR_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        isAvatarUpdated: true
      };
    case CHANGE_CLIENT_AVATAR_FAILED:
      return {
        ...state,
        error: action.payload,
        isError: true
      };

    default:
      return state;
  }
};

export default clientData;
