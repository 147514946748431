export const GET_CLIENTS_STARTED = "GET_CLIENTS_STARTED";
export const GET_CLIENTS_COMPLETED = "GET_CLIENTS_COMPLETED";
export const GET_CLIENTS_FAILED = "GET_CLIENTS_FAILED";

export const POST_DELETE_STARTED = "POST_DELETE_STARTED";
export const POST_DELETE_COMPLETED = "POST_DELETE_COMPLETED";
export const POST_DELETE_FAILED = "POST_DELETE_FAILED";

export const POST_ADD_STARTED = "POST_ADD_STARTED";
export const POST_ADD_COMPLETED = "POST_ADD_COMPLETED";
export const POST_ADD_FAILED = "POST_ADD_FAILED";

export const GET_UNIQUE_COMPANY_STARTED = "GET_UNIQUE_COMPANY_STARTED";
export const GET_UNIQUE_COMPANY_COMPLETED = "GET_UNIQUE_COMPANY_COMPLETED";
export const GET_UNIQUE_COMPANY_FAILED = "GET_UNIQUE_COMPANY_FAILED";

export const GET_SINGLE_CLIENT_IMG_STARTED = "GET_SINGLE_CLIENT_IMG_STARTED";
export const GET_SINGLE_CLIENT_IMG_COMPLETED = "GET_SINGLE_CLIENT_IMG_COMPLETED";
export const GET_SINGLE_CLIENT_IMG_FAILED = "GET_SINGLE_CLIENT_IMG_FAILED";

export const CHANGE_CLIENT_AVATAR_STARTED = "CHANGE_CLIENT_AVATAR_STARTED";
export const CHANGE_CLIENT_AVATAR_COMPLETED = "CHANGE_CLIENT_AVATAR_COMPLETED";
export const CHANGE_CLIENT_AVATAR_FAILED = "CHANGE_CLIENT_AVATAR_FAILED";

export const getClients = (input: any) => {
    return {
        type: GET_CLIENTS_STARTED,
        input: input
    };
};

export const deleteClient = (input: any) => {
    return {
        type: POST_DELETE_STARTED,
        input: input
    };
};

export const addClient = (input: any) => {
    return {
        type: GET_CLIENTS_STARTED,
        input: input
    };
};

export const updateClient = (input: any) => {
    return {
        type: GET_CLIENTS_STARTED,
        input: input
    };
};

export const getSingleClientWithImage = (input: any) => {
    return {
        type: GET_SINGLE_CLIENT_IMG_STARTED,
        input: input
    };
};

export const addorUpdateClient = (input: any) => {
    return {
        type: POST_ADD_STARTED,
        input: input
    };
};

export const checkUniqueCompanyName = (input: any) => {
    return {
        type: GET_UNIQUE_COMPANY_STARTED,
        input: input
    };
};

export const updateAvatar = (input: any) => {
  return {
		type: CHANGE_CLIENT_AVATAR_STARTED,
    input: input
  };
};