import * as React from 'react';
import { connect } from 'react-redux';
import UserProfile from "../../userprofile/userprofile";
import ChangePwd from "../../userprofile/changepassword";
import FCMessageDialog from '../../../utils/library/dialog';
import { RouteComponentProps } from 'react-router';

interface IProps {
  pg: string,
  match: any
}

interface IState {
  showAlert: boolean,
  isSuccessAlert: boolean,
  alertMessage: string,
  isChangePwd: boolean
}

class UserProfileComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showAlert: false,
      isSuccessAlert: false,
      alertMessage: "",
      isChangePwd: false
    }
    this.seeAlert = this.seeAlert.bind(this);
  }

  seeAlert(isShow, isSuccess, message) {
    this.setState({
      showAlert: isShow,
      isSuccessAlert: isSuccess,
      alertMessage: message
    });
  }

  closeDialog = () => {
    this.setState({ showAlert: false });
  }

  componentDidMount() {
    if (this.props.match.params.pg &&
        window.location.href.toLowerCase().indexOf(this.props.match.params.pg)) {
      this.setState({ isChangePwd: true });
    }
    else {
      this.setState({ isChangePwd: false });
    }
  }

  render() {
    const { showAlert, isSuccessAlert, alertMessage } = this.state;
    return (
      <>
        {showAlert && <FCMessageDialog isSuccess={isSuccessAlert} modalCallBack={() => this.closeDialog()} message={alertMessage} />}
        <div className="px-20">
          {!this.state.isChangePwd && <UserProfile seeAlert={this.seeAlert} />}
          {this.state.isChangePwd && <ChangePwd seeAlert={this.seeAlert} />}
        </div>
      </>
    );}
}

export default connect()(UserProfileComponent);;