import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from 'redux';
import { useState } from 'react'
import FCSiteInputField from '../../utils/library/sitetextbox';
import FCInputSearchField from '../../utils/library/textboxsearch';
import FCSiteBox from '../../utils/library/sitebox';
import { Validators, validateInput } from '../../utils/library/Validator';
import { PlusCircleSvgIcon, EditSvgIcon, TrashSvgIcon } from "../../utils/common/icon-hooks";
import { getUserData } from '../../utils/common/functions';
import { testpoint } from '../../utils/models/model';
import FCButtonWithClick from '../../utils/library/buttonwithclick';
import FCMessageDialog from '../../utils/library/dialog';
import FCConfirmAlert from '../../utils/library/confirmalert';
import { getAllTestPoint, deleteTestPoint, addOrUpdateTestPoint } from '../../store/actions/testpoint';

interface ITestPointProps {
	testPointData: any;
	dispatch: Dispatch<any>;
}

const TestPoint: React.SFC<ITestPointProps> = ({
	testPointData, dispatch }) => {

	const validators =
		[{
			check: Validators.required,
			message: 'All fields are required.'
		}];

	const [userData, setUserData] = useState(null);
	const [searchText, setSearchText] = useState(String);
	const [isTestPointsLoaded, setIsTestPointsLoaded] = useState(false);
	const [addMode, setAddMode] = useState(false);
	const [viewMode, setViewMode] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [selectedTestPoint, setSelectedTestPoint] = useState(testpoint);

	const [isRequiredError, setIsRequiredError] = useState(false);
	const [isNameEntered, setIsNameEntered] = useState(false);
	const [isLocationEntered, setIsLocationEntered] = useState(false);
	const [isWhereEntered, setIsWhereEntered] = useState(false);

	const [isShowConfirmAlert, setIsShowConfirmAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [showAlert, setShowAlert] = useState(false);
	const [isTestPointDeleted, setIsTestPointDeleted] = useState(false);
	const [isTestPointSaved, setIsTestPointSaved] = useState(false);
	const [isSuccessAlert, setIsSuccessAlert] = useState(false);
	const [previousTestPoint, setPreviousTestPoint] = useState(testpoint);

	React.useEffect(() => {
		var usrData = getUserData();
		setUserData(usrData);
		setSearchText("");
		dispatch(getAllTestPoint({ userId: usrData?.id, userToken: usrData?.userToken }));
		setViewMode(true);
		setAddMode(false);
		setEditMode(false);
		setShowAlert(false);
	}, []);

	if (testPointData) {
		if (testPointData.isTestPointLoaded && !isTestPointsLoaded) {
			// setTestPoints(testPointData.testPointResult ? testPointData.testPointResult : []);
			setIsTestPointsLoaded(true);
			if (testPointData.testPointResult && testPointData.testPointResult.length > 0) {
				setSelectedTestPoint(testPointData.testPointResult[0]);
			}
		}

		if (testPointData.isTestPointDeleted && !isTestPointDeleted) {
			if (testPointData.status.statusValue) {
				setAlertMessage("Deleted successfully.");
				setIsTestPointsLoaded(false);
				dispatch(getAllTestPoint({ userId: userData?.id, userToken: userData?.userToken }));
				setViewMode(true);
				setAddMode(false);
				setEditMode(false);
			}
			setIsTestPointDeleted(true);
			setShowAlert(true);
			setIsSuccessAlert(testPointData.status.statusValue);
		}

		if (testPointData.isTestPointAdded && !isTestPointSaved) {
			if (testPointData.status.statusValue) {
				setEditMode(false);
				setAddMode(false);
				setViewMode(true);
				setAlertMessage("Saved successfully.");
				setIsTestPointsLoaded(false);
				dispatch(getAllTestPoint({ userId: userData.id, userToken: userData.userToken }));
			}
			else
			{
				setAlertMessage(testPointData.status.statusDisplay);
			}
			setShowAlert(true);
			setIsTestPointSaved(true);
			setIsSuccessAlert(testPointData.status.statusValue);
		}
	}

	const onTestPointChange = (event, plan) => {
		event.preventDefault();
		//setSelectedPlanId(plan.id);
		setSelectedTestPoint(plan);
		setViewMode(true);
		setAddMode(false);
		setEditMode(false);
	}

	const onInputChange = (event) => {
		if (event.name === 'name') {
			setIsNameEntered(validateInput(validators, event.value));
		}
		if (event.name === 'location') {
			setIsLocationEntered(validateInput(validators, event.value));
		}
		if (event.name === 'where') {
			setIsWhereEntered(validateInput(validators, event.value));
		}

		setSelectedTestPoint(prevState => ({
			...prevState,
			[event.name]: event.value
		}));
	}

	const handleDeleteTestPoint = (event) => {
		event.preventDefault();
		setShowAlert(false);
		userData && dispatch(deleteTestPoint({ userId: userData.id, userToken: userData.userToken, id: selectedTestPoint.id }));
		setIsTestPointDeleted(false);
		setIsShowConfirmAlert(false);
	}

	const showDeleteConfirmAlert = (event) => {
		//event.preventDefault();
		setIsShowConfirmAlert(true);
	}

	const handleConfirmCancel = (event) => {
		setIsShowConfirmAlert(false);
	}

	const closeDialog = () => {
		setShowAlert(false);
	}

	const handleCancel = () => {
		setEditMode(false);
		setAddMode(false);
		setViewMode(true);
		setIsRequiredError(false);
		setIsNameEntered(false);
		setIsLocationEntered(false);
		setIsWhereEntered(false);
		setSelectedTestPoint(previousTestPoint);
		setPreviousTestPoint(testpoint);
	}

	const validateNullOrUndefined = () => {
		if ((typeof (selectedTestPoint.name) !== "undefined" && selectedTestPoint.name) &&
			(typeof (selectedTestPoint.location) !== "undefined" && selectedTestPoint.location) &&
			(typeof (selectedTestPoint.where) !== "undefined" && selectedTestPoint.where)) {
			return true;
		}
		else {
			return false;
		}
	}

	const addorUpdateTest = () => {
		setIsNameEntered(validateInput(validators, selectedTestPoint.name));
		setIsLocationEntered(validateInput(validators, selectedTestPoint.location));
		setIsWhereEntered(validateInput(validators, selectedTestPoint.where));

		if (validateNullOrUndefined()) {
			setIsRequiredError(false);
			setIsTestPointSaved(false);
			dispatch(addOrUpdateTestPoint(selectedTestPoint));
		}
		else {
			setIsRequiredError(true);
		}
	}

	const handleAddTestPoint = (event, isNew) => {
		event.preventDefault();
		if (isNew) {
			setSelectedTestPoint(testpoint);
			setAddMode(true);
			setEditMode(false);
		}
		else {
			setPreviousTestPoint(selectedTestPoint);
			setAddMode(false);
			setEditMode(true);
		}
		setSelectedTestPoint(prevState => ({
			...prevState,
			userId: userData.id,
			userToken: userData.userToken
		}));
		setViewMode(false);
		setShowAlert(false);
	}

	const handleTestSearch = (txtValue) => {
		setSearchText(txtValue)
		if (testPointData.testPointResult && testPointData.testPointResult.length > 0) {
			if (txtValue === "") {
				setSelectedTestPoint(testPointData.testPointResult[0]);
			} else {
				var filterResult = testPointData.testPointResult.filter(function (item) {
					return item.name.toLowerCase().indexOf((txtValue).toLowerCase()) >= 0;
				});
				if (filterResult.length > 0) {
					setSelectedTestPoint(filterResult[0]);
				} else {
					setSelectedTestPoint(testpoint);
				}
			}
		}
	}

	return (
		<React.Fragment>
			<div className="py-1">
				<FCConfirmAlert message="Are you sure you want to delete this test point?" isOpen={isShowConfirmAlert} title="Delete Test Point"
					onOkClick={(event) => handleDeleteTestPoint(event)} onCancelClick={(event) => handleConfirmCancel(event)} />
				{showAlert && <FCMessageDialog isSuccess={isSuccessAlert} modalCallBack={() => closeDialog()} message={alertMessage} />}
				<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
					<h1 className="text-1xl  text-gray-500 text-center">Test Points</h1>
				</div>
				<div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-2 lg:gap-8 w-full">
					<div className="grid grid-cols-1 gap-4 w-1/2 w-full">
						<section aria-labelledby="section-1-title">
							<div className="overflow-hidden w-full items-center">
								<div className="p-2 w-full">
									<div className="w-full mb-2 mx-auto flex">
										<div className="w-2/6 text-gray-500">
											Add Test Point
										</div>
										<div className="w-4/6">&nbsp;</div>
									</div>
									<div className="overflow-hidden h-1/2 mx-auto w-full flex">
										<div className="text-gray-500 w-1/6">
											<button
												style={{float:'right'}}
												onClick={(event) => handleAddTestPoint(event, true)}
												type="button">
												<PlusCircleSvgIcon />
											</button>
										</div>
										<div className="pl-8 pr-9 w-4/6 w-full">
											<FCInputSearchField className="" placeholder="Search Test Point" id="search" value={searchText} onChange={(event) => handleTestSearch(event)} />
										</div>
										<div className="w-1/6">&nbsp;</div>
									</div>
								</div>
								<div>
									<div className="w-full mb-2 mx-auto item-center flex">
										<div className="w-1/6">&nbsp;</div>
										<div className="w-4/6"><h2 className="text-1xl font-semibold text-gray-500 text-center py-2">Select A Test Point</h2></div>
										<div className="w-1/6">&nbsp;</div>
									</div>
									<div className="h-sitebox overflow-y-auto item-center px-11 mx-auto flex">
										<div className="w-1/6">&nbsp;</div>
										<div className="w-4/6 w-full">
											{testPointData.testPointResult.length === 0 &&
												<h2 className=" text-center align-middle">No test point found</h2>
											}
											{testPointData.testPointResult && testPointData.testPointResult.map((test: any) =>
												<FCSiteBox key={"key" + test.id} id={test.id} text={test.name}
													selected={test.id === selectedTestPoint.id ? true : false}
													onClick={(event) => onTestPointChange(event, test)} />
											)}
										</div>
										<div className="w-1/6">&nbsp;</div>
									</div>
								</div>
							</div>
						</section>
					</div>
					<div className={"grid grid-cols-1 gap-4"}>
						{(testPointData.testPointResult.length > 0 || addMode) &&
							<section aria-labelledby="section-2-title">
								<div className="overflow-hidden">
									<div className="p-2 mx-auto">
										<div className="w-full overflow-hidden h-1/2 mx-auto">
											<div className="w-3/4 mb-2  mx-auto">
												<h2 className="text-1xl font-semibold text-gray-500 text-center mb-1">
													{addMode && <> Add Test Point </>}
													{editMode && <> Edit Test Point </>}
													{viewMode && <> Test Point Details </>}
												</h2>
											</div>
											<div className="w-3/4 overflow-hidden h-1/2 mx-auto">
												<div className={"pt-1 " + (addMode || editMode ? " border-2 border-primary rounded-2xl " : "")}>
													<div className="mb-2 w-3/4 mx-auto pt-1">
														<FCSiteInputField id="name" onChange={(event) => onInputChange(event.target)} labelText="Test Point Name"
															value={selectedTestPoint.name} required={true} isError={isNameEntered} readOnly={viewMode} />
													</div>
													<div className="mb-3 w-3/4 flex flex-row mx-auto">
														<div className="w-full flex-row">
															<FCSiteInputField id="location" onChange={(event) => onInputChange(event.target)} labelText="Location"
																value={selectedTestPoint.location} required={true} isError={isLocationEntered} readOnly={viewMode} />
														</div>
													</div>
													<div className=" mb-3 w-3/4 flex flex-row mx-auto " >
														<div className="w-full flex-row">
															<FCSiteInputField id="where" onChange={(event) => onInputChange(event.target)} labelText="Where"
																value={selectedTestPoint.where} required={true} isError={isWhereEntered} readOnly={viewMode} />
														</div>
													</div>
												</div>
												<div className="mt-5 mb-2 w-3/4 flex mx-auto">
													{(addMode || editMode) &&
														<div className="mt-1 w-3/4 flex mx-auto">
															<FCButtonWithClick onHandleClick={handleCancel} bgColor="bg-fcbutton mr-3" type="button" icon="" value="Cancel" />
															<FCButtonWithClick onHandleClick={addorUpdateTest} bgColor="bg-primary" type="button" icon="" value="Save" />
														</div>
													}
													{viewMode &&
														<div className="mt-1 w-4/5">
															<button
																type="button"
																onClick={(event) => handleAddTestPoint(event, false)}
																className={"float-right inline-flex items-center py-3 border-transparent text-base " +
																	"  rounded-full text-white  focus:outline-none "}>
																<EditSvgIcon />
															</button>
														</div>
													}
													{!addMode &&
														<div className="mt-1 w-1/5">
															<button
																type="button"
																onClick={(event) => showDeleteConfirmAlert(event)}
																className={" float-right inline-flex items-center py-3 border-transparent text-base " +
																	"  rounded-full text-white  focus:outline-none "}>
																<TrashSvgIcon />
															</button>
														</div>
													}
												</div>
												<div className="text-red-500  text-center">
													{isRequiredError && <span>Please enter the required fields</span>}
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state: any) => {
	const { testPointData } = state;
	return {
		testPointData
	};
};

export default connect(mapStateToProps)(TestPoint);