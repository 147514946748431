import { Http } from "../Http";
import { AxiosPromise } from "axios";

export class TestPointApi {

	public static getAllTestPoint(input: any) {
		return Http.axios().get('/TestPoint/GetAllTestPoint?userId=' + input.userId
			+ "&userToken=" + input.userToken)
			.catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

	public static addOrUpdateTestPoint(input: any) {
		var obj = JSON.stringify(input);
		return Http.axios().post('/TestPoint/addOrUpdateTestPoint', obj, { headers: { 'Content-Type': 'application/json' } })
			.then(response => {
				return response;
			}).catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

	public static deleteTestpoint(input: any) {
		var obj = JSON.stringify(input);
		return Http.axios().post('/TestPoint/DeleteTestpoint', obj, { headers: { 'Content-Type': 'application/json' } })
			.catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}

	public static checkUniqueTestPointName(input: any) {
		return Http.axios().get('/TestPoint/ValidateTestPointExist?userId=' + input.userId
			+ "&userToken=" + input.userToken + "&name=" + input.name)
			.catch((e) => {
				return e.response;
			}) as AxiosPromise<any>;
	}
}