import {
	UPDATE_SETTINGS_STARTED,
	UPDATE_SETTINGS_COMPLETED,
	UPDATE_SETTINGS_FAILED,
	GET_SETTINGS_STARTED,
	GET_SETTINGS_COMPLETED,
	GET_SETTINGS_FAILED
} from '../actions/settings';


const initialState = {
	isFormSubmit: false,
	isSaved: false,
	isError: false,
	settingsInput: {
		userId: 0,
		usertoken: "",
		deleted: true,
		id: 0,
		preference: "",
		value: false,
		clientPreferences: [],
	},
	settingResult: []
};


const settingsData = (state = initialState, action: any) => {
	switch (action.type) {
		case UPDATE_SETTINGS_STARTED:
			return {
				...state,
				input: action.input,
				isSaved: false,
				isError: false
			};
		case UPDATE_SETTINGS_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				settings: action.payload.settings,
				isSaved: true,
				isError: false
			};
		case UPDATE_SETTINGS_FAILED:
			return {
				...state,
				isError: true,
				isSaved: false
			};
		case GET_SETTINGS_STARTED:
			return {
				...state,
				settingsInput: action.input,
			};
		case GET_SETTINGS_COMPLETED:
			return {
				...state,
				isSaved: false,
				status: action.payload.status,
				settings: action.payload.settings,
				settingsClientPreferences: action.payload.clientPreferences,
			};
		case GET_SETTINGS_FAILED:
			return {
				...state,
				isError: true
			};
		default:
			return state;
	}
};

export default settingsData;
