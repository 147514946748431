import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getCorretiveActions, deleteCorretiveAction, addCorretiveAction } from '../../store/actions/correctiveaction';
import { useState, useRef } from 'react';
import { PlusCircleSvgIcon, TrashSvgIcon, CACloseIcon, CheckIcon } from '../../utils/common/icon-hooks';
import FCMessageDialog from '../../utils/library/dialog';
import FCConfirmAlert from '../../utils/library/confirmalert';
import { cAction } from '../../utils/models/model';
import FCSiteBox from '../../utils/library/sitebox';
import { getUserData } from '../../utils/common/functions';
import { Validators, validateInput } from '../../utils/library/Validator';

interface ICorrectiveGet {
  dispatch: Dispatch<any>;
  correctiveActionData: any;
}

const CorrectiveAction: React.SFC<ICorrectiveGet> = ({
	correctiveActionData, dispatch }) => {
	const validators =
		[{
			check: Validators.required,
			message: 'Company name is required.'
		}];
  const [data, setData] = useState([cAction]);
  const [selectedCA, setSelectedCA] = useState(cAction);
  const [selectedCAId, setSelectedCAId] = useState(0);
  const [isCALoaded, setIsCALoaded] = useState(false);
  const [isCADeleted, setIsCADeleted] = useState(false);
  const [isCASaved, setIsCASaved] = useState(false);
  const [isShowConfirmAlert, setIsShowConfirmAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [userData, setUserData] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [isSuccessAlert, setIsSuccessAlert] = useState(false);
  const [addMode, setAddMode] = useState(false);
	const [isRequiredError, setIsRequiredError] = useState(false);
	const [caName, setCaName] = useState("");

  const CAInput = useRef(null);

  React.useEffect(() => {
    var usrData = getUserData();
    setUserData(usrData);

    dispatchGetCAs(usrData);
  }, []);

  const dispatchGetCAs = (usrData) => {
    //Dispatch to get the sites data
    //setSelectedSiteId(0);
    setIsCALoaded(false);
    setData([]);
    dispatch(getCorretiveActions({ userId: usrData.id, userToken: usrData.userToken }));
  }

	const onInputChange = (event) => {
		//if (event.name === 'name') {
		//	setIsCANameEntered(validateInput(validators, event.value));
		//}
    setCaName(event.value);
  }

  const handleConfirmCancel = (event) => {
    setIsShowConfirmAlert(false);
  }

  const handleAddCA = (event) => {
    event.preventDefault();
    setShowAlert(false);
    setAlertMessage("");
    setCaName("");
		const updatedCarsArray = [...data, { id: 0, name: 'Test', userId: userData.id, userToken: userData.userToken }];
    setData(updatedCarsArray);
    setAddMode(true);
  }

  const handleCancelCA = (event) => {
    event.preventDefault();
    const index = data.findIndex(obj => obj.id === 0);
    const newData = [
      ...data.slice(0, index),
      ...data.slice(index + 1)
    ]
    setData(newData);
    setAddMode(false);
  }

  const handldSaveCA = (event) => {
    event.preventDefault();
		setShowAlert(false);
		if (!validateNullOrUndefined()) {
			setIsRequiredError(true);
		}
		else {
			setIsRequiredError(false);
			dispatch(addCorretiveAction({
				userId: userData.id,
				userToken: userData.userToken,
				clientId: userData.clientId,
				id: 0,
				name: caName
			}));
			setIsCASaved(false);
			setIsShowConfirmAlert(false);
		}
  }

  const handleDeleteCA = (event) => {
    event.preventDefault();
    setShowAlert(false);
    dispatch(deleteCorretiveAction({
      userId: userData.id,
      userToken: userData.userToken,
      clientId: userData.clientId,
      id: selectedCA.id
    }));
    setIsCADeleted(false);
    setIsShowConfirmAlert(false);
  }

  const onCAChange = (event, CAid) => {
    event.preventDefault();
    setSelectedCAId(CAid);
    if (correctiveActionData && correctiveActionData.correctiveActions) {
      setSelectedCA(correctiveActionData.correctiveActions.find(o => o.id === CAid));
    }
  }

  const closeDialog = () => {
    setShowAlert(false);
  }

  const showDeleteConfirmAlert = (event, CAid) => {
    //event.preventDefault();
    setSelectedCA(correctiveActionData.correctiveActions.find(o => o.id === CAid));
    setIsShowConfirmAlert(true);
  }

  if (correctiveActionData) {
    if (correctiveActionData.isCALoaded && !isCALoaded) {
      setData(correctiveActionData.correctiveActions);
      setIsCALoaded(true);
      if (selectedCAId === 0) {
        setSelectedCA(correctiveActionData?.correctiveActions[0]);
        setSelectedCAId(correctiveActionData?.correctiveActions[0]?.id);
      }
      else {
        setSelectedCAId(correctiveActionData.correctiveActions.find(o => o.id === selectedCAId));
      }
			//correctiveActionData.isCALoaded = false;
			setShowAlert(false);
    }
    if (correctiveActionData.isCADeleted && !isCADeleted) {
      if (correctiveActionData.status.statusValue) {
        const index = data.findIndex(obj => obj.id === selectedCA.id);
        const newData = [
          ...data.slice(0, index),
          ...data.slice(index + 1)
        ];
        setSelectedCAId(data[0].id);
        setSelectedCA(newData[0]);
        setData(newData);
        setAlertMessage("Deleted successfully.");
        dispatchGetCAs(userData);
      }
      setShowAlert(true);
      setIsSuccessAlert(correctiveActionData.status.statusValue);
      setIsCADeleted(true);
			correctiveActionData.isCADeleted = false;
			setAddMode(false);
    }
    if (correctiveActionData.isCASaved && !isCASaved) {
      if (correctiveActionData.status.statusValue) {
        dispatchGetCAs(userData);
        setAlertMessage("Saved successfully.");
      }
      setIsSuccessAlert(correctiveActionData.status.statusValue);
      setShowAlert(true);
			setIsCASaved(true);
			setAddMode(false);
      correctiveActionData.isCASaved = false;
    }
  }

	const validateNullOrUndefined = () => {
		if (caName !== '') {
			return true;
		}
		else {
			return false;
		}
	}

  return (
    <div className="py-4">
      <FCConfirmAlert message="Are you sure you want to delete this corrective action?" isOpen={isShowConfirmAlert} title="Delete Corrective Action"
        onOkClick={(event) => handleDeleteCA(event)} onCancelClick={(event) => handleConfirmCancel(event) } />
      {showAlert && <FCMessageDialog isSuccess={isSuccessAlert} modalCallBack={() => closeDialog()} message={alertMessage} />}

      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-1xl  text-gray-500 text-center pb-16">Corrective Action</h1>
      </div>
      <div className="grid grid-cols-1">
        <section aria-labelledby="section-1-title">
          <div className="overflow-hidden">
            <div className="pt-8 px-2">
              <div className="w-3/4 overflow-hidden h-1/2 mx-auto">
                <div className="h-sitebox overflow-y-auto item-center w-3/4 mx-auto">
                  {data && data.length === 0 &&
                    <h2 className=" text-center align-middle">No corrective actions found</h2>
                  }
                  {data && data.map((item: any) =>
                    <div className="flex">
                      {item.id > 0 && <>
                        <div className=" w-3/4">
                          <FCSiteBox key={"key" + item.id} id={item.id} text={item.name} selected={item.id === selectedCA?.id ? true : false}
                            onClick={(event) => onCAChange(event, item.id)} isCAPage={true} />
                        </div>
                        <div className="inline-flex w-1/4">
                          <button
                            type="button"
                            onClick={(event) => showDeleteConfirmAlert(event, item.id)}
                            className={"inline-flex items-center py-3 border-transparent text-base " +
                              "  rounded-full focus:outline-none "}>
                            <TrashSvgIcon />
                          </button>
                        </div>
                      </>}
                      {item.id === 0 && addMode &&
                        <>
                          <div className=" w-3/4">
                            <div key={"key-New" + item.id} id={item.id} className="border-2 shadow-md rounded-2xl h-16 mx-1 my-2  border-gray-200 ">
                              <div className="ml-4 my-1 h-3/4 border-l-4 pl-2 flex flex-row place-items-center rounded-t-sm rounded-b-sm border-fcbutton w-3/4">
                              <input type="text" name="name" id="name" ref={CAInput} autoFocus={true}
																className="flex-auto -mr-9 px-4 py-2 blueText
                                              placeholder-gray-300 focus:outline-none "
                                  placeholder="New Corrective Action" required={true}
                                  onChange={(event) => onInputChange(event.target)} value={caName} />
                              </div>
                            </div>
                          </div>
                          <div className="inline-flex px-2 -ml-2">
                            <button
                              type="button"
                              onClick={(event) => handldSaveCA(event)}
                              className={"inline-flex items-center py-3 border-transparent text-base " +
                                "  rounded-full text-white  focus:outline-none "}>
															<CheckIcon selected={true} />
                            </button>
														<button
															type="button"
															onClick={(event) => handleCancelCA(event)}
															className={"inline-flex items-center py-3 border-transparent text-base " +
																"  rounded-full text-white  focus:outline-none "}>
															<CACloseIcon selected={true} />
														</button>
                        </div>
                        </>
                      }
                  </div>
                  )}
                </div>
								<div className="mx-auto">
									{!addMode &&
										<>
											<div className="mt-6 text-gray-500 w-3/4 mx-auto">
												<div className="block">
													<a href="#" onClick={(event) => handleAddCA(event)} className="ml-1">
														Add
													</a>
												</div>
												<button
													onClick={(event) => handleAddCA(event)}
													type="button"
													className="border border-transparent bg-transparent text-block focus:outline-none"
												>
													<PlusCircleSvgIcon />
												</button>
										</div>
										</>
									}
									<div className="text-red-500 text-center">
										{isRequiredError && <span>Please enter the required fields</span>}
									</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { correctiveActionData } = state;
  return {
    correctiveActionData
  };
};

export default connect(mapStateToProps)(CorrectiveAction);

