import axios, { AxiosInstance } from "axios";
import { authToken } from './common/functions'

let API_URL = window.location.origin.toString() + "/"; /*"http://localhost:6006/";*/

export class Http {
	public static axios(): AxiosInstance {
    return axios.create({
			baseURL: API_URL,
			headers: {
				Authorization: authToken()
			}
    });
  }
}

export class HttpLocal {
  public static axios(): AxiosInstance {
    return axios.create({
      baseURL: API_URL
    });
  }
}