import {

	GET_TEST_POINT_STARTED,
	GET_TEST_POINT_COMPLETED,
	GET_TEST_POINT_FAILED,

	POST_SINGLE_TEST_POINT_DELETE_STARTED,
	POST_SINGLE_TEST_POINT_DELETE_COMPLETED,
	POST_SINGLE_TEST_POINT_DELETE_FAILED,

	POST_ADD_TEST_POINT_STARTED,
	POST_ADD_TEST_POINT_COMPLETED,
	POST_ADD_TEST_POINT_FAILED,

	UNIQUE_TEST_POINT_STARTED,
	UNIQUE_TEST_POINT_COMPLETED,
	UNIQUE_TEST_POINT_FAILED

} from '../actions/testpoint';

import { testpoint } from '../../utils/models/model';

const initialState = {
	isLoading: false,
	isError: false,
	testpoint: testpoint,
	isTestPointLoaded: false,
	isTestPointDeleted: false,
	deleteResult: false,
	testPointInput: {
		userId: -1,
		userToken: "",
		id: -1
	},
	testPointResult: [{
		id: -1,
		name: ""
	}],
	isTestPointAdded: false,
	testPointAddResult: false,
	isUniqueChecked: false,
	isUniqueTestPoint: false
}

const testPointData = (state = initialState, action: any) => {
	switch (action.type) {
		case GET_TEST_POINT_STARTED:
			return {
				...state,
				isLoading: true,
				testPointInput: action.input,
				isTestPointLoaded: false
			};
		case GET_TEST_POINT_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				testPointResult: action.payload.result,
				isSuccess: true,
				isTestPointLoaded: true
			};
		case GET_TEST_POINT_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true
			};

		case POST_SINGLE_TEST_POINT_DELETE_STARTED:
			return {
				...state,
				isLoading: true,
				testPointInput: action.input,
				isTestPointLoaded: false,
				isTestPointDeleted: false
			};
		case POST_SINGLE_TEST_POINT_DELETE_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				deleteResult: action.payload.result,
				isTestPointDeleted: true
			};
		case POST_SINGLE_TEST_POINT_DELETE_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true
			};

		case POST_ADD_TEST_POINT_STARTED:
			return {
				...state,
				isLoading: true,
				testpoint: action.input,
				isTestPointLoaded: false,
				isTestPointAdded: false
			};
		case POST_ADD_TEST_POINT_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				testPointAddResult: action.payload.result,
				isTestPointAdded: true
			};
		case POST_ADD_TEST_POINT_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true,
				isTestPointAdded: false
			};

		case UNIQUE_TEST_POINT_STARTED:
			return {
				...state,
				isLoading: true,
				testPointInput: action.input,
				isUniqueChecked: false
			};
		case UNIQUE_TEST_POINT_COMPLETED:
			return {
				...state,
				status: action.payload.status,
				isUniqueTestPoint: action.payload,
				isSuccess: true,
				isUniqueChecked: true
			};
		case UNIQUE_TEST_POINT_FAILED:
			return {
				...state,
				error: action.payload,
				isError: true
			};

		default:
			return state;
	}
}

export default testPointData;